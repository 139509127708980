import request from '@/utils/request'

// 查询打卡记录明细
export function listPunchInDetails(query) {
  return request({
    url: '/match/punchInDetails/list',
    method: 'get',
    params: query
  })
}

//导出打卡记录明细
export function exportPunchInDetailsExcel(query) {
  return request({
    url: '/match/punchInDetails/exportPunchInDetailsExcel',
    method: 'get',
    params: query
  })
}
