var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { className: "box" } }, [
    _c("div", { attrs: { id: "weather" } }, [
      _vm._v(" 天气："),
      _c("span", { staticStyle: { "margin-right": "10px" } }, [
        _vm._v(_vm._s(_vm.briefNew) + "/" + _vm._s(_vm.tmpNew) + "℃"),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }