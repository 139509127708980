import request from "@/utils/request";
import { console } from "vuedraggable/src/util/helper";

// 查询设备信息列表
export function listInfo(query) {
  return request({
    url: "/rc/info/ScreenList",
    method: "get",
    params: query,
  });
}

// 查询设备信息详细
export function getInfo(diId) {
  return request({
    url: "/rc/info/" + diId,
    method: "get",
  });
}

// 删除设备信息
export function delInfo(diId) {
  return request({
    // url: "/rc/info/removeDevAndCW/" + diId,
    url: "/rc/info/removeDevAndMore/" + diId,
    method: "delete",
  });
}

// 导出设备信息
export function exportInfo(dtCode, ids) {
  // if (ids.length === 0) {
  //   ids[0] = -1;
  // }
  return request({
    url: `/rc/info/export/${dtCode}&${ids}`,
    method: "get",
    // params: query,
  });
}

// 下载导入设备imei的excel模板
export function importImeiTemplate() {
  return request({
    url: "/rc/info/importTemplate",
    method: "get",
  });
}

// 查询rfid芯片类型列表
export function listRfType() {
  return request({
    url: "/rc/RfidType/list",
    method: "get",
  });
}
// 查询入库货道列表
export function listWay() {
  return request({
    url: "/rc/way/list",
    method: "get",
  });
}

// 根据设备编码批量修改货道容量
export function editCapacity(query) {
  return request({
    url: "/rc/info/edits",
    method: "put",
    params: query,
  });
}

//查看号码段
export function getRfidEpcNo(query) {
  return request({
    url: "/rc/production/DtoList",
    method: "get",
    params: query,
  });
}

/** 详情 **/
// 查看存包柜详情
export function listInfoDetail(query) {
  return request({
    url: "/rc/rfid/list",
    method: "get",
    params: query,
  });
}
// 解绑绿道
export function noTrack(query) {
  return request({
    url: "/rc/greenway_device/" + query,
    method: "delete",
  });
}

// 修改单条货道容量
export function editOneRoad(query) {
  return request({
    url: "/rc/way",
    method: "put",
    params: query,
  });
}
// 修改单条货道容量
export function errorState(data) {
  return request({
    url: "/rc/way/update/errorState",
    method: "post",
    params: data,
  });
}

//设备上架
export function deviceUpperFrame(diId) {
  return request({
    url: "/rc/info/device/deviceUpperFrame/" + diId,
    method: "get",
  });
}



//设备下架
export function deviceDownFrame(diId) {
  return request({
    url: "/rc/info/device/deviceDownFrame/" + diId,
    method: "get",
  });
}


//号码柜出货
export function rfidShipment(data){
  return request({
    url: "/rc/rfid/device/shipment",
    method: "POST",
    data: data
  });
}
