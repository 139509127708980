import request from '@/utils/request'

// 查询奖品订单列表
export function listOrderMedal(query) {
  return request({
    url: '/order/orderMedal/list',
    method: 'get',
    params: query
  })
}


// 导出奖品订单
export function exportOrderMedal(query,orderIds) {
  if (0 === orderIds.length) {
    orderIds = [-1];
  }
   return request({
    url: '/order/orderMedal/export/'+orderIds,
    method: 'get',
    params: query
  })
}
// 保存单号
export function saveTrack(orderId,postDeliveryNo, postCompanyName) {
  return request({
    url: '/order/orderMedal/saveTrack/'+ orderId + "/"  +postDeliveryNo + "/" +postCompanyName,
    method: 'post',
  })
}

//获取申请人信息
export function getApplyUserInformation(data) {
  return request({
    url: '/order/orderMedal/getApplyUserInformation',
    method: 'get',
    params: data
  })
}

// 人工领取
export function artificialReceive(orderId, receiveNumber) {
  return request({
    url: '/order/orderMedal/artificialReceive/' + orderId + "/" + receiveNumber,
    method: 'get'
  })
}
