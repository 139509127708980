import request from '@/utils/request'

//获取商城数据接口
export function getMatchMaterial(params) {
  return request({
    url: '/emd/match/info/getMatchMaterial',
    method: 'get',
    params: params
  })
}

//获取商品分类接口
export function getGoodsClassify() {
  return request({
    url: '/emd/match/info/getGoodsClassify',
    method: 'get',
  })
}

//新增赛事策划
export function saveMatchMaterial(data) {
  return request({
    url: '/emd/match/info/add',
    method: 'POST',
    data: data
  })
}

//赛事策划详情
export function getInfo(emdMainId) {
  return request({
    url: '/emd/match/info/getInfo/'+ emdMainId,
    method: 'GET',
  })
}

//修改赛事策划
export function updateMatchMaterial(data) {
  return request({
    url: '/emd/match/info/update',
    method: 'POST',
    data: data
  })
}

//获取绿道打卡点
export function getCheckPoint(greenwayIds) {
  return request({
    url: '/emd/match/info/getCheckPoint',
    method: 'get',
    params: {
      greenwayIds: greenwayIds.join(',')
    }
  })
}

//下载活动队伍名单模板
export function  handleTeamTemplate() {
  return request({
    url: '/emd/match/info/handleTeamTemplate',
    method: 'get',
  })
}

//根据绿道Ids获取组别
export function getMbsGreenwayLineListByIds(greenwayIds) {
  return request({
    url: '/greenway/line/getMbsGreenwayLineListByGreenwayIds',
    method: 'get',
    params: {
      greenwayIds: greenwayIds
    }
  })
}
