import request from '@/utils/request'

// 查询运营赛事证书列表
export function listCert(query) {
  return request({
    url: '/operate/cert/list',
    method: 'get',
    params: query
  })
}

// 查询运营赛事证书详细
export function getCert(certId) {
  return request({
    url: '/operate/cert/' + certId,
    method: 'get'
  })
}

// 新增运营赛事证书
export function addCert(data) {
  return request({
    url: '/operate/cert/insert',
    method: 'post',
    data: data
  })
}

// 修改运营赛事证书
export function updateCert(data) {
  return request({
    url: '/operate/cert/edit',
    method: 'post',
    data: data
  })
}

// 删除运营赛事证书
export function delCert(certId) {
  return request({
    url: '/operate/cert/' + certId,
    method: 'post',
  })
}
