import request from "@/utils/request";

// 查询节目单列表
export function listPlayBill(query) {
  return request({
    url: "/rc/playbill/list/playbill",
    method: "get",
    params: query,
  });
}

// 查询节目单详细
export function getPlayBill(playBillId) {
  return request({
    url: "/rc/playbill/details/" + playBillId,
    method: "get",
  });
}

// 新增节目单
export function addPlayBill(data) {
  return request({
    url: "/rc/playbill/add/playbill",
    method: "post",
    data: data,
  });
}

// 新增节目单
export function newAddPlayBill(data) {
  return request({
    url: "/rc/playbill/newAdd/playbill",
    method: "post",
    data: data,
  });
}


// 修改节目单
export function updatePlayBill(data) {
  return request({
    url: "/rc/playbill/updatePlayBill",
    method: "post",
    data: data,
  });
}

// 处理广告关联表数据
export function handleRcAdSpaceDevice(data) {
  return request({
    url: "/rc/playbill/handleRcAdSpaceDevice",
    method: "post",
    data: data,
  });
}


// 删除节目单
export function delPlayBill(playbillCode) {
  return request({
    url: "/rc/playbill/remove/playbill/" + playbillCode,
    method: "delete",
  });
}

// 导出节目单
export function exportPlayBill(playBillIds) {
  if (playBillIds.length === 0) {
    playBillIds[0] = -1;
  }
  return request({
    url: "/rc/playBill/export/" + playBillIds,
    method: "get",
    // params: query,
  });
}

// 查询节目单详情列表
export function listDetails(query) {
  return request({
    url: "/rc/playBill/details/list",
    method: "get",
    params: query,
  });
}
// 批量删除节目单中的媒体
export function delPlayBills(playBillIds) {
  return request({
    url: "/rc/playBill/removes/" + playBillIds,
    method: "delete",
  });
}
// 查询节目单详情时间列表
export function getBillTime(query) {
  return request({
    url: "/rc/playBill/details",
    method: "get",
    params: query,
  });
}
// 查询节目单已绑的媒体资源列表
export function getAlreadyMedia(query) {
  return request({
    url: "/rc/playbill/list/media/already",
    method: "get",
    params: query,
  });
}
// 查询节目单需要绑定的媒体资源列表
export function getToMedia(query) {
  return request({
    url: "/rc/playbill/list/media/to",
    method: "get",
    params: query,
  });
}
// 批量新增绑定的媒体资源
export function getAddMedia(data) {
  return request({
    url: "/rc/playbill/add/medias",
    method: "post",
    data: data,
  });
}
// 删除绑定的媒体资源
export function getRemoveMedia(playbillIds) {
  return request({
    url: "/rc/playbill/remove/media/" + playbillIds,
    method: "delete",
  });
}

