import request from '@/utils/request'

// 查询设备状态日志列表
export function listStatusLog(query) {
  return request({
    url: '/rc/operation/list',
    method: 'get',
    params: query
  })
}

// 清空操作日志
export function cleanStatusLog() {
  return request({
    url: '/monitor/statusLog/cleanStatusLog',
    method: 'post'
  })
}


// 删除设备状态日志
export function delStatusLog(statusId) {
  return request({
    url: '/monitor/statusLog/delStatusLog/' + statusId,
    method: 'post'
  })
}


// 导出设备状态日志
export function exportStatusLog(query) {
  return request({
    url: '/rc/operation/export',
    method: 'get',
    params: query
  })
}

// 修改设备运行状态
export function updateStatus(data) {
  return request({
    url: '/rc/operation' ,
    method: 'put',
    data
  })
}
// 查询未读个数
export function selectRcDeviceOperationCountByInfoState(data) {
  return request({
    url: '/rc/operation/selectRcDeviceOperationCountByInfoState' ,
    method: 'GET',
  })
}

//全部设置为已读
export function infoAllRead() {
  return request({
    url: '/rc/operation/infoAllRead' ,
    method: 'GET',
  })
}



