<template>
  <div :style="{color,fontSize: size + 'px', textAlign}">
    {{time}}
  </div>
</template>

<script>

import {clockConfig} from "./config";
import {parseTime} from "@/utils/ruoyi";

export default {
  name: "w-clock",
  components: {},
  props: {
    format: {
      type: String,
      default: clockConfig.children.props.format
    },
    color: {
      type: String,
      default: clockConfig.children.props.color
    },
    fontSize: {
      type: Number,
      default: clockConfig.children.props.fontSize
    },
    textAlign: {
      type: String,
      default: clockConfig.children.props.textAlign
    }
  },
  computed: {
    size(){
      return this.fontSize * this.$store.getters.width / 1000
    }
  },
  data() {
    return {
      time: parseTime(new Date(), this.format),
      intervalId: null
    };
  },
  methods: {

  },
  mounted() {
    this.intervalId = setInterval(()=>{
      this.time = parseTime(new Date(), this.format)
    }, 1000)
  },
  destroyed() {
    if(this.intervalId){
      clearInterval(this.intervalId)
    }
  }
}
</script>

<style scoped>

</style>
