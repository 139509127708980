var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "runMap",
      style: {
        height: _vm.height,
        backgroundImage: "url(" + _vm.background + ")",
      },
    },
    [
      _vm._m(0),
      _c("div", { staticClass: "runPath" }, [
        _c("img", {
          attrs: { src: _vm.trackImageUrl, width: "100%", alt: "" },
        }),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "p_title" }, [
      _c("span", [_vm._v("赛道地图")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }