import Vue from 'vue'

import Cookies from 'js-cookie'

import Element from 'element-ui'
import './assets/styles/element-variables.scss'

import '@/assets/styles/index.scss' // global css
import '@/assets/styles/ruoyi.scss' // ruoyi css
import App from './App'
import store from './store'
import router from './router'
import directive from './directive' //directive
import plugins from './plugins' // plugins
import apis from '@/api'

import './assets/icons' // icon
import './permission' // permission control
import { getDicts } from "@/api/system/dict/data";
import { getConfigKey } from "@/api/system/config";
import { parseTime, resetForm, addDateRange, addDateObjRange, selectDictLabel, selectDictLabels, handleTree } from "@/utils/ruoyi";
import { nameFormat, priceFormat, hourFormat, customLeastTimeFormat } from '@/api/common/formats'
import { forbidSpecialChar, forbidEmoji, forbidPunctuation } from '@/api/common/valid'
// 分页组件
import Pagination from "@/components/Pagination";
// 自定义表格工具组件
import RightToolbar from "@/components/RightToolbar"
// 富文本组件
import Editor from "@/components/Editor"
// 文件上传组件
import FileUpload from "@/components/FileUpload"
// 图片上传组件
import ImageUpload from "@/components/ImageUpload"
// 字典标签组件
import DictTag from '@/components/DictTag'
import DictLabel from '@/components/DictLabel'
// 头部标签组件
import VueMeta from 'vue-meta'
// 字典数据组件
import DictData from '@/components/DictData'
//  全屏组件
import fullscreen from 'vue-fullscreen'
// 设备端
import Bridge from './config/JSbridge.js'
import VConsole from 'vconsole';

import {nanoid} from "nanoid";
import {connect} from "mqtt";
import mitt from 'mitt'
import { compressAccuratelyEnd } from '@/utils/compress' //图片压缩api


import Quill from 'quill'
import imageResize from 'quill-image-resize-module'
window.Quill = Quill
Quill.register('modules/imageResize', imageResize)

// props默认离开后点击不关闭
Element.Dialog.props.closeOnClickModal.default = false

// 全局方法挂载
Vue.prototype.getDicts = getDicts
Vue.prototype.getConfigKey = getConfigKey
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.addDateObjRange = addDateObjRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.handleTree = handleTree
Vue.prototype.$API = apis
Vue.prototype.$compressAccurately = compressAccuratelyEnd
Vue.prototype.$bus = new Vue() //组件通讯

// 全局方法挂载
Vue.prototype.nameFormat = nameFormat
Vue.prototype.priceFormat = priceFormat
Vue.prototype.hourFormat = hourFormat
Vue.prototype.customLeastTimeFormat = customLeastTimeFormat
// Vue.prototype.contentFormat = contentFormat
// Vue.prototype.remarksFormat = remarksFormat
// Vue.prototype.upgradeFormat = upgradeFormat
Vue.prototype.$bridge = Bridge
Vue.prototype.forbidSpecialChar = forbidSpecialChar
Vue.prototype.forbidPunctuation = forbidPunctuation
// 全局组件挂载
Vue.component('DictTag', DictTag)
Vue.component('DictLabel', DictLabel)
Vue.component('Pagination', Pagination)
Vue.component('RightToolbar', RightToolbar)
Vue.component('Editor', Editor)
Vue.component('FileUpload', FileUpload)
Vue.component('ImageUpload', ImageUpload)
// http://datav.jiaminghi.com/    dataV组件
import dataV from '@jiaminghi/data-view'
import {vConsoleEnable} from "@/settings";
if(process.env.NODE_ENV !== 'production'){
  const vConsole = new VConsole({
    theme: 'light' // 可选值是：dark
  });
}

Vue.use(dataV)

Vue.use(directive)
Vue.use(plugins)
Vue.use(VueMeta)
Vue.use(fullscreen)
DictData.install()

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */

Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size
})

Vue.config.productionTip = false

export const emitter = mitt()
let mqttClient = null
// mqtt
// export const mqttClient  = connect(process.env.VUE_APP_MQTT, {
//   connectTimeout: 4000,
//   reconnectPeriod: 1000,
//   clean: true,
//   clientId: nanoid(),
//   username: process.env.VUE_APP_MQTT_USERNAME,
//   password: process.env.VUE_APP_MQTT_PASSWORD
// })
// mqttClient.on('message',  (topic, message) => {
//   emitter.emit(topic, {topic, message})
// })
// mqtt客户端
// Vue.prototype.$mqttClient = mqttClient
if(process.env.NODE_ENV === 'development'){
  // 延时是为了模拟获取deviceCode
  setTimeout(()=>{
    // mqtt
    mqttClient = connect(process.env.VUE_APP_MQTT, {
      connectTimeout: 4000,
      reconnectPeriod: 1000,
      clean: true,
      clientId: 'deviceCode_' + nanoid(),
      username: process.env.VUE_APP_MQTT_USERNAME,
      password: process.env.VUE_APP_MQTT_PASSWORD
    })

    mqttClient.on('connect', () => {
      console.log(`mqtt：${process.env.VUE_APP_MQTT}  连接成功`)
    })

    mqttClient.on('message',  (topic, message) => {
      emitter.emit(topic, {topic, message})
    })
    // mqtt客户端
    Vue.prototype.$mqttClient = mqttClient
  }, 500)
}else {
  Bridge.callHandler("searchDeviceCode", "", (data) => {
    console.log('设备相关信息', data)
    var settings = JSON.parse(data);
    store.commit('setDeviceCode', settings.deviceCode)
    // mqtt
    mqttClient = connect(process.env.VUE_APP_MQTT, {
      connectTimeout: 4000,
      reconnectPeriod: 1000,
      clean: true,
      clientId: settings.deviceCode + "_" + nanoid(),
      username: process.env.VUE_APP_MQTT_USERNAME,
      password: process.env.VUE_APP_MQTT_PASSWORD
    })

    mqttClient.on('connect', () => {
      console.log(`mqtt：${process.env.VUE_APP_MQTT}  连接成功`)
    })

    mqttClient.on('message',  (topic, message) => {
      emitter.emit(topic, {topic, message})
    })
    // mqtt客户端
    Vue.prototype.$mqttClient = mqttClient
  });
}


export {mqttClient}

Vue.prototype.$emitter = emitter

// 只允许输入数字、字母、中文和常见标点符号的自定义指令
Vue.directive('only-allow', {
  bind: function (el, binding) {
    el.handler = function (event) {
      //
      let reg = /^[a-zA-Z0-9\u4e00-\u9fa5\x20-\x7e，。？！【】（）《》、“”‘’；：…￥【】（）％＃＠＆＊＋－／＝～｜＿｛｝＼＜＞～·～｀　]+$/;
      if (!reg.test(event.target.value)) {
        event.target.value = event.target.value.replace(/[^a-zA-Z0-9\u4e00-\u9fa5\x20-\x7e，。？！【】（）《》、“”‘’；：…￥【】（）％＃＠＆＊＋－／＝～｜＿｛｝＼＜＞～·～｀　]/g, '');
      }
    }
    el.addEventListener('input', el.handler);
  },
  unbind: function (el) {
    el.removeEventListener('input', el.handler);
  }
})

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
