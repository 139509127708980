import request from '@/utils/request'

//获取办赛需求赛事合同方案详细信息
export function getContractInfo(params) {
  return request({
    url: `/emd/config/share/getInfo/` + params,
    method: 'get',
  })
}
//获取参数配置
export function getSysConfig(params) {
  return request({
    url:  `/common/getSysConfig/` + params,
    method: 'get',
  })
}
