import request from '@/utils/request'

//节目素材统计
export function countAdAnalyze() {
  return request({
    url: '/ad/ad_record/count_ad_analyze',
    method: 'get'
  })
}

// 设备投放数统计
export function listAdAnalyzeByGreenway(query) {
  return request({
    url: '/ad/ad_record/list_ad_analyze_by_greenway',
    method: 'get',
    params: query
  })
}
