import request from '@/utils/request'

// 查询芯片订单列表
export function listChip(query) {
  return request({
    url: '/order/chip/list',
    method: 'get',
    params: query
  })
}


// 导出芯片订单
export function exportChip(query,orderIds) {
  if (0 === orderIds.length) {
    orderIds = [-1];
  }
  return request({
    url: '/order/chip/export/' + orderIds,
    method: 'get',
    params: query
  })
}
// 保存单号
export function saveTrack(orderId,postDeliveryNo,expressCompanyName, bingingChip) {
  return request({
    url: '/order/chip/saveTrack/'+ orderId + "/"  +postDeliveryNo + "/" +expressCompanyName,
    method: 'post',
    data: bingingChip
  })
}


//查询所有未绑定芯片列表
export function selectMRRfidProductionList(chipId, rfidShowNo) {
  return request({
    url: '/order/chip/selectMRRfidProductionList/'+ chipId,
    method: 'get',
    params: {
      rfidShowNo: rfidShowNo,
    }
  })

}

//获取申请人信息
export function getApplyUserInformation(data) {
  return request({
    url: '/order/chip/getApplyUserInformation',
    method: 'get',
    params: data
  })
}
