import request from '@/utils/request'
import {console} from "vuedraggable/src/util/helper";

// 查询绿道列表
export function listTrack(query) {
  return request({
    url: '/greenway/track/list',
    // method: 'get',
    method: 'post',

    params: {
      pageNum: query.pageNum,
      pageSize: query.pageSize,
    },
    data: query,
  })
}

// 查询绿道详细
export function getTrack(greenwayId) {
  return request({
    url: '/greenway/track/' + greenwayId,
    method: 'get'
  })
}

// 新增绿道
export function addTrack(data) {
  return request({
    url: '/greenway/track',
    method: 'post',
    data: data
  })
}

// 修改绿道
export function updateTrack(data) {
  return request({
    url: '/greenway/track/edit',
    method: 'post',
    data: data
  })
}

// 删除绿道
export function delTrack(greenwayId) {
  return request({
    url: '/greenway/track/del/' + greenwayId,
    method: 'post'
  })
}

// 导出绿道excel
export function exportTrack(greenwayIds) {
  if (0 === greenwayIds.length) {
    greenwayIds = [-1];
  }
  return request({
    url: '/greenway/track/export/' + greenwayIds,
    method: 'get',
  })
}

//下载导入模板
export function importTemplate() {
  return request({
    url: '/greenway/track/importTemplate',
    method: 'get',
  })
}

export function greenwayTrackImport(greenway){
  return request({
    url: '/greenway/track/greenwayTrackImport',
    method: 'POST',
    data: greenway
  })
}

// 导出绿道csv
export function exportTrackCsv(greenwayIds) {
  if (0 === greenwayIds.length) {
    greenwayIds = [-1];
  }
  return request({
    url: '/greenway/track/exportCsv/' + greenwayIds,
    method: 'get',
  })
}


//修改赛道状态
export function updateStatus(greenwayId) {
  return request({
    url: '/greenway/track/updateStatus/' + greenwayId,
    method: 'post',
  })

}

//获取赛道地址经纬度
export function getAddressCoordinates(address) {
  return request({
    url: '/greenway/track/getAddressCoordinates',
    method: 'get',
    params:{
      "address":address
    }
  })

}

//根据经度纬度获取地址信息
export function getAddressInfo(lat, lng) {
  return request({
    url: '/greenway/track/getAddressInfo/'+lat +'/' + lng,
    method: 'get',
  })
}

//地图关键字搜索
export function getKeywordAddressList(address) {
 return request({
   url: '/greenway/track/getKeywordAddressList',
   method: 'get',
   params: {
     "keyword":address
   },
 })
}

