import request from '@/utils/request'

// 查询左侧节点
export function queryNodeList(query) {
  return request({
    url: '/emd/emdExecuteConfig/share/getLeftNode',
    method: 'get',
    params: query
  })
}

// 查询右侧table
export function queryNodeFlowList(query) {
  return request({
    url: '/emd/emdExecuteConfig/share/getRightTable',
    method: 'get',
    params: query
  })
}

// 查询活动基本信息
export function getEventInfo(query) {
  return request({
    url: '/emd/emdExecuteConfig/share/getEventInfo',
    method: 'get',
    params: query
  })
}

