var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "bigDataScreenContentRef",
      staticClass: "main",
      style: {
        height: _vm.contentHeight + "px",
        backgroundColor: _vm.backgroundColor,
      },
    },
    [
      _c(
        "grid-layout",
        {
          ref: "gridlayout",
          attrs: {
            layout: _vm.layout,
            "col-num": 100,
            "row-height": _vm.contentHeight / 100,
            "max-rows": 100,
            "is-draggable": true,
            "is-resizable": true,
            "is-mirrored": false,
            "vertical-compact": false,
            margin: [0, 0],
            "use-css-transforms": true,
            "prevent-collision": true,
            isDraggable: _vm.mode === "edit",
            isResizable: _vm.mode === "edit",
          },
          on: {
            "update:layout": function ($event) {
              _vm.layout = $event
            },
          },
        },
        _vm._l(_vm.layout, function (item) {
          return _c(
            "grid-item",
            {
              key: item.i,
              class: _vm.classNames(
                "vue-grid-item",
                { "vue-grid-item__edit": _vm.mode === "edit" },
                {
                  "vue-grid-item__selected":
                    _vm.mode === "edit" && item.selected,
                }
              ),
              attrs: {
                x: item.x,
                y: item.y,
                w: item.w,
                h: item.h,
                i: item.i,
                preserveAspectRatio: item.preserveAspectRatio,
              },
              on: { move: _vm.moveEvent, moved: _vm.movedEvent },
            },
            [
              _c(
                "div",
                {
                  staticStyle: { height: "100%" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.selectGridItem(item.i)
                    },
                  },
                },
                [
                  _vm.mode === "edit"
                    ? _c(
                        "span",
                        {
                          staticClass: "remove",
                          on: {
                            click: function ($event) {
                              return _vm.removeItem(item.i)
                            },
                          },
                        },
                        [_vm._v("x")]
                      )
                    : _vm._e(),
                  item.children
                    ? _c(
                        item.children.component,
                        _vm._g(
                          _vm._b(
                            { tag: "component" },
                            "component",
                            item.children.props,
                            false
                          ),
                          item.children.events
                        )
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }