<template>
  <div class="box">
    <div :style="{fontSize: size + 'px', color,textAlign: textAlign,width: '100%', height: '100%'}">
      {{value}}
    </div>
  </div>
</template>

<script>
import {textConfig} from "@/views/device/widget/text/config";
import Log from "@/views/monitor/job/log";

export default {
  name: "w-text",
  components: {},
  props: {
    value: {
      type: String,
      default: textConfig.children.props.value
    },
    fontSize: {
      type: [Number, String],
      default: textConfig.children.props.fontSize
    },
    color: {
      type: String,
      default: textConfig.children.props.color
    },
    textAlign: {
      type: String,
      default: textConfig.children.props.textAlign
    }
  },
  computed: {
    size(){
      console.log('哈哈哈',this.fontSize * this.$store.getters.width / 1000)
      return this.fontSize * this.$store.getters.width / 1000
    }
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {
  }
}
</script>

<style scoped>
.box{
  width: 100%;
  height: 100%;
}
</style>
