import request from '@/utils/request'

// 查询小程序主图列表
export function listBanner(query) {
  return request({
    url: '/operate/banner/list',
    method: 'get',
    params: query
  })
}

// 查询小程序主图详细
export function getBanner(bannerId) {
  return request({
    url: '/operate/banner/getBanner/' + bannerId,
    method: 'get'
  })
}

// 新增小程序主图
export function addBanner(data) {
  return request({
    url: '/operate/banner/addBanner',
    method: 'post',
    data: data
  })
}

// 修改小程序主图
export function updateBanner(data) {
  return request({
    url: '/operate/banner/updateBanner',
    method: 'POST',
    data: data
  })
}

// 删除小程序主图
export function delBanner(bannerId) {
  return request({
    url: '/operate/banner/del/' + bannerId,
    method: 'GET'
  })
}

// 获取所有公开赛事
export function listOpenMatchBase(query){
  return request({
    url: '/match/establish/listOpenMatchBase',
    method: 'GET',
    params: query
  })
}

