import request from '@/utils/request'

// 查询上线版本日志
export function listReleaseNote(query) {
    return request({
    url: '/monitor/releaseNote/listReleaseNote',
    method: 'get',
    params: query
  })
}
