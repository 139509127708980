import request from '@/utils/request'

// 查询运营赛事定制服务列表
export function listService(query) {
  return request({
    url: '/operate/service/list',
    method: 'get',
    params: query
  })
}

// 查询运营赛事定制服务详细
export function getService(serviceId) {
  return request({
    url: '/operate/service/' + serviceId,
    method: 'get'
  })
}

// 新增运营赛事定制服务
export function addService(data) {
  return request({
    url: '/operate/service',
    method: 'post',
    data: data
  })
}

// 修改运营赛事定制服务
export function updateService(data) {
  return request({
    url: '/operate/service/update',
    method: 'post',
    data: data
  })
}

// 删除运营赛事定制服务
export function delService(serviceId) {
  return request({
    url: '/operate/service/delete/' + serviceId,
    method: 'post'
  })
}

// 根据定制服务查询对应的赛事信息列表
export function getMatchBaseListByService(query) {
  return request({
    url: '/match/establish/listByService',
    method: 'get',
    params: query
  })
}
