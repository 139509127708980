import request from '@/utils/request'

// 团队赛数据表list
export function listRealTimeReport(query) {
  return request({
    url: '/match/realTimeReport/list',
    method: 'get',
    params: query
  })
}

// 赛事记录list
export function raceRecordList(query) {
  return request({
    url: '/match/realTimeReport/raceRecordList',
    method: 'get',
    params: query
  })
}

// 导出
export function exportList(query) {
  return request({
    url: '/match/realTimeReport/export',
    method: 'get',
    params: query
  })
}
