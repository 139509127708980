import request from "@/utils/request";

// 查询芯片记录列表
export function listProduction(query) {
  return request({
    // url: '/rc/production/list',
    url: "/rc/production/DtoList",
    method: "get",
    params: query,
  });
}

// 查询运营芯片商品管理列表
export function listChip(query) {
  return request({
    url: "/rc/production/chipList",
    method: "get",
    params: query,
  });
}

// 查询设备信息列表
export function listDevice(query) {
  return request({
    url: "/rc/info/list",
    method: "get",
    params: query,
  });
}

// 查询芯片记录详细
export function getProduction(id) {
  return request({
    url: "/rc/production/" + id,
    method: "get",
  });
}

// 新增芯片记录
export function addProduction(data) {
  return request({
    url: "/rc/production/add",
    method: "post",
    data: data,
  });
}

// 修改芯片记录
export function updateProduction(data) {
  return request({
    url: "/rc/production",
    method: "put",
    data: data,
  });
}

// 报损芯片记录
export function delProduction(id) {
  return request({
    url: "/rc/production/" + id,
    method: "delete",
  });
}

// 导出芯片记录
export function exportProduction(params,ids) {
  if (ids.length === 0) {
    ids[0] = -1;
  }
  return request({
    url: "/rc/production/export/" + ids,
    method: "get",
    params: params
  });
}

// 下载用户导入模板
export function importTemplate() {
  return request({
    url: "/rc/production/importTemplate",
    method: "get",
  });
}

// 分配芯片
// export function postRelation(data) {
//   return request({
//     url: "/rc/relation",
//     method: "post",
//     data: data,
//   });
// }
// 分配芯片（方式一：开始结束号码）
export function postRelation(data) {
  return request({
    url: "/rc/relation/add/part",
    method: "post",
    data: data,
  });
}
// 分配芯片（方式二：号码段）
export function chooseRelation(data) {
  return request({
    url: "/rc/relation/add/choose",
    method: "post",
    data: data,
  });
}
// 删除芯片分配
export function noMedia(id) {
  return request({
    url: "/rc/relation/remove/" + id,
    method: "delete",
  });
}

// 所有最近赛事
export function allRecentMatch(query) {
  return request({
    url: "/rc/screenMatch/allRecentMatch",
    method: "get",
    params: query,
  });
}
// 查询货道管理列表
export function cargoList(data) {
  return request({
    url: "/rc/way/CargoList/" + data,
    method: "get",
  });
}
// 查询芯片类型列表
export function listType(query) {
  return request({
    url: "/rc/RfidType/list",
    method: "get",
    params: query,
  });
}
//批量取消分配号码
export function removeRelation(rfidEpcNos) {
  return request({
    url: "/rc/relation/remove/" + rfidEpcNos,
    method: "delete",
  });
}
