<template>
  <div class="runMap" :style="{height:height,backgroundImage:'url('+ background +')'}">
    <div class="p_title"><span>赛道地图</span></div>
    <div class="runPath">
     <img :src="trackImageUrl" width="100%"  alt="" />

    </div>
  </div>
</template>
<script>
import { getTrackImage,} from "@/api/device/screen-preview/screen-preview";
export default {
  name: "TrackImage",
  props:['heightTrackImage','backgroundTrackImage'],
  computed: {

  },
  data() {
    return {
      //图片路径
      trackImageUrl: "",
      deviceCode: "",
      //容器高度
      height:this.heightTrackImage,
      //边框
      background:require('@/assets/images/'+this.backgroundTrackImage),
    };
  },
  created(){
    this.getDeviceCode();
    if (this.$route.query.deviceCode) {
      this.deviceCode = this.$route.query.deviceCode;
      this.getImage();
    }
  },
  mounted(){
  },
  methods: {
    /** 赛事大屏地图 ,:backgroundTrackImage*/
    getImage() {
      getTrackImage({ deviceCode:this.deviceCode }).then((res) => {
        // this.trackImageUrl = process.env.VUE_APP_BASE_API + res.data;
        this.trackImageUrl = process.env.VUE_APP_BASE_API + res.data.pictureUrl
        console.log("赛事大屏地图",res.data)
        console.log("赛事大屏地图trackImageUrl",this.trackImageUrl)
      });
    },
    // 查询设备编号
    getDeviceCode() {
      this.$bridge.callHandler("searchDeviceCode", "", (data) => {
        var settings = JSON.parse(data);
        this.deviceCode = settings.deviceCode;

        if (this.deviceCode) {
          this.getImage();
        }
      });
    },
  },
  destroyed() {

  }
}
</script>

<style lang="scss" scoped>
.runMap {
  background-size: 100% 100%;
  padding: 5% 0 2% 5%;
  background-repeat: no-repeat,no-repeat;
  position: relative;
  .p_title {
    position: absolute;
    top: 1%;
    left:1%;
  }
  .runPath {
    position: absolute;
    left: 5%;
    top: 15%;
    right: 5%;
    bottom: 5%;
    overflow: hidden;
  }
}
</style>
