<template>
  <div
    :class="classObj"
    class="app-wrapper"
    :style="{ '--current-color': theme }"
  >
    <div class="copyShareLink" @click="copyShareLinkFn">点击复制分享链接</div>
    <div
      v-if="device === 'mobile' && sidebar.opened"
      class="drawer-bg"
      @click="handleClickOutside"
    />
    <SettingsDuplicateIndex class="sidebar-container" />
    <div :class="{ hasTagsView: needTagsView }" class="main-container">
      <div :class="{ 'fixed-header': fixedHeader }">
        <DuplicateNavbar />
        <!-- <DuplicateTagsView v-if="needTagsView" /> -->
      </div>
      <app-main />
      <right-panel>
        <settings />
      </right-panel>
    </div>
  </div>
</template>

<script>
import RightPanel from "@/components/RightPanel";
import {
  AppMain,
  DuplicateNavbar,
  Settings,
  SettingsDuplicateIndex,
  DuplicateTagsView,
} from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
import { mapState } from "vuex";
import variables from "@/assets/styles/variables.scss";
import { Message } from "element-ui";
import { getMainBase } from "@/api/sharingPlanView/index.js";
let getCUrrentShareRoles = "";
export default {
  name: "sharingPlanView",
  components: {
    AppMain,
    DuplicateNavbar,
    RightPanel,
    Settings,
    DuplicateTagsView,
    SettingsDuplicateIndex,
  },
  mixins: [ResizeMixin],
  computed: {
    ...mapState({
      theme: (state) => state.settings.theme,
      sideTheme: (state) => state.settings.sideTheme,
      sidebar: (state) => state.app.sidebar,
      device: (state) => state.app.device,
      needTagsView: (state) => state.settings.tagsView,
      fixedHeader: (state) => state.settings.fixedHeader,
    }),
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile",
      };
    },
    variables() {
      return variables;
    },
  },
  async beforeRouteEnter(to, from, next) {
    if (!to.query.mainId) {
      Message.error("找不到网页！");
      next({ path: "/404" });
      return;
    }
    // 每次进路由重新检查
    getCUrrentShareRoles = ""
    let currentRoles = getCUrrentShareRoles;
    try {
      if (!currentRoles) {
        const res = await getMainBase({ emdMainId: to.query.mainId });
        if (res.code === 200 && res.data) {
          getCUrrentShareRoles = res.data;
          currentRoles = getCUrrentShareRoles
        }else {
          Message.error("找不到网页！");
          next({ path: "/404" });
        }
      }
      if (
        (to.meta.pageFrom && currentRoles[to.meta.pageFrom] === "Y") ||
        [
          "shareEventPlanOverview",
          "shareOrganizerInformation",
          "shareRedirect",
        ].includes(to.name)
      ) {
        next();
      } else {
        Message.error("找不到网页！");
        next({ path: "/404" });
      }
    } catch (e) {
      Message.error("找不到网页！");
      next({ path: "/404" });
    }
  },
  created(){
    if(!sessionStorage.getItem("shareTextToCopy")) {
      sessionStorage.setItem("shareTextToCopy",location.href)
    }
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch("app/closeSideBar", { withoutAnimation: false });
    },
    copyShareLinkFn() {
      const textToCopy = sessionStorage.getItem("shareTextToCopy");
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard
          .writeText(textToCopy)
          .then(() => {
            this.$notify({
              type: "success",
              title:"分享方案链接复制成功",
              message: "已复制到剪切板(默认快捷键ctrl+v或command+v)",
              duration:5000
            });
          })
          .catch((error) => {
            this.$notify({
              type: "error",
              title:"失败",
              message: "分享方案链接复制失败",
            });
          });
      } else {
        // 创建text area
        const textArea = document.createElement("input");
        textArea.value = textToCopy;
        // 使text area不在viewport，同时设置不可见
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        new Promise((resolve, reject) => {
          // 执行复制命令并移除文本框
          document.execCommand("copy")
            ? resolve()
            : reject(new Error("出错了"));
          textArea.remove();
        }).then(
          () => {
            this.$notify({
              type: "success",
              title:"分享方案链接复制成功",
              duration:5000,
              message: "已复制到剪切板(默认快捷键ctrl+v或command+v)",
            });
          },
          () => {
            this.$notify({
              type: "error",
              title:"失败",
              message: "分享方案链接复制失败",
            });
          }
        );
      }
    },
  },
};
</script>
<style>
/* 分享页面text类型按钮默认14px */
/*  .el-button.el-button--text {
    font-size: 14px;
  }*/
</style>
<style lang="scss" scoped>
@import "~@/assets/styles/mixin.scss";
@import "~@/assets/styles/variables.scss";

.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
  .copyShareLink {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    writing-mode: vertical-rl;
    position: fixed;
    width: 36px;
    height: 140px;
    border-radius: 20px 0 0 20px;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2px;
    right: -60px;
    top: 200px;
    z-index: 999;
    background: var(--theme-color);
    color: #fff;
    animation: cartoonIn 0.8s 1s forwards, cartoonOut 0.8s 4s forwards;
    &:hover {
      animation-play-state: paused;
      animation: cartoonIn 0.8s 1s forwards;
    }
  }
  @keyframes cartoonIn {
    0% {
      opacity: 0;
    }
    100% {
      right: 0;
      opacity: 1;
    }
  }
  @keyframes cartoonOut {
    0% {
      right: 0;
      opacity: 1;
      align-items: center;
    }
    100% {
      right: -16px;
      opacity: 0.7;
      align-items: flex-end;
    }
  }
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$base-sidebar-width});
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}

.mobile .fixed-header {
  width: 100%;
}
</style>
