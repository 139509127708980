import request from '@/utils/request'

// 查询素材内容列表
export function listMaterial(query) {
  return request({
    url: '/ad/ad_content/list_ad_contents',
    method: 'get',
    params: {
      customerId:query.customerId,
      contentName:query.contentName,
      folderId:query.folderId,
      contentType:query.type,
      delFlag:query.delFlag,
      auditStatus:query.auditStatus,
      auditStatusList:query.auditStatusList,
      contentResolution:query.resolution,
      cooperatorId:query.cooperatorId,
      pageNum:query.pageNum,
      pageSize:query.pageSize
    }
  })
}
//查询素材审核列表
export function listAuditMaterial(query) {
  return request({
    url: '/ad/ad_content/list_ad_contents_audit',
    method: 'get',
    params: query
  })
}

// 批量新增广告素材
export function addMaterial(data) {
  return request({
    url: '/ad/ad_content/add_ad_content',
    method: 'post',
    data: data
  })
}
//修改广告内容
export function editMaterial(data) {
  return request({
    url: '/ad/ad_content/edit_ad_content',
    method: 'put',
    params: data
  })
}
// 批量删除广告内容
export function delMaterial(contentIds) {
  return request({
    url: '/ad/ad_content/delete',
    method: 'delete',
    params:{
      adContentIds:contentIds,
    }
  })
}

// 批量查询是否可删除广告内容
export function isRemovable(contentIds) {
  return request({
    url: '/ad/ad_content/is_ad_content_removable',
    method: 'get',
    params:{
      adContentIds:contentIds,
    }
  })
}

// 批量移动素材内容
export function moveTo(contentIds,new_parentId) {
  return request({
    url: '/ad/ad_content/move_to',
    method: 'put',
    params:{
      contentIds:contentIds,
      new_parentId:new_parentId,
    }
  })
}
// 审核广告内容
export function audit(contentIds,auditStatus,auditComment) {
  return request({
    url: '/ad/ad_content/audit',
    method: 'get',
    params:{
      contentIds,
      auditStatus,
      auditComment,
    }
  })
}

//素材上下架功能
export function setContentOnline(adContentIds,online) {
  return request({
    url: '/ad/ad_content/set_ad_content_online',
    method: 'get',
    params:{
      adContentIds:adContentIds,
      online:online,
    }
  })
}

//查询代理商名下已授权/素材总数
export function getAuditSummary() {
  return request({
    url: '/ad/ad_content/get_audit_summary',
    method: 'get'
  })
}
