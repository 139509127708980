import request from '@/utils/request'

// 统计数量
export function totalsessions() {
  return request({
    url: '/fontPage/statistics/totalsessions',
    method: 'get'
  })
}

// 所有赛事列表
export function totalSessionsList(query) {
  return request({
    url: '/fontPage/statistics/totalSessionsList',
    method: 'get',
    params: query
  })
}
// 总参赛人员列表
export function getParticipantsList(query) {
  return request({
    url: '/fontPage/statistics/getParticipantsList',
    method: 'get',
    params: query
  })
}
// 总完赛人员列表
export function getFinishersList(query) {
  return request({
    url: '/fontPage/statistics/getFinishersList',
    method: 'get',
    params: query
  })
}
// 今日参赛人员列表
export function getEnterTodayList(query) {
  return request({
    url: '/fontPage/statistics/getEnterTodayList',
    method: 'get',
    params: query
  })
}

// 正在进行赛事列表
export function getProcessingList(query) {
  return request({
    url: '/fontPage/statistics/getProcessingList',
    method: 'get',
    params: query
  })
}
// 正在进行赛事列表
export function tournamentStatistics(statisticsType) {
  return request({
    url: '/fontPage/statistics/tournamentStatistics/'+statisticsType,
  })
}
// 正在进行赛事列表
export function halfYearPersonChart() {
  return request({
    url: '/fontPage/statistics/halfYearPersonChart',
    method: 'get'
  })
}
// 最近半年芯片统计
export function halfYearChipChart() {
  return request({
    url: '/fontPage/statistics/halfYearChipChart',
    method: 'get'
  })
}
// 最近半年完赛奖品及领取统计
export function halfYearMedalChart() {
  return request({
    url: '/fontPage/statistics/halfYearMedalChart',
    method: 'get'
  })
}
//最近半年比赛赛事统计
export function halfYearMatchChart() {
  return request({
    url: '/fontPage/statistics/halfYearMatchChart',
    method: 'get'
  })
}
