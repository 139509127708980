import request from "@/utils/request";

// 查询芯片类型列表
export function listType(query) {
  return request({
    url: "/rc/RfidType/list",
    method: "get",
    params: query,
  });
}

// 新增芯片类型
export function addType(data) {
  return request({
    url: "/rc/RfidType",
    method: "post",
    data: data,
  });
}

// 修改芯片类型
export function updateType(data) {
  return request({
    url: "/rc/RfidType",
    method: "put",
    data: data,
  });
}

// 删除芯片类型
export function delType(typeId) {
  return request({
    url: "/rc/RfidType/" + typeId,
    method: "delete",
  });
}

// 查询芯片类型详细
export function getType(typeId) {
  return request({
    url: "/rc/RfidType/" + typeId,
    method: "get",
  });
}

// 导出芯片类型
export function exportType(ids) {
  if (ids.length === 0) {
    ids[0] = -1;
  }
  return request({
    url: "/rc/RfidType/export/" + ids,
    method: "get",
    // params: query,
  });
}

// 根据芯片类型获取rfid芯片入库剩余数量
export function getTypeNum(rfidTypeCode) {
  return request({
    url: "/rc/production/count/" + rfidTypeCode,
    method: "get",
  });
}
