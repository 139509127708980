// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/datascreen/图层2备份@2x.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n.p_name[data-v-796991b6] {\n  background: -webkit-gradient(linear, left top, right top, from(#41fee8), to(#084bd5));\n  background: linear-gradient(to right, #41fee8, #084bd5);\n  padding: 5px 0px;\n}\n.marquee[data-v-796991b6] {\n  position: relative;\n  padding: 0 1.5vw 2vh 2vw;\n  overflow: hidden;\n}\n.marquee .personalMatch[data-v-796991b6] {\n  margin: 20px 40px;\n  font-size: 24px;\n  color: #a5fffd;\n}\n.marquee .personalMatch .data[data-v-796991b6] {\n  width: 100%;\n  background: #f7bcbc;\n}\n.marquee .personalMatch .data .ones[data-v-796991b6] {\n  width: 33%;\n  text-align: center;\n  float: left;\n  padding-top: 50px;\n}\n.marquee .personalMatch .data .ones > div[data-v-796991b6]:first-child {\n  font-size: 40px;\n  margin-bottom: 20px;\n}\n.marquee .personalMatch .time[data-v-796991b6] {\n  clear: both;\n  padding-top: 100px;\n  text-align: center;\n  -webkit-box-pack: right;\n      -ms-flex-pack: right;\n          justify-content: right;\n}\n.marquee1 .p_title[data-v-796991b6] {\n  z-index: 100;\n  position: absolute;\n  top: 10px;\n  left: 10px;\n}\n.marquee1[data-v-796991b6]  .el-carousel__container {\n  height: 81vh !important;\n  overflow: hidden;\n}\n.marquee2[data-v-796991b6] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 0 0;\n  background-size: 100% 100%;\n}\n.marquee2[data-v-796991b6]  .el-carousel__container {\n  height: 22.5vh !important;\n  overflow: hidden;\n}\n.girlGrop[data-v-796991b6] {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  /* 禁止换行  normal可以换行 */\n}\n.p_top[data-v-796991b6] {\n  margin: 16px 0;\n  margin-left: 200px !important;\n  width: 450px;\n  text-align: center;\n  font-size: 20px;\n  color: #0ec0be;\n}\n.p_dataScreen[data-v-796991b6] {\n  text-align: center;\n  margin: 16px 0;\n  color: #0ec0be;\n  font-size: 20px;\n}\n[data-v-796991b6] .el-carousel__indicators {\n  display: none;\n}", ""]);
// Exports
module.exports = exports;
