import request from '@/utils/request'

// 查询赛事统计报表列表
export function listMatch(query) {
  return request({
    url: '/operate/statictis/listMatch',
    method: 'get',
    params: query
  })
}

// 查询赛事收入统计报表列表
export function listPay(query) {
  return request({
    url: '/operate/statictis/listPay',
    method: 'get',
    params: query
  })
}

// 查询赛事芯片统计报表列表
export function listChip(query) {
  return request({
    url: '/operate/statictis/listChip',
    method: 'get',
    params: query
  })
}

// 导出
export function exportStatement(query,ids) {
  if (0 === ids.length) {
    ids = [-1];
  }
  return request({
    url: '/operate/statictis/export/'+ids,
    method: 'get',
    params: query
  })
}

// 统计
export function statistics(query) {
  return request({
    url: '/operate/statictis/statistics',
    method: 'get',
    params: query
  })
}
