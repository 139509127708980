var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        container: true,
        marquee1: _vm.index === 1,
        marquee2: _vm.index === 2,
      },
      style: { height: _vm.height },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.index === 2,
              expression: "index === 2",
            },
          ],
          staticClass: "p_title",
        },
        [_c("span", [_vm._v("个人赛事")])]
      ),
      _c(
        "div",
        { staticClass: "personalMatch" },
        [
          _c(
            "el-row",
            { staticClass: "information", attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c("el-avatar", {
                    attrs: {
                      shape: "square",
                      size: 130,
                      src: _vm.personalData.avatar,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { padding: "10px" }, attrs: { span: 8 } },
                [
                  _c("div", { staticClass: "userName scollData" }, [
                    _vm._v(_vm._s(_vm.personalData.userName)),
                  ]),
                  _c("div", { staticClass: "userName" }, [
                    _vm._v(
                      " 累计参赛：" +
                        _vm._s(_vm.personalData.totalEntries) +
                        "场 "
                    ),
                  ]),
                ]
              ),
              _c(
                "el-col",
                { staticStyle: { position: "relative" }, attrs: { span: 4 } },
                [
                  _c("img", {
                    staticStyle: { margin: "20px" },
                    attrs: {
                      src: require("@/assets/images/编组15@2x.png"),
                      width: "90",
                      alt: "",
                    },
                  }),
                ]
              ),
              _c("el-col", { attrs: { span: 7 } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "line-height": "130px",
                      "font-size": "76px",
                      "margin-left": "20px",
                    },
                  },
                  [_vm._v(" NO." + _vm._s(_vm.personalData.rankingValue) + " ")]
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "match", staticStyle: { "line-height": "10px" } },
            [
              _c("p", [
                _vm._v("赛事项目：" + _vm._s(_vm.personalData.matchName)),
              ]),
              _c(
                "p",
                [
                  _vm._v(" 赛事状态："),
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        "font-size": "20px",
                        padding: "5px 20px",
                        color: "#a5fffd",
                      },
                      attrs: { type: "primary" },
                    },
                    [_vm._v(_vm._s(_vm.personalData.matchType))]
                  ),
                ],
                1
              ),
              _c("p", [
                _vm._v("参赛时间：" + _vm._s(_vm.personalData.timeOfEntry)),
              ]),
            ]
          ),
          _c("div", { staticClass: "data" }, [
            _c("div", { staticClass: "ones" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.personalData.matchMileage,
                      expression: "personalData.matchMileage",
                    },
                  ],
                },
                [_vm._v(" " + _vm._s(_vm.personalData.matchMileage) + " ")]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.personalData.matchMileage,
                      expression: "!personalData.matchMileage",
                    },
                  ],
                },
                [_vm._v("123")]
              ),
              _c("div", [_vm._v("赛事里程")]),
            ]),
            _c("div", { staticClass: "ones" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.personalData.finishTime,
                      expression: "personalData.finishTime",
                    },
                  ],
                },
                [_vm._v(" " + _vm._s(_vm.personalData.finishTime) + " ")]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.personalData.finishTime,
                      expression: "!personalData.finishTime",
                    },
                  ],
                },
                [_vm._v("123")]
              ),
              _c("div", [_vm._v("完赛时长")]),
            ]),
            _c("div", { staticClass: "ones" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.personalData.punchInStatistics,
                      expression: "personalData.punchInStatistics",
                    },
                  ],
                },
                [_vm._v(" " + _vm._s(_vm.personalData.punchInStatistics) + " ")]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.personalData.punchInStatistics,
                      expression: "!personalData.punchInStatistics",
                    },
                  ],
                },
                [_vm._v("123")]
              ),
              _c("div", [_vm._v("打卡统计")]),
            ]),
            _c("div", { staticClass: "ones" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.personalData.averagePace,
                      expression: "personalData.averagePace",
                    },
                  ],
                },
                [_vm._v(" " + _vm._s(_vm.personalData.averagePace) + " ")]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.personalData.averagePace,
                      expression: "!personalData.averagePace",
                    },
                  ],
                },
                [_vm._v("123")]
              ),
              _c("div", [_vm._v("平均配速(KM/H)")]),
            ]),
            _c("div", { staticClass: "ones" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.personalData.fastestPace,
                      expression: "personalData.fastestPace",
                    },
                  ],
                  staticClass: "bigData",
                },
                [_vm._v(" " + _vm._s(_vm.personalData.fastestPace) + " ")]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.personalData.fastestPace,
                      expression: "!personalData.fastestPace",
                    },
                  ],
                },
                [_vm._v("123")]
              ),
              _c("div", [_vm._v("最快配送(KM/H)")]),
            ]),
            _c("div", { staticClass: "ones" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.personalData.consumptionOfEnergy,
                      expression: "personalData.consumptionOfEnergy",
                    },
                  ],
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.personalData.consumptionOfEnergy) + " "
                  ),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.personalData.consumptionOfEnergy,
                      expression: "!personalData.consumptionOfEnergy",
                    },
                  ],
                },
                [_vm._v("123")]
              ),
              _c("div", [_vm._v("千卡(kcal)")]),
            ]),
          ]),
          _c("div", { staticClass: "time" }, [
            _c("p", [_vm._v(_vm._s(_vm.time) + "s后返回赛事排名")]),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }