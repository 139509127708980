import WText from "@/views/device/widget/text/w-text";
import WTextConfig from "@/views/device/widget/text/w-text-config";

export const textConfig = {
  "x": null,
  "y": null,
  "w": 10,
  "h": 5,
  "i": null,
  label: '文字',
  icon: 'el-icon-tickets',
  children: {
    component: 'w-text', // WText.name,
    componentConfig: 'w-text-config', // WTextConfig.name,
    props: {
      value: '文字',
      fontSize: 14,
      color: '#ffd700',
      textAlign: 'left',
    },
  },
  selected: false
}
