import request from '@/utils/request'
import {shareLinkUrlSuffix} from './index'

// 查询客户基础信息
export function queryCustomBase(query) {
  return request({
    url: '/emd/Message/share/queryCustomBase',
    method: 'get',
    params: query
  })
}

// 查询客户信息表
export function queryCustomInfo(query) {
  return request({
    url: '/emd/Message/share/queryCustomInfo',
    method: 'get',
    params: query
  })
}


