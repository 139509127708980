<template>
    <div>
      {{time}}
    </div>
</template>
<script>
    export default {
      name: "w-clock",
      computed: {
        size(){
          return this.fontSize * this.$store.getters.width / 1000
        }
      },
      data() {
        return {
            format: '{y}年{m}月{d}日 {h}:{i}:{s} 星期{a}',
          time: this.parseTime(new Date(), this.format),
          intervalId: null
        };
      },
      methods: {
        parseTime(time, cFormat) {
          if (arguments.length === 0) {
            return null
          }
          const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
          let date
          if (typeof time === 'object') {
            date = time
          } else {
            if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
              time = parseInt(time)
            }
            if ((typeof time === 'number') && (time.toString().length === 10)) {
              time = time * 1000
            }
            date = new Date(time)
          }
          const formatObj = {
            y: date.getFullYear(),
            m: date.getMonth() + 1,
            d: date.getDate(),
            h: date.getHours(),
            i: date.getMinutes(),
            s: date.getSeconds(),
            a: date.getDay()
          }
          const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
            let value = formatObj[key]
            // Note: getDay() returns 0 on Sunday
            if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
            if (result.length > 0 && value < 10) {
              value = '0' + value
            }
            return value || 0
          })
        //   console.log(time_str);
          return time_str
        }
      },
      mounted() {
        this.intervalId = setInterval(()=>{
          this.time = this.parseTime(new Date(), this.format)
        }, 1000)
      },
      destroyed() {
        if(this.intervalId){
          clearInterval(this.intervalId)
        }
      }
    }
    </script>
    
    <style scoped>
    </style>
    
  