import request from "@/utils/request";

// 查询已绑定的设备
export function listDeviceAlready(query) {
  return request({
    url: "/rc/space/list/device/already",
    method: "get",
    params: query,
  });
}

// 查询需要绑定的设备 /rc/space/list/device/to
export function listDeviceToBind(query) {
  return request({
    url: "/rc/space/list/device/to",
    method: "get",
    params: query,
  });
}

// 批量新增绑定的设备 /rc/space/add/devices
export function addDevices(data) {
  return request({
    url: "/rc/space/add/devices",
    method: "post",
    data: data,
  });
}

// 删除绑定的设备
export function deleteDevice(data) {
  return request({
    url: "/rc/space/remove/device",
    method: "post",
    data: data,
  });
}
