import request from '@/utils/request'

// 查询自定义广告列表
export function listCustom(query) {
  return request({
    url: '/rc/custom/list',
    method: 'get',
    params: query
  })
}

// 查询自定义广告详细
export function getCustom(customId) {
  return request({
    url: '/rc/custom/' + customId,
    method: 'get'
  })
}

// 新增自定义广告
export function addCustom(data) {
  return request({
    url: '/rc/custom',
    method: 'post',
    data: data
  })
}

// 修改自定义广告
export function updateCustom(data) {
  return request({
    url: '/rc/custom',
    method: 'put',
    data: data
  })
}

// 删除自定义广告
export function delCustom(customId) {
  return request({
    url: '/rc/custom/' + customId,
    method: 'delete'
  })
}

// 导出自定义广告
export function exportCustom(query) {
  return request({
    url: '/rc/custom/export',
    method: 'get',
    params: query
  })
}


// 查询一条自定义广告
export function queryCustom(customId) {
  return request({
    url: '/rc/custom/one/' + customId,
    method: 'get'
  })
}
// 审核
export function auditPlaylist(mediaId, auditStatus) {
  return request({
    url: `/rc/custom/examine/${mediaId},${auditStatus}`,
    method: "put",
  });
}

//导出自定义广告到Excel
export function exportCustomExcel(ids) {
  if (0 === ids.length) {
    ids = [-1];
  }
  return request({
    url: '/rc/custom/exportCustomExcel/' + ids,
    method: 'get',
  })
}
//导出自定义广告到Csv
export function exportCustomCsv(ids) {
  if (0 === ids.length) {
    ids = [-1];
  }
  return request({
    url: '/rc/custom/exportCustomCsv/' + ids,
    method: 'get',
  })
}

