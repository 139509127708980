import { render, staticRenderFns } from "./w-img-config.vue?vue&type=template&id=31322eba&scoped=true&"
import script from "./w-img-config.vue?vue&type=script&lang=js&"
export * from "./w-img-config.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31322eba",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/echo/zuwen/hyzp_vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('31322eba')) {
      api.createRecord('31322eba', component.options)
    } else {
      api.reload('31322eba', component.options)
    }
    module.hot.accept("./w-img-config.vue?vue&type=template&id=31322eba&scoped=true&", function () {
      api.rerender('31322eba', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/device/widget/img/w-img-config.vue"
export default component.exports