import request from "@/utils/request";

// 查询广告位列表
export function listSpace(query) {
  return request({
    url: "/rc/space/list/space",
    method: "get",
    params: query,
  });
}

// 查询广告位详细
export function getSpace(spaceId) {
  return request({
    url: "/rc/space/" + spaceId,
    method: "get",
  });
}

// 新增广告位
export function addSpace(data) {
  return request({
    url: "/rc/space/add/space",
    method: "post",
    data: data,
  });
}

// 修改广告位
export function updateSpace(data) {
  return request({
    url: "/rc/space",
    method: "put",
    data: data,
  });
}

// 删除广告位
export function delSpace(spaceCodes) {
  return request({
    url: "/rc/space/remove/space/" + spaceCodes,
    method: "delete",
  });
}

// 导出广告位
export function exportSpace(ids) {
  if (ids.length === 0) {
    ids[0] = -1;
  }
  return request({
    url: "/rc/space/export/" + ids,
    method: "get",
    // params: query,
  });
}
// 查询媒体列表
export function listMedia(query) {
  return request({
    url: "/rc/media/list",
    method: "get",
    params: query,
  });
}
// 绑定媒体
// 绑定广告 /rc/space/add/launch
export function bindPlayBill(query) {
  return request({
    url: "/rc/space/add/launch",
    method: "post",
    data: query,
  });
}
// url: "/rc/list",

// 解绑媒体 /rc/space/remove/launch/{spaceCode}
export function noMedia(spaceCode) {
  return request({
    url: "/rc/space/remove/launch/" + spaceCode,
    method: "delete",
  });
}
// url: "/rc/list/" + query,

// 查询设备唯一编码列表
export function listInfo(query) {
  return request({
    url: "/rc/info/ScreenList",
    method: "get",
    params: query,
  });
}

//下发广告位()
// export function issueSpace(data) {
//   return request({
//     url: "/rc/space/issue",
//     method: "put",
//     data: data,
//   });
// }

// 启用禁用广告位 /rc/space/active/{spaceId},{canUse}
export function issueSpace(spaceCode, canUse, endTime) {
  return request({
    url: `/rc/space/active/${spaceCode},${canUse},${endTime}`,
    method: "put",
  });
}

// 解绑广告 /rc/space/remove/launch/{spaceCode}
