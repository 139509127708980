import request from '@/utils/request'

// c查询短信模板
export function findList(query) {
  return request({
    url: '/operate/smsTemplate/findList',
    method: 'get',
    params: query
  })
}

export function byDelete(templateId) {
  return request({
    url: `/operate/smsTemplate/delete/${templateId}`,
    method: 'get'
  })
}
export function byDeleteAll(params){
  return request({
    url: '/operate/smsTemplate/deleteBatch',
    method: 'post',
    data: params
  })
}
/*保存未提交*/
export function bySave(params) {
  return request({
    url: '/operate/smsTemplate/save',
    method: 'post',
    data: params
  })
}
/*提交*/
export function bySubmit(params) {
  return request({
    url: '/operate/smsTemplate/submit',
    method: 'post',
    data: params
  })
}
export function byUpdate(params) {
  return request({
    url: '/operate/smsTemplate/update',
    method: 'post',
    data: params
  })
}
export function bySaveUpdate(params) {
  return request({
    url: '/operate/smsTemplate/saveUpdate',
    method: 'post',
    data: params
  })
}
/*提交审核*/
export function bySubmitAudit(params) {
  return request({
    url: '/operate/smsTemplate/submitAudit',
    method: 'post',
    data: params
  })
}
/*模板启用/禁用*/
export function byDisable(params) {
  return request({
    url: '/operate/smsTemplate/disable',
    method: 'post',
    data: params
  });
}
export function byRefresh() {
  return request({
    url: '/operate/smsTemplate/refresh',
    method: 'post'
  });
}
export function getSmsTemplateById(templateId) {
  return request({
    url: `/operate/smsTemplate/getSmsTemplateById/${templateId}`,
    method: 'get'
  });
}
