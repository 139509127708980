var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { className: "box" } },
    [
      _c("hr", {
        staticStyle: {
          border: "#ffffff 1px solid",
          "margin-bottom": "2px",
          "margin-top": "0px",
        },
      }),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%", overflow: "hidden" },
          attrs: {
            data: _vm.list,
            "row-style": { height: "40px" },
            "header-cell-style": {
              "text-align": "center",
              background: "rgb(255,255,255,0.3)",
            },
            "cell-style": { "text-align": "center", padding: "5px" },
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "rankingValue",
              label: "排名",
              "show-overflow-tooltip": true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.rankingValue == 1
                      ? _c("img", {
                          attrs: {
                            width: "35",
                            height: "28",
                            src: require("../../../../assets/images/first.png"),
                            alt: "",
                          },
                        })
                      : scope.row.rankingValue == 2
                      ? _c("img", {
                          attrs: {
                            width: "35",
                            height: "28",
                            src: require("../../../../assets/images/twice.png"),
                            alt: "",
                          },
                        })
                      : scope.row.rankingValue == 3
                      ? _c("img", {
                          attrs: {
                            width: "35",
                            height: "28",
                            src: require("../../../../assets/images/third.png"),
                            alt: "",
                          },
                        })
                      : _c("span", [_vm._v(_vm._s(scope.row.rankingValue))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "playUserName",
              label: "用户名",
              align: "center",
              "show-overflow-tooltip": true,
              width: "200px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          "justify-content": "flex-start",
                          "margin-left": "20px",
                        },
                      },
                      [
                        _c("span", { staticStyle: { "margin-left": "10px" } }, [
                          _vm._v(_vm._s(scope.row.playUserName)),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "score",
              label: _vm.scoreLabel,
              "show-overflow-tooltip": true,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }