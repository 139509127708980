<template>
  <div class="card">
    <div class="main" v-for="item in infoList" :key="item.id">
      <span class="name">{{ item.deviceName }}</span>
<!--      <span class="code">{{ item.deviceCode }}</span>-->
<!--      <span class="address">{{ item.devicePosition }}</span>-->
      <div class="state">
        <span :style="{color:item.activeFlag ? '' : 'red'}">{{ item.activeFlag ? "在线" : "离线" }}</span>
        <img
          v-show="item.activeFlag"
          style="width: 20px; height: 20px"
          src="@/assets/images/online1.png"
        />
        <img
          v-show="!item.activeFlag"
          style="width: 20px; height: 20px"
          src="@/assets/images/offline1.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["infoList"],
};
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
  .main {
    background-color: #fff;
    //width: 47%;
    width: 100%;
    padding: 10px;
    display: flex;
    //flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;
    margin: 5px 5px;

    span {
      margin-bottom: 5px;
    }
    .name {
      //font-size: 14px;
      font-size: 16px;
      font-weight: bold;
    }
    .code {
      font-size: 12px;
      color: #909399;
    }
    .address {
      font-size: 12px;
      background: #f8f8f8;
      border-radius: 8px;
      padding: 0 5px;
      margin-bottom: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .state {
      //font-size: 12px;
      font-size: 16px;
      //color: #909399;
      color: rgb(137,228,211);
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 10%;
      span{
        margin-right: 10px;
        width: 32px;
      }
    }
  }
}
</style>
