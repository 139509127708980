<template>
  <div
    class="recentMatch"
    :style="{ height: height, backgroundImage: 'url(' + background + ')' }"
  >
    <div class="p_title p_title_down">热门赛事</div>
    <div class="container">
      <hr style="border: #ffffff 1px solid; margin-bottom: 2px" />
      <el-row class="p_name">
        <!--        <el-col :span="15" style="text-align: center">赛事名称</el-col>-->
        <!--        <el-col :span="9" style="text-align: center">比赛时间</el-col>-->

        <el-col :span="4" style="text-align: center">类别</el-col>
        <el-col :span="12" style="text-align: center">赛事名称</el-col>
        <el-col :span="8" style="text-align: center">比赛日期</el-col>
      </el-row>

      <div
        class="scollData"
        style="
          height: 100%;
          overflow: scroll;
          overflow-x: hidden;
          overflow-y: scroll;
        "
      >
        <vue-seamless-scroll
          :class-option="classOption"
          :data="listRecentMatch"
          ref="seamlessScrollRecent"
        >
          <el-row
            :gutter="10"
            style="margin-top: 1.5vh; height: 5.5vh"
            v-for="(item, index) in listRecentMatch"
            :key="index"
          >
            <el-col :span="4" class="line" style="text-align: center">
              {{ item.matchClassify == "INSIDE" ? "内部赛" : "公开赛" }}
            </el-col>
            <el-col :span="12" class="line" style="padding-left: 5%">
              {{ item.matchName }}
            </el-col>
            <el-col :span="8">
              <div class="spot">
                {{ item.matchStartTime.replace(/-/g, "/") }} ~
                {{ item.matchEndTime.replace(/-/g, "/") }}
                <!--              <div-->
                <!--                class="spotGram"-->
                <!--                :style="{-->
                <!--                                background:-->
                <!--                                  item.matchClassify == 'OPEN'-->
                <!--                                    ? '#ffb454'-->
                <!--                                    : '#0ec0be',-->
                <!--                              }"-->
                <!--              >-->
                <!--                              <span v-if="item.matchClassify == 'INSIDE'"-->
                <!--                              >内部赛</span-->
                <!--                              >-->
                <!--                <span v-else-if="item.matchClassify == 'OPEN'"-->
                <!--                >公开赛</span-->
                <!--                >-->
                <!--              </div>-->
              </div>
            </el-col>
          </el-row>
        </vue-seamless-scroll>
      </div>
    </div>
    <!--    <div class="marqueeCover"></div>-->
  </div>
</template>
<script>
import { listRecentMatchForm } from "@/api/device/screen-preview/screen-preview";
import vueSeamlessScroll from "vue-seamless-scroll";

var source = null;
export default {
  name: "RecentMatch",
  props: ["heightRecentMatch", "backgroundRecentMatch"],
  components: {
    vueSeamlessScroll,
  },
  computed: {},
  data() {
    return {
      //滚动组件配置项
      classOption: {
        limitMoveNum: 3,
        hoverStop: false,
      },
      //定时
      intervalId: null,
      //容器的高
      height: this.heightRecentMatch,
      //容器边框
      background: require("@/assets/images/" + this.backgroundRecentMatch),
      //最近列表数据
      listRecentMatch: [
        // {
        //   matchStartTime: "03-12",
        //   matchEndTime: "03-14",
        //   matchClassify: "INSIDE",
        //   matchName:
        //     "51CTO博客已为您找到关于css字体大小手机端电脑端的相关内容,包含IT学习相关文档代码介绍、相关教程视频课程,以及css字体大小手机端电脑端问答内容。",
        // },
        // {
        //   matchStartTime: "03-12",
        //   matchEndTime: "03-14",
        //   matchClassify: "OPEN",
        //   matchName:
        //     "51CTO博客已为您找到关于css字体大小手机端电脑端的相关内容,包含IT学习相关文档代码介绍、相关教程视频课程,以及css字体大小手机端电脑端问答内容。",
        // },
        // {
        //   matchStartTime: "03-12",
        //   matchEndTime: "03-14",
        //   matchClassify: "INSIDE",
        //   matchName:
        //     "51CTO博客已为您找到关于css字体大小手机端电脑端的相关内容,包含IT学习相关文档代码介绍、相关教程视频课程,以及css字体大小手机端电脑端问答内容。",
        // },
        // {
        //   matchStartTime: "03-12",
        //   matchEndTime: "03-14",
        //   matchClassify: "INSIDE",
        //   matchName:
        //     "51CTO博客已为您找到关于css字体大小手机端电脑端的相关内容,包含IT学习相关文档代码介绍、相关教程视频课程,以及css字体大小手机端电脑端问答内容。",
        // },
      ],
      deviceCode: "",
    };
  },
  created() {
    // 如果路由有deviceCode，说明是在平台预览，否则是在大屏设备端展示
    if (this.$route.query.deviceCode) {
      this.deviceCode = this.$route.query.deviceCode;
      this.getRecentMatch();
    } else {
      this.getDeviceCode();
    }
  },
  methods: {
    /** 查询最近赛事列表 */
    getRecentMatch() {
      listRecentMatchForm(this.deviceCode).then((response) => {
        this.listRecentMatch = response.data;
        // listData length无变化，仅仅是属性变更，手动调用下组件内部的reset方法
        this.$refs.seamlessScrollRecent.reset();
      });
    },
    // 查询设备编号
    getDeviceCode() {
      this.$bridge.callHandler("searchDeviceCode", "", (data) => {
        var settings = JSON.parse(data);
        this.deviceCode = settings.deviceCode;
        if (this.deviceCode) {
          this.getRecentMatch();
        }
      });
    },
  },
  mounted() {
    this.intervalId = setInterval(() => {
      this.getRecentMatch();
    }, 5 * 60 * 1000);
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
};
</script>

<style lang="scss" scoped>
.recentMatch {
  box-sizing: border-box;
  font-size: 16px;
  margin-top: 2vh;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat, no-repeat;
  background-size: 100% 100%;
  padding: 5vh 1vw 2vh 1vw;
  .marqueeCover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    height: 100%;
    width: 100%;
  }
  .p_title_down {
    position: absolute;
    top: 5px;
    left: 10px;
  }
  .p_name {
    //background: linear-gradient(to right, #0c84ef, #0a1a48);
    background: rgb(255, 255, 255, 0.3);
    padding: 5px 0px;
  }
  .container {
    height: 95%;
    z-index: 10;
    overflow: hidden;
  }
  //两行或多行显示
  .line {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden; //溢出内容隐藏
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    display: -webkit-box; //特别显示模式
    -webkit-line-clamp: 2; //行数
    line-clamp: 2;
    -webkit-box-orient: vertical; //盒子中内容竖直排列
  }
  .scollData::-webkit-scrollbar {
    display: none;
  }
  .spot {
    position: relative;
    text-align: center;
    width: 100%;
    height: 4vh;

    .spotGram {
      position: absolute;
      width: 40%;
      height: 22px;
      left: 30%;
      border-radius: 8px;
      font-size: 10px;
      line-height: 22px;
    }
  }
}
</style>
