<template>
  <div class="overGame" :style="{height:height,backgroundImage:'url('+ background +')'}">
    <div class="p_title"><span>最近打卡</span></div>
    <div class="marquee">
      <el-row class="title">
        <el-col :span="10" style="text-align: center;width:42%">
          用户名
        </el-col>
        <el-col :span="4" style="text-align: center;width:16%">
          配速
        </el-col>
        <el-col :span="10" style="text-align: center;width:42%">
<!--          用时-->
          里程(KM)
        </el-col>
      </el-row>
      <div class="tbody" :style="{height:heightTbody}">
        <vue-seamless-scroll :class-option="classOption" :data="listComplete" ref="seamlessScrollOver">
          <el-row v-for="(item,index) in listComplete" :key="`${item.matchId}_${item.userId}_${item.playRecordId}`">
            <el-col class="scollData" style="text-align: center;width:42%">
              {{item.userName}}
            </el-col>
            <el-col style="text-align: center;width:16%">
              {{formatConversion(item.pace)}}
            </el-col>
            <el-col style="text-align: center;width:42%">
              {{item.totalMileage}}
            </el-col>
          </el-row>
        </vue-seamless-scroll>
      </div>

    </div>
    <!-- <div class="marqueeCover"></div> -->
  </div>
</template>
<script>
import {overGameDevice, playFinishRecord} from "@/api/device/screen-preview/screen-preview";
import {onMqttMessage, mqttTopic, unsubscribe} from "@/utils/mqtt";
import {updatePage} from "@/api/device/data-screen/data-screen";
import vueSeamlessScroll from 'vue-seamless-scroll'

var source = null;
export default {
  name: "OverGame",
  props:['heightOverGame','backgroundOverGame','heightTbody'],
  components: {
    vueSeamlessScroll
  },
  computed: {

  },
  data() {
    return {
      //滚动组件配置项
      classOption:{
        limitMoveNum:10,
        hoverStop: false,
      },
      //完赛数据
      listComplete: [
        // {userName:'1无完赛者完',pace:'0.456',costTime:'00:00:000',name:'内部联谊赛'},
        // {userName:'2无完赛者完赛者',pace:'0.456',costTime:'00:00:000',name:'公开赛'},
        // {userName:'3完赛者完赛者',pace:'0.456',costTime:'00:00:000',name:'公开赛'},
        // {userName:'4完赛者完赛者',pace:'0.456',costTime:'00:00:000',name:'内部联谊赛'},
        // {userName:'5完赛者完',pace:'0.456',costTime:'00:00:000',name:'内部联谊赛'},
        // {userName:'6完赛者完赛者',pace:'0.456',costTime:'00:00:000',name:'公开赛'},
        // {userName:'7完赛者完赛者',pace:'0.456',costTime:'00:00:000',name:'公开赛'},
        // {userName:'8完赛者完赛者',pace:'0.456',costTime:'00:00:000',name:'内部联谊赛'},
        // {userName:'9完赛者完',pace:'0.456',costTime:'00:00:000',name:'内部联谊赛'},
        // {userName:'10完赛者完赛者',pace:'0.456',costTime:'00:00:000',name:'公开赛'},
        // {userName:'11完赛者完',pace:'0.456',costTime:'00:00:000',name:'内部联谊赛'},
        // {userName:'12完赛者完赛者',pace:'0.456',costTime:'00:00:000',name:'公开赛'},
        // {userName:'13完赛者完赛者',pace:'0.456',costTime:'00:00:000',name:'公开赛'},
        // {userName:'14完赛者完赛者',pace:'0.456',costTime:'00:00:000',name:'内部联谊赛'},
        // {userName:'15完赛者完',pace:'0.456',costTime:'00:00:000',name:'内部联谊赛'},
        // {userName:'16完赛者完赛者',pace:'0.456',costTime:'00:00:000',name:'公开赛'},
        // {userName:'17完赛者完赛者',pace:'0.456',costTime:'00:00:000',name:'公开赛'},
        // {userName:'18完赛者完赛者',pace:'0.456',costTime:'00:00:000',name:'内部联谊赛'},
        // {userName:'19完赛者完',pace:'0.456',costTime:'00:00:000',name:'内部联谊赛'},
        // {userName:'20完赛者完赛者',pace:'0.456',costTime:'00:00:000',name:'公开赛'},
      ],
      deviceCode: "",
      //容器高度
      height:this.heightOverGame,
      //容器边框
      background:require('@/assets/images/'+this.backgroundOverGame),
      //大屏页面类型
      pageType:null,
      //mqtt定时
      timeHandle:null,

    };
  },
  created(){

    if (this.$route.query.deviceCode) {
      this.deviceCode = this.$route.query.deviceCode;
      this.getOverGame()
      this.getMqttData();
    }else{
      this.getDeviceCode()
    }
  },
  watch: {
  },
  methods: {
    // 查询设备编号
    getDeviceCode() {
      this.$bridge.callHandler("searchDeviceCode", "", (data) => {
        var settings = JSON.parse(data);
        this.deviceCode = settings.deviceCode;
        if (this.deviceCode) {
          this.getMqttData();
          this.getOverGame()
        }
      });
    },
    //mqtt订阅
    getMqttData(){
      // 数据大屏完赛topic
      onMqttMessage({
        topic: this.deviceCode + mqttTopic.finish_match_play_info,
      })
      this.$emitter.on(this.deviceCode + mqttTopic.finish_match_play_info, ({topic, message})=>{
        if(topic === this.deviceCode + mqttTopic.finish_match_play_info){
          console.log("完赛MQTT消息message",JSON.parse(message.toString()))
          //收到完赛消息重新调赛事数据、赛事排名接口
          this.$emitter.emit('getMqttData')
          if(this.pageType){
            //发送完赛消息重新定时
            this.timeset();
          }
          //判断收到的完赛信息是否有重复数据
          const msgObj = JSON.parse(message.toString())
          const obj = this.listComplete.filter(item=>(item.userId === msgObj.userId))
          // 如果数据中已经有了该数据，删除之前的数据, 添加到首位
          if(obj?.length){
            this.listComplete.forEach((item, index)=>{
              if(item.userId === msgObj.userId){
                // Object.assign(item, msgObj);
                this.listComplete.splice(index, 1);
              }
            })
            this.listComplete.unshift(msgObj)

          }else{
            //如果没有，就把最后一位删除，添加到最后一位
            this.listComplete.pop();
            this.listComplete.unshift(msgObj)
          }
          //完赛滚动数据更新
          // listData length无变化，仅仅是属性变更，手动调用下组件内部的reset方法
          this.$nextTick(()=>{
            if(this.$refs.seamlessScrollOver){
              this.$refs.seamlessScrollOver.reset()
            }else {
              console.log('this.$refs.seamlessScrollOver对象获取不到')
            }
          })
          //数据大于100条就删除最早的一条数据
          // if(this.listComplete.length>30){
          // if(this.listComplete.length>100){
          //   this.listComplete.pop()
          // }
        }
      })
    },
    //定时
    timeset(){
      //调用代码清除定时器
      clearTimeout(this.timeHandle);
      console.log('进入定时')
      //开启自动 && 赛事大屏：完赛mqtt计时十分钟没消息转广告大屏；10分钟有消息消除定时再重新定时十分钟
      this.timeHandle = setTimeout(() => {
        this.sendPageType()
      }, 10*60*1000);//10*60*1000
    },
    // 完赛mqtt计时十分钟没消息转广告大屏
    sendPageType(){
      this.pageType = "GG"
      this.$emitter.emit('changePageType',this.pageType)
    },
    //获取完赛数据
    getOverGame(){
      // overGameDevice(this.deviceCode).then((res) => {
      //   this.listComplete=[]
      //   if (res.data) {
      //     res.data.forEach((item,index)=>{
      //       // if(index<30){
      //       if(index<100){
      //             this.listComplete.push(item)
      //           }
      //     })
      //   }
      // });
      playFinishRecord().then((res) => {
        this.listComplete=[];
        if (res.data) {
          this.listComplete = res.data
        }
      })
    },
    //处理配速
    formatConversion(val){
      if(null != val && val !== '/' && val.indexOf(".") > 0){
        if (val === '') {
          return 0
        } else {
          let finalValue = "";
          let handleNum = parseFloat(val)
          let isToFixed = handleNum.toString().includes('.') && handleNum.toString().split('.')[1].length > 2
          if (isToFixed) {
            finalValue = handleNum.toFixed(2)
          } else {
            finalValue = handleNum
          }
          finalValue = finalValue.toString();
          let points = [];
          points = finalValue.split(".");
          let cutOut = "";
          let cutOutOne = "";
          let flag = true;
          points.forEach(item=>{
            if (flag) {
              cutOut = item;
            }
            if (!flag){
              cutOutOne = item;
            }
            flag = false;
          });
          return  cutOut + "′" + (cutOutOne ? cutOutOne + "″" : "");
        }
      }
      return  val;
    },
  },
  mounted() {
    // this.$nextTick(()=>{
    //   this.autoCycle()
    // })
    //监听赛事大屏跳转广告大屏事件
    this.$emitter.on('setTimeOutOverGame', (item)=>{
      this.pageType = item.pageType
      this.timeset();
    })

  },
  beforeDestroy() {
    // 销毁前取消定时
    if(this.timeHandle){
      clearTimeout(this.timeHandle)
    }
    if(this.deviceCode){
      // 销毁前取消订阅
      unsubscribe(this.deviceCode + mqttTopic.finish_match_play_info)
    }
  },
}
</script>

<style lang="scss" scoped>
.scollData{
  overflow: hidden;
  text-overflow: ellipsis;//多出部分以省略号形式隐藏
  white-space: nowrap;
  width:42%;/* 禁止换行  normal可以换行 */
}
.overGame {
  margin-top: 2vh;
  background-size: 100% 100%;
  background-repeat: no-repeat,no-repeat;
  padding: 5vh 1vw 2vh 1vw;
  position: relative;
  .p_title {
    position: absolute;
    top: 2%;
    left:1%;
  }
  .marqueeCover{
    position: absolute;
    top:0;
    left:0;
    z-index:100;
    height:100%;
    width:100%;
  }
  .marquee {
    height: 96%;
    padding:0;
    z-index:11;
    .title{
      //background: linear-gradient(to right, rgb(65, 254, 232), rgb(8, 75, 213));
    }
    .tbody{
      //height: 90%;
      overflow: hidden;
      overflow-x: hidden;
      overflow-y: scroll;
    }
    .el-row{
      line-height:40px;
      height:40px;
      font-size:18px;
      //color: #a5fffd;
      overflow: hidden;
    }
  }
  .tbody::-webkit-scrollbar {
    display: none;
  }
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper {
    overflow: hidden !important;
  }
}
</style>
