var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card" },
    _vm._l(_vm.infoList, function (item) {
      return _c("div", { key: item.id, staticClass: "main" }, [
        _c("span", { staticClass: "name" }, [_vm._v(_vm._s(item.deviceName))]),
        _c("div", { staticClass: "state" }, [
          _c("span", { style: { color: item.activeFlag ? "" : "red" } }, [
            _vm._v(_vm._s(item.activeFlag ? "在线" : "离线")),
          ]),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: item.activeFlag,
                expression: "item.activeFlag",
              },
            ],
            staticStyle: { width: "20px", height: "20px" },
            attrs: { src: require("@/assets/images/online1.png") },
          }),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !item.activeFlag,
                expression: "!item.activeFlag",
              },
            ],
            staticStyle: { width: "20px", height: "20px" },
            attrs: { src: require("@/assets/images/offline1.png") },
          }),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }