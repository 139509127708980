import request from "@/utils/request";

// 查询设备信息列表
export function listInfo(query) {
  return request({
    url: "/rc/info/ScreenList",
    method: "get",
    params: query,
  });
}

// 查询设备信息详细
export function getInfo(diId) {
  return request({
    url: "/rc/info/" + diId,
    method: "get",
  });
}

// 新增设备信息
export function addInfo(data) {
  return request({
    url: "/rc/info",
    method: "post",
    data: data,
  });
}

// 修改设备信息
export function updateInfo(data) {
  return request({
    url: "/rc/info",
    method: "put",
    data: data,
  });
}

// 删除设备信息
export function delInfo(diId) {
  return request({
    // url: "/rc/info/" + diId,
    url: "/rc/info/removeDevAndMore/" + diId,
    method: "delete",
  });
}

// 导出设备信息
export function exportInfo(dtCode, ids) {
  // if (ids.length === 0) {
  //   ids[0] = -1;
  // }
  return request({
    url: `/rc/info/export/${dtCode}&${ids}`,
    method: "get",
    // params: query,
  });
}

//清空单个设备的存包格口
export function clearCabinet(query) {
  return request({
    url: "/rc/cabinet/clearCabinet",
    method: "get",
    params: query,
  });
}

//清空单个设备的具体某个存包格口
export function clearCabinetDetail(query) {
  return request({
    url: "/rc/cabinet/clearCabinetDetail",
    method: "get",
    params: query,
  });
}

/** 详情 **/
// 查看存包柜详情
export function cabinetListInfo(query) {
  return request({
    url: "/rc/cabinet/cabinetList",
    method: "get",
    params: query,
  });
}
// 获取储物单元格详细信息
export function getSubCabinet(diId) {
  return request({
    url: "/rc/SubCabinet/" + diId,
    method: "get",
  });
}

// 新增储物单元格
export function addSubCabinet(data) {
  return request({
    url: "/rc/SubCabinet",
    method: "post",
    data: data,
  });
}
// 修改设备信息
export function updateSubCabinet(data) {
  return request({
    url: "/rc/SubCabinet",
    method: "put",
    data: data,
  });
}
// 删除设备详细信息
export function delSubCabinet(diId) {
  return request({
    url: "/rc/SubCabinet/" + diId,
    method: "delete",
  });
}
// 查看存包格密码
export function cabinetPsw(data) {
  return request({
    url: "/rc/cabinet/" + data,
    method: "get",
    // params: query
  });
}
// 解绑绿道
export function noTrack(query) {
  return request({
    url: "/rc/greenway_device/" + query,
    method: "delete",
  });
}

//存包格口开门发送消息
export function cabinetOpen(query) {
  return request({
    url: "/rc/cabinet/cabinetOpen",
    method: "get",
    params: query,
  });
}

//设备上架
export function deviceUpperFrame(diId) {
  return request({
    url: "/rc/info/device/deviceUpperFrame/" + diId,
    method: "get",
  });
}



//设备下架
export function deviceDownFrame(diId) {
  return request({
    url: "/rc/info/device/deviceDownFrame/" + diId,
    method: "get",
  });
}







