module.exports = {
  /**
   * 侧边栏主题 深色主题theme-dark，浅色主题theme-light
   */
  sideTheme: 'theme-dark',

  /**
   * 是否系统布局配置
   */
  showSettings: false,

  /**
   * 是否显示顶部导航
   */
  topNav: false,

  /**
   * 是否显示 tagsView
   */
  tagsView: true,

  /**
   * 是否固定头部
   */
  fixedHeader: false,

  /**
   * 是否显示logo
   */
  sidebarLogo: true,

  /**
   * 是否显示动态标题
   */
  dynamicTitle: false,

  /**
   * @type {string | array} 'production' | ['production', 'development']
   * @description Need show err logs component.
   * The default is only used in the production env
   * If you want to also use it in dev, you can pass ['production', 'development']
   */
  errorLog: 'production',

  /**
   * 是否启用vConsole
   */
  vConsoleEnable: true,

  /**
    *  当无token时路由重定向进入团建赛登录页规则
    *  @type[String]:   写法一、根据指定路径前缀来判断(模糊)
    *  @type[Function]: 写法二、自定义判断(精确)
    *  @return[String | Boolean]
    */
  enterLoginPage: (to,from) => {
    return (to.path || '').indexOf('emd') !== -1 //暂定
  },

}
