<template>
  <fullscreen :fullscreen.sync="fullscreen">
    <!--    版本时间   -->
    <!--    <version-time></version-time>-->
    <div class="screen2">
      <el-container style="height: 100%">
        <!--头部-->
        <el-header>
          <div class="mainTitle">
            <div class="top" @click="toggle()">{{ screenTitle }}</div>
          </div>
        </el-header>
        <!--主体-->
        <el-main style="padding-top: 43px">
          <!--日期时间-->
          <w-clock class="clock"></w-clock>
          <!--天气-->
          <w-weather class="weather"></w-weather>
          <!--比赛信息-->
          <div style="padding-top: 10px;height: 100%;">
            <big-screen-view></big-screen-view>
          </div>
        </el-main>
      </el-container>
    </div>
  </fullscreen>
</template>

<script>
  //时间
  import WClock from "./components/clock";
  //天气
  import WWeather from "./components/weather";

  //模块
  import TrackImage from "./module/TrackImage";
  import OverGame from "./module/OverGame1";
  import BigScreenView from "./components/bigScreenView";
  import CurrentMatch from "./module/CurrentMatch1";
  import RecentMatch from "./module/RecentMatch1";
  import TotalSessions from "./module/TotalSessions1";
  import PersonalMatch from "./module/PersonalMatch";
  import VersionTime from "@/views/device/screen-preview/module/VersionTime";

  export default {
    name: "index",
    components: {
      VersionTime,
      TotalSessions,
      WClock,
      WWeather,
      BigScreenView,
      TrackImage,
      OverGame,
      CurrentMatch,
      RecentMatch,
      PersonalMatch,
    },
    data() {
      return {
        //全屏预览
        fullscreen: false,
        deviceCode: "",
        //赛事排名和个人赛事页面切换
        ptitle: "赛事排名",
      };
    },
    props: ["screenTitle"],

    created() {},
    destroyed() {},

    methods: {
      //切换回赛事排名
      getTitle(temp) {
        this.ptitle = temp;
      },
      //刷卡进入个人赛事
      changePage(title) {
        this.ptitle = title;
        // if (this.ptitle == "赛事排名") {
        //   this.ptitle = "个人赛事";
        // }
      },

      //全屏预览
      toggle() {
        this.fullscreen = !this.fullscreen;
      },
    },
    mounted() {},
  };
</script>

<style lang="scss">
  .screen2 {
    //background: linear-gradient(180deg, #011868 0%, #0c1a43 43%, #051d62 100%);
    background: linear-gradient(to right, #00b2c0 0%, #2dc2af 100%);
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    color: #ffffff;
    position: relative;
    //头部
    .mainTitle {
      position: relative;
      /* 限定大小 */
      width: 100%;
      margin-top: 6px;
      height: 8.5vh;
      background: url(../../../assets/images/logo_lc1.png) no-repeat 0 0 / contain;
      background-position: center center;
      background-size: 100% 100%;

      .top {
        position: absolute;
        top: 45%;
        transform: translate(0, -50%);
        width: 100%;
        text-align: center;
        font-size: 28px;
        font-weight: bold;
      }
    }

    .clock {
      font-size: 22px;
      width: 280px;
      //display: inline-block;
      //text-align: center;
      position: absolute;
      top: 50px;
      right: 40px;
    }
    .weather {
      font-size: 22px;
      margin-left: 10px;
      display: inline-block;
      position: absolute;
      top: 50px;
      left: 40px;
    }
    .center {
      background: url(../../../assets/images/赛事排名1.png) no-repeat 0px 0px;
      background-size: 100% 100%;
      padding: 50px 0 20px 15px;
      position: relative;
      height: 90vh;
      .p_title {
        position: absolute;
        top: 20px;
        left: 20%;
      }
    }

    .left {
    }
    .right {
      .matchVideo {
        position: relative;
        height: 29vh;
        background: url(../../../assets/images/赛事讲解1.png) no-repeat 0 0;
        background-size: 100% 100%;
        padding: 5vh 2vw 2vh 2vw;
        box-sizing: border-box;
        overflow: hidden;

        .p_title {
          position: absolute;
          left: 0.5vw;
          top: 0;
        }
      }
    }

    //改变表格背景颜色
    .el-table,
    .el-table__expanded-cell {
      background-color: transparent;
      //color: #a5fffd;
      color: #ffffff;
    }
    //去掉最下面的边框
    .el-table::before {
      background-color: transparent;
    }
    .el-table {
      font-size: 16px;
      //表头样式
      .el-table__header-wrapper th,
      .el-table__fixed-header-wrapper th {
        font-size: 16px !important;
        color: #fff;
        font-weight: 500;
      }
      //表头行高
      .el-table__header tr,
      .el-table__header th {
        padding: 5px 0;
        height: 22px;
      }
      ////表头背景颜色
      //thead {
      //  //background: linear-gradient(to right, rgb(65, 254, 232), rgb(8, 75, 213));
      //  background: rgb(255,255,255,0.3);
      //  margin: 10px;
      //}
      th,
      tr {
        background-color: transparent;
        height: 30px;
      }
      tbody tr:nth-of-type(odd) {
        //background: rgba(23, 114, 186, 0.3);
      }
      //去掉边框
      tr,
      th,
      td {
        border: none;
        overflow: hidden;
      }
      //去掉表头下边框
      th.is-leaf {
        border-bottom: none;
      }
      //鼠标悬浮行背景色
      .el-table__body tr:hover > td {
        background-color: transparent !important;
      }
    }

    .p_title {
      font-size: 20px;
      font-weight: bold;
      padding: 5px 20px;
      width: 150px;

      span {
        display: block;
      }
    }

    .marquee {
      font-size: 16px;
      padding-left: 20px;
      width: 100%;
      font-weight: 500;
      .m_row {
        padding: 10px 0px 10px 15px;
        color: #a5fffd;
      }
      .m_row:nth-of-type(odd) {
        //background: rgba(23, 114, 186, 0.3);
      }
      .m_row_title {
        background: linear-gradient(to right, #0c84ef, #0a1a48);
        margin-bottom: 7px;
        color: white;
      }
    }
  }
</style>
