import request from '@/utils/request'

// 查询销售量ECharts
export function selectSalesVolumeList(query) {
  return request({
    url: '/dataManagement/SalesVolume/newList',
    method: 'get',
    params: query
  })
}

// 查询销售量列表
export function newSalesVolume(query) {
  return request({
    url: '/dataManagement/SalesVolume/newSalesVolumeList',
    method: 'get',
    params: query
  })
}
