import request from '@/utils/request'

//查询赛事总览
export function queryOverviewInfo(query) {
  return request({
    url: '/emd/generalView/queryOverviewInfo',
    method: 'get',
    params: query
  })
}

//保存客户可见不可见
export function saveClientSee(query) {
  return request({
    url: '/emd/generalView/saveClientSee',
    method: 'get',
    params: query
  })
}

//确认记录
export function confirmationRecord(query) {
  return request({
    url: '/emd/generalView/confirmationRecord',
    method: 'get',
    params: query
  })
}

//操作记录
export function moduleOperateRecord(query) {
  return request({
    url: '/emd/generalView/queryModuleOperateRecord',
    method: 'get',
    params: query
  })
}

//生成链接前校验
export function createLinkBeforeCheck(type, emdId){
  return request({
    url: '/emd/generalView/createLinkBeforeCheck/' + type + '/' + emdId,
    method: 'get',
  })
}

