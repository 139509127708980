import request from "@/utils/request";

// 查询设备信息列表
export function listInfo(query) {
  return request({
    url: "/rc/info/ScreenList",
    method: "get",
    params: query,
  });
}

// 查询设备信息详细
export function getInfo(diId) {
  return request({
    url: "/rc/info/" + diId,
    method: "get",
  });
}

// 新增设备信息
export function addInfo(data) {
  return request({
    url: "/rc/info",
    method: "post",
    data: data,
  });
}

// 修改设备信息
export function updateInfo(data) {
  return request({
    url: "/rc/info",
    method: "put",
    data: data,
  });
}

// 删除设备信息
export function delInfo(diId) {
  return request({
    // url: '/rc/info/' + diId,
    url: "/rc/info/removeDevAndMore/" + diId,
    method: "delete",
  });
}

// 导出设备信息
export function exportInfo(dtCode, ids) {
  // if (ids.length === 0) {
  //   ids[0] = -1;
  // }
  return request({
    url: `/rc/info/export/${dtCode}&${ids}`,
    method: "get",
    // params: query,
  });
}


//设备上架
export function deviceUpperFrame(diId) {
  return request({
    url: "/rc/info/device/deviceUpperFrame/" + diId,
    method: "get",
  });
}



//设备下架
export function deviceDownFrame(diId) {
  return request({
    url: "/rc/info/device/deviceDownFrame/" + diId,
    method: "get",
  });
}
