var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        marquee: true,
        marquee1: _vm.index === 1,
        marquee2: _vm.index === 2,
      },
      style: { height: _vm.height },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.index === 2,
              expression: "index === 2",
            },
          ],
          staticClass: "p_title",
        },
        [_c("span", [_vm._v("赛事排名 ")])]
      ),
      _vm._l(_vm.listCurrentMatchs, function (item, index) {
        return _c("div", { key: index }, [
          index === _vm.currentIndex
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    { class: _vm.marqueeIndex == 2 ? "p_top" : "p_dataScreen" },
                    [
                      item.matchName != "月度排行榜"
                        ? _c(
                            "span",
                            { staticStyle: { display: "block" } },
                            [
                              _vm._v(" " + _vm._s(item.matchName) + " "),
                              item.matchRule
                                ? [
                                    _vm._v(" （ "),
                                    _c("dict-label", {
                                      attrs: {
                                        options: _vm.dict.type.match_rule,
                                        value: item.matchRule,
                                      },
                                    }),
                                    _vm._v(" ） "),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        : _c(
                            "span",
                            {
                              staticStyle: {
                                display: "block",
                                "font-weight": "bold",
                                "font-size": "28px",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.month) +
                                  _vm._s(item.matchName) +
                                  " "
                              ),
                            ]
                          ),
                      item.lineGroup
                        ? [
                            _c("p", { staticClass: "lineGroup" }, [
                              _vm._v("组别：" + _vm._s(item.lineGroup) + "KM"),
                            ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  item.listData1 && item.listData2
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-row",
                            {
                              staticClass: "p_name",
                              staticStyle: {
                                padding: "7px",
                                "padding-top": "0px",
                              },
                            },
                            [
                              _c("el-col", { attrs: { span: 12 } }, [
                                _vm._v(_vm._s(item.thead1)),
                              ]),
                              _c("el-col", { attrs: { span: 12 } }, [
                                _vm._v(_vm._s(item.thead2)),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { staticClass: "doubleTable" },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c("match-table", {
                                    attrs: {
                                      list: item.listData1,
                                      matchRule:
                                        item.matchRule == "monthly"
                                          ? "LJLC"
                                          : item.matchRule,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 11 } },
                                [
                                  _c("match-table", {
                                    attrs: {
                                      list: item.listData2,
                                      matchRule:
                                        item.matchRule == "monthly"
                                          ? "DKCS"
                                          : item.matchRule,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : !item.listData2
                    ? _c("match-table", {
                        attrs: {
                          list: item.listData1,
                          matchRule: item.matchRule,
                          status: "1",
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }