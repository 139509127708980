import request from '@/utils/request'

// 查询客户基础信息
export function queryCustomBase(query) {
  return request({
    url: '/emd/Message/queryCustomBase',
    method: 'get',
    params: query
  })
}

// 查询客户信息表
export function queryCustomInfo(query) {
  return request({
    url: '/emd/Message/queryCustomInfo',
    method: 'get',
    params: query
  })
}

// 新增运营奖牌管理
export function addOrUpdateCustomInfo(data) {
  return request({
    url: '/emd/Message/addOrUpdateCustomInfo',
    method: 'post',
    data: data
  })
}

