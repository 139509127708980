var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _c(
      "div",
      {
        style: {
          fontSize: _vm.size + "px",
          color: _vm.color,
          textAlign: _vm.textAlign,
          width: "100%",
          height: "100%",
        },
      },
      [_vm._v(" " + _vm._s(_vm.value) + " ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }