// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/赛事讲解.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n.matchVideo[data-v-bbda2530] {\n  position: relative;\n  height: 29vh;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 0 0;\n  background-size: 100% 100%;\n  padding: 5vh 2vw 2vh 2vw;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  overflow: hidden;\n}\n.matchVideo .p_title[data-v-bbda2530] {\n  position: absolute;\n  left: 0.5vw;\n  top: 0;\n}\n.matchVideo .p_video[data-v-bbda2530] {\n  width: 100%;\n  height: 100%;\n}\n.matchVideo .p_video .imgClass[data-v-bbda2530] {\n  width: 100%;\n  height: 100%;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  position: relative;\n}\n.matchVideo .p_video .imgClass img[data-v-bbda2530] {\n  position: absolute;\n  left: 0;\n  top: 0;\n  bottom: 0;\n  right: 0;\n}\n.matchVideo[data-v-bbda2530]  .el-carousel__container {\n  height: 100%;\n}", ""]);
// Exports
module.exports = exports;
