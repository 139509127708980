<template>
  <div className="box">
    <hr
      style="border: #ffffff 1px solid; margin-bottom: 2px; margin-top: 0px"
    />
    <el-table
      :data="list"
      style="width: 100%; overflow: hidden"
      :row-style="{ height: '40px' }"
      :header-cell-style="{
        'text-align': 'center',
        background: 'rgb(255,255,255,0.3)',
      }"
      :cell-style="{ 'text-align': 'center', padding: '5px' }"
    >
      <el-table-column
        prop="rankingValue"
        label="排名"
        :show-overflow-tooltip="true"
      >
        <!--   v-if="headerStatus == true"-->
        <template slot-scope="scope" style="position: relative">
<!--          style="position: absolute; top: 5px; left: 44%"-->
          <img
            v-if="scope.row.rankingValue == 1"

            width="35"
            height="28"
            src="../../../../assets/images/first.png"
            alt=""
          />
          <img
            v-else-if="scope.row.rankingValue == 2"
            width="35"
            height="28"
            src="../../../../assets/images/twice.png"
            alt=""
          />
          <img
            v-else-if="scope.row.rankingValue == 3"
            width="35"
            height="28"
            src="../../../../assets/images/third.png"
            alt=""
          />
          <span v-else>{{ scope.row.rankingValue }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="playUserName"
        label="用户名"
        align="center"
        :show-overflow-tooltip="true"
        width="200px"
      >
        <template slot-scope="scope">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: flex-start;
              margin-left: 20px;
            "
          >
<!--            <el-avatar-->
<!--              :size="20"-->
<!--              :src="getImgPath(scope.row.avatar)"-->
<!--              fit="cover"-->
<!--            ></el-avatar>-->
            <span style="margin-left: 10px">{{ scope.row.playUserName }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="score"
        :label="scoreLabel"
        :show-overflow-tooltip="true"
      ></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "MatchTable",
  props: ["list", "status", "matchRule"],
  computed: {},
  data() {
    return {
      headerStatus: false,
      scoreLabel: "",
    };
  },
  created() {},
  watch: {
    status: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue == 1) {
          this.headerStatus = true;
        }
      },
    },
    matchRule: {
      immediate: true,
      handler(newValue, oldValue) {
        //比赛单位名称处理
        if (
          newValue == "DCPDK" ||
          newValue == "SXGRZHCJ"
        ) {
          // this.scoreLabel = "用时";
          this.scoreLabel = "完赛用时";
        } else if (newValue == "ZNTZS") {
          this.scoreLabel = "完成时间点";
        } else if (newValue == "DKCS") {
          this.scoreLabel = "打卡次数";
        } else if (newValue == "MBLC") {
          // this.scoreLabel = "完赛日期";
          this.scoreLabel = "完赛时间点";
        } else if (newValue == "LJLC") {
          // this.scoreLabel = "累计里程";
          this.scoreLabel = "里程";
        } else {
          this.scoreLabel = "赛事成绩";
        }
      },
    },
  },
  methods: {
    getImgPath(imgPath) {
      //http开头的路径
      // 正则匹配以 http:// 或者 https:// 开头
      if (/^(http|https):\/\//.test(imgPath)) {
        return imgPath;
      } else {
        //相对路径处理
        return process.env.VUE_APP_BASE_API + imgPath;
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.box {
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.active {
  padding: 0 70px;
}
::v-deep .el-avatar > img {
  width: 100%;
  height: 100%;
}
::v-deep .el-table__empty-text {
  color: #fff;
}
::v-deep .el-avatar--circle {
  background-color: #fff;
}
</style>
