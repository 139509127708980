<template>
  <div>
    <el-form>
      <el-form-item
        label="图片资源：">
        <!--<el-input-->
        <!--  :value="currentEditItem.children.props.value"-->
        <!--  @input="(value)=>changeProps('value', value)"-->
        <!--  style="width: 100%"-->
        <!--  type="textarea"-->
        <!--  :rows="4"-->
        <!--&gt;</el-input>-->
        <div @click="chooseMedia()">
          <el-input
            :value="currentEditItem.children.props.mediaName"
            style="width: 100%"
            :rows="4"
          ></el-input>
        </div>


      </el-form-item>
      <el-form-item
        label="模式：">
        <el-radio-group
          v-model="currentEditItem.children.props.objectFit"
          @change="(value)=>changeProps('objectFit', value)"
        >
          <el-radio label="cover">cover</el-radio>
          <el-radio label="none">none</el-radio>
          <el-radio label="contain">contain</el-radio>
          <el-radio label="fill">fill</el-radio>
          <el-radio label="scale-down">scale-down</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <media-resources ref="mediaResources" mediaFileType="1000" @submit="choiceVlaue"></media-resources>
  </div>
</template>

<script>
// 号码类型弹框组件
import mediaResources from "@/views/device/components/media-resources.vue";

export default {
  name: "w-img-config",
  components: {
    mediaResources
  },
  props: {
    currentEditItem: {
      type: Object
    }
  },
  data() {
    return {};
  },
  methods: {
    changeProps(key, value) {
      this.$emit('change', {key,value})
    },
    // 打开媒体资源弹框
    chooseMedia(){
      console.log("打开媒体资源弹框")
      this.$refs.mediaResources.MediaVisible = true;
    },
  //   选择的媒体资源
    choiceVlaue(val){
      console.log("选择的媒体资源",val)
      this.currentEditItem.children.props.mediaName = val.mediaName
      this.changeProps("value",val.url)
    }
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
