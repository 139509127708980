// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/index/赛事路线.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n.box[data-v-2d591bf3] {\n  color: #fff;\n  height: 100%;\n  width: 100%;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 0px 0px;\n  background-size: 100% 100%;\n  position: relative;\n}\n.box .p_title[data-v-2d591bf3] {\n  position: absolute;\n  top: 2%;\n  left: 5%;\n}\n.box .runPath[data-v-2d591bf3] {\n  background: #000;\n  position: absolute;\n  left: 5%;\n  top: 17%;\n  right: 5%;\n  bottom: 5%;\n}", ""]);
// Exports
module.exports = exports;
