import request from '@/utils/request'

// 查询左侧节点
export function getAdvertConfigNode(query) {
  return request({
    url:  '/emd/advertConfig/share/getAdvertConfigNode',
    method: 'get',
    params: query
  })
}

// 查询右侧上传的
export function getAdvertConfigFile(query) {
  return request({
    url: '/emd/advertConfig/share/getAdvertConfigFile',
    method: 'get',
    params: query
  })
}

