import request from '@/utils/request'

// 查询赛事订单列表
export function listMatch(query) {
  return request({
    url: '/order/match/list',
    method: 'get',
    params: query
  })
}
// 导出赛事订单
export function exportMatch(query,orderIds) {

  if (0 === orderIds.length) {
    orderIds = [-1];
  }
  return request({
    url: '/order/match/export/'+orderIds,
    method: 'get',
    params: query
  })
}

