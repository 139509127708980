var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "inline" } },
    [
      _vm._l(_vm.options, function (item, index) {
        return [
          _vm.values.includes(item.value)
            ? [
                _c(
                  "span",
                  {
                    key: item.value,
                    class: item.raw.cssClass,
                    attrs: { index: index },
                  },
                  [_vm._v(_vm._s(item.label))]
                ),
              ]
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }