<template>
  <fullscreen :fullscreen.sync="fullscreen">
    <div class="screen1">
      <el-container>
        <!--头部-->
        <el-header>
          <div class="title">
            <div class="top" @click="toggle()">{{ screenTitle }}</div>
          </div>
          <div
            style="
              text-align: right;
              position: absolute;
              top: 6px;
              right: 3px;
              width: 100%;
            "
          >
            <!--日期时间-->
            <w-clock class="clock"></w-clock>
            <!--天气-->
            <w-weather class="weather"></w-weather>
          </div>
        </el-header>
        <!--主体-->
        <el-main style="padding-top: 43px">
          <!--比赛信息-->
          <el-row :gutter="15">
            <!--左侧内容-->
            <el-col :span="9" class="left">
              <track-image
                heightTrackImage="35.7vh"
                backgroundTrackImage="datascreen/编组@2x.png"
              ></track-image>
              <current-match
                marqueeIndex="2"
                style="margin-top: 2vh"
                heightCurrentMatch="25vh"
              ></current-match>
              <recent-match
                heightRecentMatch="25vh"
                backgroundRecentMatch="datascreen/最近赛事.png"
              ></recent-match>
            </el-col>
            <!--右侧内容-->
            <el-col :span="15" class="center">
              <div class="bigScreen">
                <personal-match
                  @timeTitle="getTitle"
                  @changePage="changePage"
                  v-show="ptitle === '个人赛事'"
                  marqueeIndex="2"
                  heightCurrentMatch="62.5vh"
                ></personal-match>
                <big-screen-view v-show="!ptitle"></big-screen-view>
              </div>
              <el-row :gutter="15">
                <el-col :span="15">
                  <over-game
                    heightOverGame="25vh"
                    heightTbody="70%"
                    backgroundOverGame="datascreen/图层2备份@2x.png"
                  ></over-game>
                </el-col>
                <el-col :span="9">
                  <total-sessions
                    heightTotalSessions="25vh"
                    backgroundTotalSessions="datascreen/图层@2x.png"
                  ></total-sessions>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-main>
      </el-container>
    </div>
  </fullscreen>
</template>

<script>
import { listGetVideoUrl } from "@/api/device/screen-preview/screen-preview";
//时间
import WClock from "./components/clock";
//天气
import WWeather from "./components/weather";

//模块
import TrackImage from "./module/TrackImage";
import OverGame from "./module/OverGame";
import CurrentMatch from "./module/CurrentMatch";
import AdvertEdit from "./module/AdvertEdit";
import RecentMatch from "./module/RecentMatch";
import TotalSessions from "./module/TotalSessions1";
import DataScreen from "./../data-screen/detail";
import BigDataScreen from "@/views/device/components/big-data-screen";
import PersonalMatch from "./module/PersonalMatch";
import BigScreenView from "./components/bigScreenView";

export default {
  name: "index",
  components: {
    TotalSessions,
    WClock,
    WWeather,
    TrackImage,
    OverGame,
    CurrentMatch,
    AdvertEdit,
    RecentMatch,
    DataScreen,
    BigDataScreen,
    PersonalMatch,
    BigScreenView,
  },
  data() {
    return {
      // 赛事排名和个人赛事页面切换
      ptitle: null,
      //全屏预览
      fullscreen: false,
    };
  },
  props: ["screenTitle"],

  created() {},
  destroyed() {
  },

  methods: {
    //刷卡进入个人赛事
    changePage(title) {
      this.ptitle = title;
      // if (this.ptitle == "赛事排名") {
      //   this.ptitle = "个人赛事";
      // }
    },
    //定时
    setTimeData() {
      this.getCurrentMatch();
      this.getRecentMatch();
      this.getDataList();
    },

    //切換回原页面
    getTitle(temp) {
      this.ptitle = null;
    },

    //全屏预览
    toggle() {
      this.fullscreen = !this.fullscreen;
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.screen1 {
  background: linear-gradient(180deg, #011868 0%, #0c1a43 43%, #051d62 100%);
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  color: #ffffff;
  //头部
  .title {
    position: relative;
    /* 限定大小 */
    width: 100%;
    margin-top: 6px;
    height: 8.5vh;
    background: url(../../../assets/images/logo_lc.png) no-repeat 0 0 / contain;
    background-position: center center;
    background-size: 100% 100%;

    .top {
      position: absolute;
      top: 45%;
      transform: translate(0, -50%);
      width: 100%;
      text-align: center;
      font-size: 28px;
      font-weight: bold;
    }
  }

  .p_name {
    background: linear-gradient(to right, #0c84ef, #0a1a48);
    padding: 5px 0 5px 20px;
  }
  .center {
    padding: 0 0 20px 15px;
    position: relative;
    height: 90vh;

    .bigScreen {
      height: 62.5vh;
    }
  }
  .main {
    border: none;
  }

  //background-image:url('@/assets/images/datascreen/图层2备份@2x.png')
  .p_title {
    position: absolute;
    top: 7px;
    left: 10px;
  }

  .clock {
    font-size: 16px;
    display: inline-block;
    text-align: center;
  }
  .weather {
    font-size: 16px;
    margin-left: 10px;
    display: inline-block;
  }

  //改变表格背景颜色
  .el-table,
  .el-table__expanded-cell {
    background-color: transparent;
    color: #a5fffd;
  }
  //去掉最下面的边框
  .el-table::before {
    background-color: transparent;
  }
  .el-table {
    font-size: 16px;
    //表头样式
    .el-table__header-wrapper th,
    .el-table__fixed-header-wrapper th {
      font-size: 16px !important;
      color: #fff;
      font-weight: 500;
    }
    //表头行高
    .el-table__header tr,
    .el-table__header th {
      padding: 5px 0;
      height: 22px;
    }
    //表头背景颜色
    thead {
      background: linear-gradient(to right, rgb(65, 254, 232), rgb(8, 75, 213));
      margin: 10px;
    }
    th,
    tr {
      background-color: transparent;
      height: 30px;
    }
    tbody tr:nth-of-type(odd) {
      background: rgba(23, 114, 186, 0.3);
    }
    //去掉边框
    tr,
    th,
    td {
      border: none;
      overflow: hidden;
    }
    //去掉表头下边框
    th.is-leaf {
      border-bottom: none;
    }
    //鼠标悬浮行背景色
    .el-table__body tr:hover > td {
      background-color: transparent !important;
    }
  }

  .p_title {
    font-size: 20px;
    padding: 5px 20px;
    width: 150px;

    span {
      display: block;
    }
  }

  .marquee {
    font-size: 16px;
    padding-left: 20px;
    width: 100%;
    font-weight: 500;
    .m_row {
      padding: 10px 0px 10px 15px;
      color: #a5fffd;
    }
    .m_row:nth-of-type(odd) {
      background: rgba(23, 114, 186, 0.3);
    }
    .m_row_title {
      background: linear-gradient(to right, #0c84ef, #0a1a48);
      margin-bottom: 7px;
      color: white;
    }
  }
}
</style>
