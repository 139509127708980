var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticStyle: { "margin-bottom": "20px" } },
      [
        _c("el-page-header", {
          attrs: { content: "数据大屏详情" },
          on: {
            back: function ($event) {
              return _vm.$router.back()
            },
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "layout" }, [
      _c(
        "div",
        { staticClass: "left" },
        [
          _c(
            "div",
            { staticClass: "widget-list" },
            _vm._l(_vm.widgetList, function (item) {
              return _c(
                "div",
                {
                  key: item.children.component,
                  staticClass: "w-item",
                  attrs: { draggable: true, unselectable: "on" },
                  on: {
                    drag: function (e) {
                      _vm.drag({ e: e, dragProps: item })
                    },
                    dragend: function (e) {
                      _vm.dragend({ e: e, dragProps: item })
                    },
                  },
                },
                [
                  _c("i", { class: item.icon }),
                  _c("span", { staticClass: "text" }, [
                    _vm._v(_vm._s(item.label)),
                  ]),
                ]
              )
            }),
            0
          ),
          _c("el-divider"),
          _vm.currentEditItem
            ? _c(
                "div",
                { staticClass: "widget-form" },
                [
                  _c("option-config", {
                    attrs: { currentEditItem: _vm.currentEditItem },
                    on: { change: _vm.changeOptions },
                  }),
                  _c(_vm.currentEditItem.children.componentConfig, {
                    tag: "component",
                    attrs: { currentEditItem: _vm.currentEditItem },
                    on: { change: _vm.changeProps },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "center" }, [
        _c("div", { staticClass: "header" }, [
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c(
                "div",
                { staticStyle: { display: "flex", "align-items": "center" } },
                [
                  _c("span", [_vm._v("背景颜色:")]),
                  _c("el-color-picker", {
                    attrs: { "show-alpha": "", predefine: _vm.predefineColors },
                    model: {
                      value: _vm.backgroundColor,
                      callback: function ($$v) {
                        _vm.backgroundColor = $$v
                      },
                      expression: "backgroundColor",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-select",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { clearable: "", placeholder: "请选择边框" },
                  model: {
                    value: _vm.borderBox,
                    callback: function ($$v) {
                      _vm.borderBox = $$v
                    },
                    expression: "borderBox",
                  },
                },
                _vm._l(11, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: {
                      value: "dv-border-box-" + item,
                      label: "边框" + item,
                    },
                  })
                }),
                1
              ),
              _vm.borderBox === "dv-border-box-11"
                ? _c("el-input", {
                    staticStyle: { width: "150px", "margin-left": "10px" },
                    attrs: { placeholder: "请输入标题" },
                    model: {
                      value: _vm.title,
                      callback: function ($$v) {
                        _vm.title = $$v
                      },
                      expression: "title",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.fillScreen("view")
                    },
                  },
                },
                [_vm._v("全屏预览")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.fillScreen("edit")
                    },
                  },
                },
                [_vm._v("全屏编辑")]
              ),
              _c("el-button", { on: { click: _vm.printLayout } }, [
                _vm._v("打印layout"),
              ]),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { ref: "content" },
          [
            _c("big-data-screen", {
              ref: "bigDataScreen",
              attrs: {
                mode: _vm.mode,
                layout: _vm.layout,
                borderBox: _vm.borderBox,
                title: _vm.title,
                backgroundColor: _vm.backgroundColor,
              },
              on: {
                move: _vm.moveEvent,
                moved: _vm.movedEvent,
                select: _vm.selectGridItem,
                remove: _vm.removeItem,
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }