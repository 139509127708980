import request from '@/utils/request'

// 查询用户数量ECharts
export function NumberOfUsersList(query) {
  return request({
    url: '/dataManagement/NumberOfUsers/list',
    method: 'get',
    params: query
  })
}

// 查询用户数量列表
export function numberOfUsers(query) {
  return request({
    url: '/dataManagement/NumberOfUsers/numberOfUsersList',
    method: 'get',
    params: query
  })
}
