import request from "@/utils/request";


// 查询团建赛客户列表
export function listTmCustomer(query) {
  return request({
    url: '/team/match/customer/list',
    method: 'get',
    params: query
  })
}

/**
 * 新增团建赛客户
 * @param data
 * @returns {*}
 */
export function addTeamMatchCustomer(data){
  return request({
    url: '/team/match/customer/add',
    method: 'post',
    data: data
  })
}

/**
 * 查看团建赛客户信息
 * @param userId
 * @returns {*}
 */
export function viewTeamMatchCustomer(userId){
  return request({
    url: '/team/match/customer/'+userId,
    method: 'get',
  })
}

/**
 * 修改团建赛客户信息
 * @param userId
 * @returns {AxiosPromise}
 */
export function updateTeamMatchCustomer(data){
  return request({
    url: '/team/match/customer/edit',
    method: 'put',
    data : data
  })
}

