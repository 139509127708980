import request from '@/utils/request'

//办赛需求列表
export function exportAdvertZip(params) {
  return request({
    url: '/emd/export/exportAdvertZip',
    method: 'get',
    params: params,
    responseType: 'blob'
  })
}
// 删除办赛需求
export function exportProcess(mainId) {
  return request({
    url: '/emd/export/exportProcess/'+mainId,
    method: 'get'
  })
}

export function exportRiteConfigPDF(params) {
  return request({
    url: '/emd/export/exportRiteConfigPDF',
    method: 'get',
    params: params,
    responseType: 'blob'
  })
}
export function exportEmdContractInvoiceConfigPDF(params) {
  return request({
    url: '/emd/export/exportEmdContractInvoiceConfigPDF',
    method: 'get',
    params: params,
    responseType: 'blob'
  })
}

export function exportEmdAllZip(params) {
  return request({
    url: '/emd/export/exportEmdAllZip',
    method: 'get',
    params: params,
    responseType: 'blob'
  })
}

export function exportEmdPlanConfigMatchInfoPDF(params) {
  return request({
    url: '/emd/export/exportEmdPlanConfigMatchInfoPDF',
    method: 'get',
    params: params,
    responseType: 'blob'
  })
}

/**
 * 导出赛事合同附件压缩包
 * @param params
 * @returns {*}
 */
export function exportEmdContractInvoiceConfigZip(params) {
  return request({
    url: '/emd/export/exportEmdContractInvoiceConfigZip',
    method: 'get',
    params: params,
    responseType: 'blob'
  })
}

/**
 * 导出赛事报价方案pdf
 * @param params
 * @returns {*}
 */
export function exportEmdQuoteConfigPDF(params) {
  return request({
    url: '/emd/export/exportEmdQuoteConfigPDF',
    method: 'get',
    params: params,
    responseType: 'blob'
  })
}
