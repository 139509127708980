var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.dataMatchType + " dataMatch",
      style: {
        height: _vm.height,
        backgroundImage: "url(" + _vm.background + ")",
      },
    },
    [
      _c("div", { staticClass: "p_title p_title_down" }, [_vm._v("数据展示")]),
      _c(
        "el-row",
        { staticClass: "elRow" },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("span", [_vm._v(_vm._s(_vm.listData.total))]),
            _c("p", [_vm._v("总赛事场次")]),
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c("span", [_vm._v(_vm._s(_vm.listData.participants))]),
            _c("p", [_vm._v("总参赛人数")]),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "elRow" },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("span", [_vm._v(_vm._s(_vm.listData.finishVisits))]),
            _c("p", [_vm._v("总完赛人次")]),
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c("span", [_vm._v(_vm._s(_vm.listData.enterToday))]),
            _c("p", [_vm._v("今日参赛数")]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }