var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        [
          _c("el-form-item", { attrs: { label: "视频资源：" } }, [
            _c(
              "div",
              {
                on: {
                  click: function ($event) {
                    return _vm.chooseMedia()
                  },
                },
              },
              [
                _c("el-input", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    value: _vm.currentEditItem.children.props.mediaName,
                    rows: 4,
                  },
                  on: {
                    input: function (value) {
                      return _vm.changeProps("value", value)
                    },
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("media-resources", {
        ref: "mediaResources",
        attrs: { mediaFileType: "1001" },
        on: { submit: _vm.choiceVlaue },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }