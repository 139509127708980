import request from "@/utils/request";


// 全屏显示开关
export function switchFullScreen(status, matchId) {
  return request({
    url: "/rc/match/device/switchFullScreen",
    method: "get",
    params: {
      'status': status,
      'matchId': matchId,
    },
  });
}
