import request from '@/utils/request'

// 查询优惠券列表
export function listCoupon(query) {
  return request({
    url: '/operate/coupon/list',
    method: 'get',
    params: query
  })
}

// 查询优惠券详细
export function getCoupon(couponId) {
  return request({
    url: '/operate/coupon/' + couponId,
    method: 'get'
  })
}

// 新增优惠券
export function addCoupon(data) {
  return request({
    url: '/operate/coupon/add',
    method: 'post',
    data: data
  })
}

// 修改优惠券
export function updateCoupon(data) {
  return request({
    url: '/operate/coupon/edit',
    method: 'put',
    data: data
  })
}

// 上下架
export function editStatus(data) {
  return request({
    url: '/operate/coupon/editStatus',
    method: 'put',
    data: data
  })
}

// 补货
export function replenish(data) {
  return request({
    url: '/operate/coupon/replenish',
    method: 'put',
    data: data
  })
}


// 查询兑换码列表
export function listCouponDetail(query) {
  return request({
    url: '/operate/coupon/listDetail',
    method: 'get',
    params: query
  })
}

//导出兑换码列表
export function exportDetail(query) {
  return request({
    url: '/operate/coupon/exportDetail',
    method: 'get',
    params: query
  })
}
// 启用禁用兑换码
export function editDetailStatus(data) {
  return request({
    url: '/operate/coupon/editDetailStatus',
    method: 'put',
    data: data
  })
}
//导出模板
export function codeTemplate(query) {
  return request({
    url: '/operate/coupon/codeTemplate',
    method: 'get',
    params: query
  })
}

// 查询优惠券平台列表
export function listCouponPlatform() {
  return request({
    url: '/operate/coupon/platformList',
    method: 'get',
  })
}

// 同步第三方平台
export function synPlatform(data) {
  return request({
    url: '/operate/coupon/synPlatform',
    method: 'post',
    data: data
  })
}
