<template>
  <div
    :class="{ marquee: true, marquee1: index === 1, marquee2: index === 2 }"
    :style="{ height: height }"
  >
    <div class="p_title" v-show="index === 2">
      <span>赛事排名 </span>
    </div>
    <el-carousel :interval="8000">
      <el-carousel-item v-for="(item, index) in listCurrentMatchs" :key="index">
        <!--赛事标题-->

        <div :class="marqueeIndex == 2 ? 'p_top' : 'p_dataScreen'">
          <span style="display: block">
            {{ item.matchName }}
            （
            <dict-label
              :options="dict.type.match_rule"
              :value="item.matchRule"
            />
            <span v-if="item.lineGroup">
              <span v-if="item.matchRule == 'MBLC' || item.matchRule == 'LJLC'">
                / {{ item.lineGroup }}KM
              </span>
              <span v-else-if="item.matchRule == 'DKCS'">
                / {{ item.lineGroup }}次
              </span>
              <span
                v-else-if="
                  item.matchRule == 'SXGRZHCJ' || item.matchRule == 'DCPDK'
                "
              >
                / {{ item.lineGroup }}KM
              </span>
            </span>
            ）
          </span>
        </div>
        <!--listData1 && listData2-->
        <div v-if="item.listData1 && item.listData2">
          <!--thead-->
          <el-row class="p_name" style="padding: 7px">
            <el-col style="margin-left: 16px" :span="5">名次</el-col>
            <el-col :span="11">{{ item.thead1 }}</el-col>
            <el-col :span="6">{{ item.thead2 }}</el-col>
          </el-row>
          <!--tbody-->
          <el-row>
            <!--序号-->
            <el-col :span="2">
              <el-row
                class="m_row"
                v-show="item.maxLength"
                v-for="(index, item1) of item.maxLength"
                :key="item1"
              >
                <el-col
                  v-if="item1 == 0"
                  :span="16"
                  :offset="8"
                  style="height: 20px; position: relative"
                >
                  <img
                    style="position: absolute; top: -5px; left: -12px"
                    width="35"
                    height="28"
                    src="../../../../assets/images/first.png"
                    alt=""
                  />
                </el-col>
                <el-col
                  v-else-if="item1 == 1"
                  :span="16"
                  :offset="8"
                  style="height: 20px; position: relative"
                >
                  <img
                    style="position: absolute; top: -5px; left: -12px"
                    width="35"
                    height="28"
                    src="../../../../assets/images/twice.png"
                    alt=""
                  />
                </el-col>
                <el-col
                  v-else-if="item1 == 2"
                  :span="16"
                  :offset="8"
                  style="height: 20px; position: relative"
                >
                  <img
                    style="position: absolute; top: -5px; left: -12px"
                    width="35"
                    height="28"
                    src="../../../../assets/images/third.png"
                    alt=""
                  />
                </el-col>
                <el-col v-else :span="16" :offset="8" style="height: 20px">{{
                  item1 + 1
                }}</el-col>
              </el-row>
            </el-col>
            <!--第一列表格-->
            <el-col :span="11">
              <!--              <el-row class="m_row" v-show="item.listData1" v-for="(index, item2) of item.maxLength" :key="item2">-->
              <!--                <el-col :span="16" :offset="8" style="height: 20px"></el-col>-->
              <!--              </el-row>-->
              <match-table :list="item.listData1"></match-table>
            </el-col>
            <!--第二列表格-->
            <el-col :span="11">
              <!--              <el-row class="m_row" v-show="item.listData2" v-for="(index, item3) of item.maxLength" :key="item3">-->
              <!--                <el-col-->
              <!--                  :span="16"-->
              <!--                  :offset="8"-->
              <!--                  style="height: 20px"-->
              <!--                ></el-col>-->
              <!--              </el-row>-->
              <match-table :list="item.listData2"></match-table>
            </el-col>
          </el-row>
        </div>
        <match-table
          v-else-if="!item.listData2"
          :list="item.listData1"
          :matchRule="item.matchRule"
          status="1"
        ></match-table>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
import { listCurrentMatch } from "@/api/device/screen-preview/screen-preview";
import MatchTable from "../components/MatchTable";
export default {
  name: "CurrentMatch",
  dicts: ["match_rule"],
  props: ["heightCurrentMatch", "backgroundCurrentMatch", "marqueeIndex"],
  components: {
    MatchTable,
  },
  computed: {},
  data() {
    return {
      intervalId: null,
      height: this.heightCurrentMatch,
      // background:require('@/assets/images/'+this.backgroundCurrentMatch),
      trackImageUrl: "",
      deviceCode: "",
      time: 60,
      index: null,
      tableData: [],
      listCurrentMatchs: [
        //多人玩法
        // {
        //   "matchId": "1621719537091661826",
        //   "matchName": "多人玩法团队赛",
        //   "lineGroup": null,
        //   "matchRule": "MBLC",
        //   "partType":"DJ",//队际PK 或 DRZH：多人组合
        //   "teamList": [
        //     {
        //       "ranking": "1",
        //       "teamLogo": null,
        //       "teamName": "队伍一",
        //       "score": "00:19:66"
        //     },
        //     {
        //       "ranking": "2",
        //       "teamLogo": null,
        //       "teamName": "队伍一",
        //       "score": "00:19:66"
        //     }
        //   ],
        //   "manList": null,
        //   "woManList": null,
        //   "individualRankingList": [
        //     {
        //       "ranking": "1",
        //       "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83eqlzuSw7EFRv7Ocb8hDtAyicDDAYiaMsJz7OvnjjvichqhRkoiaQkibHYPIC00fQQxuoWfmXMtJp8u6gqg/132",
        //       "playUserName": "一抹暖阳\uD83D\uDCA6",
        //       "score": "00:10:22"
        //     },
        //     {
        //       "ranking": "2",
        //       "avatar": "",
        //       "playUserName": "小宝",
        //       "score": "00:11:12"
        //     },
        //     {
        //       "ranking": "3",
        //       "avatar": "/profile/upload/2023/01/17/274d1c68-d670-4422-8d71-683a0618c2bd.jpg",
        //       "playUserName": "Carolyn11",
        //       "score": "00:11:24"
        //     }
        //   ]
        // },
        // {
        //   "matchId": "1622821256034865154",
        //   "matchName": "多人玩法单人赛",
        //   "lineGroup": null,
        //   "matchRule": "LJLC",
        //   "partType":"DN",//DN：队内PK 或 DRS：单人赛；
        //   "teamList": [],
        //   "manList": null,
        //   "woManList": null,
        //   "individualRankingList": [
        //     {
        //     "ranking": "1",
        //     "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83eqlzuSw7EFRv7Ocb8hDtAyicDDAYiaMsJz7OvnjjvichqhRkoiaQkibHYPIC00fQQxuoWfmXMtJp8u6gqg/132",
        //     "playUserName": "一抹暖阳\uD83D\uDCA6",
        //     "score": "00:10:22"
        //     },
        //     {
        //     "ranking": "2",
        //     "avatar": "",
        //     "playUserName": "小宝",
        //     "score": "00:11:12"
        //   },
        //     {
        //     "ranking": "3",
        //     "avatar": "/profile/upload/2023/01/17/274d1c68-d670-4422-8d71-683a0618c2bd.jpg",
        //     "playUserName": "Carolyn11",
        //     "score": "00:11:24"
        //   }
        //   ]
        // },
        // //单人玩法
        // {//男女不为空
        //   "matchId": "1621719537091661826",
        //   "matchName": "单人玩法男女",
        //   "lineGroup": null,
        //   "matchRule": "SXGRZHCJ",
        //   "woManList": [
        //     {
        //     "ranking": "1",
        //     "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83eqlzuSw7EFRv7Ocb8hDtAyicDDAYiaMsJz7OvnjjvichqhRkoiaQkibHYPIC00fQQxuoWfmXMtJp8u6gqg/132",
        //     "playUserName": "一抹暖阳\uD83D\uDCA6",
        //     "score": "15km"
        //   }, {
        //     "ranking": "2",
        //     "avatar": "/profile/upload/2023/01/17/274d1c68-d670-4422-8d71-683a0618c2bd.jpg",
        //     "playUserName": "Carolyn11",
        //     "score": "10km"
        //   }
        //   ],
        //   "teamList": null,
        //   "individualRankingList": null,
        //   "manList": [
        //     {
        //       "ranking": "1",
        //       "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83eqlzuSw7EFRv7Ocb8hDtAyicDDAYiaMsJz7OvnjjvichqhRkoiaQkibHYPIC00fQQxuoWfmXMtJp8u6gqg/132",
        //       "playUserName": "一抹暖阳\uD83D\uDCA6",
        //       "score": "15km"
        //     }, {
        //       "ranking": "2",
        //       "avatar": "/profile/upload/2023/01/17/274d1c68-d670-4422-8d71-683a0618c2bd.jpg",
        //       "playUserName": "Carolyn11",
        //       "score": "10km"
        //     }, {
        //       "ranking": "2",
        //       "avatar": "",
        //       "playUserName": "小宝",
        //       "score": "10km"
        //     }
        //   ]
        // },
        // {
        //   "matchId": "1621719537091661826",
        //   "matchName": "单人玩法男",
        //   "lineGroup": null,
        //   "matchRule": "SXGRZHCJ",
        //   "woManList": [],
        //   "teamList": null,
        //   "individualRankingList": null,
        //   "manList": [
        //     {
        //       "ranking": "1",
        //       "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83eqlzuSw7EFRv7Ocb8hDtAyicDDAYiaMsJz7OvnjjvichqhRkoiaQkibHYPIC00fQQxuoWfmXMtJp8u6gqg/132",
        //       "playUserName": "一抹暖阳\uD83D\uDCA6",
        //       "score": "15km"
        //     }, {
        //       "ranking": "2",
        //       "avatar": "/profile/upload/2023/01/17/274d1c68-d670-4422-8d71-683a0618c2bd.jpg",
        //       "playUserName": "Carolyn11",
        //       "score": "10km"
        //     }, {
        //       "ranking": "2",
        //       "avatar": "",
        //       "playUserName": "小宝",
        //       "score": "10km"
        //     }
        //   ]
        // },
        // {
        //   "matchId": "1621719537091661826",
        //   "matchName": "单人玩法女",
        //   "lineGroup": null,
        //   "matchRule": "SXGRZHCJ",
        //   "woManList": [
        //     {
        //       "ranking": "1",
        //       "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83eqlzuSw7EFRv7Ocb8hDtAyicDDAYiaMsJz7OvnjjvichqhRkoiaQkibHYPIC00fQQxuoWfmXMtJp8u6gqg/132",
        //       "playUserName": "一抹暖阳\uD83D\uDCA6",
        //       "score": "15km"
        //     }, {
        //       "ranking": "2",
        //       "avatar": "/profile/upload/2023/01/17/274d1c68-d670-4422-8d71-683a0618c2bd.jpg",
        //       "playUserName": "Carolyn11",
        //       "score": "10km"
        //     }, {
        //       "ranking": "2",
        //       "avatar": "",
        //       "playUserName": "小宝",
        //       "score": "10km"
        //     }
        //     ],
        //   "teamList": null,
        //   "individualRankingList": null,
        //   "manList": []
        // },
      ],
    };
  },
  created() {
    // this.getTableData(this.listCurrentMatchs);
    // 如果路由有deviceCode，说明是在平台预览，否则是在大屏设备端展示
    if (this.$route.query.deviceCode) {
      this.deviceCode = this.$route.query.deviceCode;
      this.getCurrentMatch();
    } else {
      this.getDeviceCode();
    }
  },

  watch: {
    marqueeIndex: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue == 1) {
          this.index = 1;
        } else {
          this.index = 2;
        }
      },
    },
  },
  mounted() {
    this.$emitter.on("getMqttData", () => {
      this.getCurrentMatch();
    });
    // 定时每5分钟刷新数据
    // this.intervalId = setInterval(()=>{
    //   this.getCurrentMatch()
    // }, 5 * 60 * 1000)
  },
  methods: {
    // 查询设备编号
    getDeviceCode() {
      this.$bridge.callHandler("searchDeviceCode", "", (data) => {
        var settings = JSON.parse(data);
        this.deviceCode = settings.deviceCode;

        if (this.deviceCode) {
          this.getCurrentMatch();
        }
      });
    },
    sort(x, y) {
      if (x > y || x == y) {
        return x;
      } else {
        return y;
      }
    },
    getTableData(data) {
      data.forEach((element) => {
        //单人玩法
        if (element.matchRule == "SXGRZHCJ" || element.matchRule == "DCPDK" || element.matchRule == "ZNTZS") {
          if (!element.manList?.length) {
            element.listData1 = element.woManList;
          } else if (!element.woManList?.length) {
            element.listData1 = element.manList;
          } else {
            //统一循环数据
            element.listData1 = element.manList;
            element.listData2 = element.woManList;
            element.thead1 = "男子组";
            element.thead2 = "女子组";
            //得出左侧排名
            element.maxLength = this.sort(
              element.manList.length,
              element.woManList.length
            );
          }
        } else {
          //多人玩法
          //单人赛
          if (element.partType == "DN" || element.partType == "DRS") {
            element.listData1 = element.individualRankingList;
          } else if (element.partType == "DJ" || element.partType == "DRZH") {
            //多人赛
            element.maxLength = this.sort(
              element.individualRankingList.length,
              element.teamList.length
            );
            //统一循环数据
            element.listData1 = element.individualRankingList;
            element.listData2 = element.teamList;
            element.thead1 = "个人排名";
            element.thead2 = "团队排名";
            //统一团队赛和个人赛里的字段
            element.listData2.map((item) => {
              item.avatar = item.teamLogo;
              item.playUserName = item.teamName;
            });
          }
        }
      });
      // console.log(data,'data')
    },

    /** 查询当前赛事列表 */
    getCurrentMatch() {
      listCurrentMatch(this.deviceCode).then((response) => {
        this.getTableData(response.data);
        this.listCurrentMatchs = response.data;
      });
    },
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
};
</script>

<style lang="scss" scoped>
.p_name {
  background: linear-gradient(to right, rgb(65, 254, 232), rgb(8, 75, 213));
  padding: 5px 0px;
}
.marquee {
  position: relative;
  padding: 0 1.5vw 2vh 2vw;
  overflow: hidden;
  .personalMatch {
    margin: 20px 40px;
    font-size: 24px;
    color: #a5fffd;
    .data {
      width: 100%;
      background: rgb(247, 188, 188);
      .ones {
        width: 33%;
        text-align: center;
        float: left;
        padding-top: 50px;
        > div:first-child {
          font-size: 40px;
          margin-bottom: 20px;
        }
      }
    }
    .time {
      clear: both;
      padding-top: 100px;
      text-align: center;
      justify-content: right;
    }
  }
}
.marquee1 {
  .p_title {
    z-index: 100;
    position: absolute;
    top: 10px;
    left: 10px;
  }
  ::v-deep .el-carousel__container {
    height: 81vh !important;
    overflow: hidden;
  }
}
.marquee2 {
  background: url(../../../../assets/images/datascreen/图层2备份@2x.png)
    no-repeat 0 0;
  background-size: 100% 100%;
  ::v-deep .el-carousel__container {
    height: 22.5vh !important;
    overflow: hidden;
  }
}

.girlGrop {
  overflow: hidden;
  text-overflow: ellipsis; //多出部分以省略号形式隐藏
  white-space: nowrap; /* 禁止换行  normal可以换行 */
}
.p_top {
  margin: 16px 0;
  margin-left: 200px !important;
  width: 450px;
  text-align: center;
  font-size: 20px;

  color: #0ec0be;
}
.p_dataScreen {
  text-align: center;
  margin: 16px 0;
  color: #0ec0be;
  font-size: 20px;
}

::v-deep .el-carousel__indicators{
  display: none;
}
</style>
