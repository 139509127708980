var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "overGame",
      style: {
        height: _vm.height,
        backgroundImage: "url(" + _vm.background + ")",
      },
    },
    [
      _c("div", { staticClass: "p_title" }, [
        _c("span", [
          _vm._v("恭喜完赛(" + _vm._s(_vm.listComplete.length) + ")"),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "marquee" },
        [
          _c(
            "el-row",
            { staticClass: "title" },
            [
              _c(
                "el-col",
                {
                  staticStyle: { "text-align": "center", width: "42%" },
                  attrs: { span: 10 },
                },
                [_vm._v(" 用户名 ")]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "text-align": "center", width: "16%" },
                  attrs: { span: 4 },
                },
                [_vm._v(" 配速 ")]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "text-align": "center", width: "42%" },
                  attrs: { span: 10 },
                },
                [_vm._v(" 用时 ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tbody", style: { height: _vm.heightTbody } },
            [
              _c(
                "vue-seamless-scroll",
                {
                  ref: "seamlessScrollOver",
                  attrs: {
                    "class-option": _vm.classOption,
                    data: _vm.listComplete,
                  },
                },
                _vm._l(_vm.listComplete, function (item, index) {
                  return _c(
                    "el-row",
                    {
                      key:
                        item.matchId +
                        "_" +
                        item.userId +
                        "_" +
                        item.playRecordId,
                    },
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "scollData",
                          staticStyle: { "text-align": "center", width: "42%" },
                        },
                        [_vm._v(" " + _vm._s(item.userName) + " ")]
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "text-align": "center", width: "16%" },
                        },
                        [_vm._v(" " + _vm._s(item.pace) + " ")]
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "text-align": "center", width: "42%" },
                        },
                        [_vm._v(" " + _vm._s(item.costTime) + " ")]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "marqueeCover" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }