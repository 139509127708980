<template>
  <div class="box">
    <video width="100%" height="100%" autoplay="autoplay" loop="loop" controls :key='value1' muted="muted">
      <source :src="value1">
    </video>
  </div>
</template>

<script>
import {videoConfig} from "@/views/device/widget/video/config";

export default {
  name: "w-video",
  components: {},
  props: {
    value: {
      type: String,
      default: videoConfig.children.props.value
    }
  },
  computed:{
    value1(){
      // 如果是设备进来，就用127的地址
      if(this.$route.query.device == 1){
        return 'https://127.0.0.1:8082' + this.value
      }else {
        return process.env.VUE_APP_BASE_API + this.value
      }

    }
  },
  data() {
    return {

    };
  },
  methods: {

  },
  mounted() {
    console.log("%%%%%%%",this.$route.query.device)
  }
}
</script>

<style lang="scss" scoped>
.box{
  width: 100%;
  overflow: hidden;
  height: 100%;
}
</style>
