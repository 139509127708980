import request from "@/utils/request";

// 查询货物类型库存列表
export function giftListInfo(query) {
  return request({
    url: "/rc/replenish/prize/query",
    method: "get",
    params: query,
  });
}

// 查询奖品类型列表
export function listType(query) {
  return request({
    url: "/rc/prize/PrizeType",
    method: "get",
    params: query,
  });
}

// 查询奖品柜详情列表
export function listPrize(query) {
  return request({
    url: "/rc/prize/list",
    method: "get",
    params: query,
  });
}

// 查看货道
export function listCargo(query) {
  return request({
    url: "/rc/prize/cargoList",
    method: "get",
    params: query,
  });
}

// 查看货道（无分页）
export function listCargoNotPage(query) {
  return request({
    url: "/rc/prize/cargoListNotPage",
    method: "get",
    params: query,
  });
}


// 各商品补货功能
export function postAdd(data) {
  return request({
    url: "/rc/replenish/prize/add",
    method: "POST",
    data: data,
  });
}

//库存清零
export function removeReplenish(data) {
  return request({
    url: "/rc/replenish/prize/remove",
    method: "POST",
    data: data,
  });
}

//修改库存
export function updateStockNum(data) {
  return request({
    url: "/rc/prize/updateStockNum",
    method: "POST",
    data: data,
  });
}

