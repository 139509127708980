import request from '@/utils/request'

//获取版权名称和版权年限
export function getNameAndCopyrightYear() {
  return request({
    url: '/common/getNameAndCopyrightYear',
    method: 'get'
  })
}

//获取参数配置
export function getSysConfig(params) {
  return request({
    url: '/common/getSysConfig/' + params,
    method: 'get',
  })
}

//获取当前登陆人信息
export function getSysUser() {
  return request({
    url: '/common/getSysUser',
    method: 'get',
  })
}
