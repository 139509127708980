import request from "@/utils/request";
import { console } from "vuedraggable/src/util/helper";

// 查询设备信息列表
export function listInfo(query) {
  return request({
    url: "/rc/info/ScreenList",
    method: "get",
    params: query,
  });
}

// 查询设备信息详细
export function getInfo(diId) {
  return request({
    url: "/rc/info/" + diId,
    method: "get",
  });
}

// 删除设备信息
export function delInfo(diId) {
  return request({
    // url: "/rc/info/removeDevAndCW/" + diId,
    url: "/rc/info/removeDevAndMore/" + diId,
    method: "delete",
  });
}

// 导出设备信息
export function exportInfo(dtCode, ids) {
  // if (ids.length === 0) {
  //   ids[0] = -1;
  // }
  return request({
    url: `/rc/info/export/${dtCode}&${ids}`,
    method: "get",
    // params: query,
  });
}

// 查询奖品类型列表
export function listPrizeType(query) {
  return request({
    url: "/rc/PrizeType/list",
    method: "get",
    params: query,
  });
}
// 查询入库货道列表
export function listWay() {
  return request({
    url: "/rc/way/list",
    method: "get",
  });
}

//修改全部货道容量
export function updateCargoSize(query) {
  return request({
    url: "/rc/info/edits",
    method: "put",
    params: query,
  });
}

/** 详情 **/
// 查看奖品柜详情
export function listInfoDetail(query) {
  return request({
    url: "/rc/prize/list",
    method: "get",
    params: query,
  });
}

// 解绑绿道
export function noTrack(query) {
  return request({
    url: "/rc/greenway_device/" + query,
    method: "delete",
  });
}

//修改单条货道容量
export function editsCargoSize(query) {
  return request({
    url: "/rc/way",
    method: "put",
    params: query,
  });
}

//设备上架
export function deviceUpperFrame(diId) {
  return request({
    url: "/rc/info/device/deviceUpperFrame/" + diId,
    method: "get",
  });
}



//设备下架
export function deviceDownFrame(diId) {
  return request({
    url: "/rc/info/device/deviceDownFrame/" + diId,
    method: "get",
  });
}

//完赛柜出货
export function prizeShipment(data){
  return request({
    url: "/rc/prize/device/shipment",
    method: "POST",
    data: data
  });
}


