import request from '@/utils/request'

// 查询赛事建立列表
export function listEstablish(query) {
  return request({
    url: '/match/establish/list',
    method: 'get',
    params: query
  })
}

// 查询分享二维码
export function getEWMByGreenwayByQrName(wxAppletCodeParams) {
  return request({
    url: '/rc/rfid/device/getEWMByGreenwayByQrName',
    method: 'post',
    data: wxAppletCodeParams
  })
}

// 查询赛事建立详细
export function getEstablish(matchId) {
  return request({
    url: '/match/establish/' + matchId,
    method: 'get'
  })
}

// 新增赛事建立
export function addEstablish(matchBase, type) {
  return request({
    url: '/match/establish/add/' + type,
    method: 'post',
    data: matchBase
  })
}

// 修改赛事建立
export function updateEstablish(matchBase, type) {
  return request({
    url: '/match/establish/update/' + type,
    method: 'post',
    data: matchBase
  })
}

// 删除赛事建立
export function delEstablish(matchId) {
  return request({
    url: '/match/establish/delete/' + matchId,
    method: 'post'
  })
}


// 提交申请
export function submitApplication(matchId, enrollFee) {
  return request({
    url: '/match/establish/submitApplication/' + matchId + '/' + enrollFee,
    method: 'post',
  })
}


//取消赛事
export function cancelMatch(matchId) {
  return request({
    url: '/match/establish/cancelMatch/' + matchId,
    method: 'post',
  })
}


// 查询赛事建立列表
export function getGreenwayTrackAll() {
  return request({
    url: '/match/establish/getGreenwayTrackAll',
    method: 'get'
  })
}

//赛事支付cancelMatch
export function matchPay(data) {
  return request({
    url: '/match/establish/matchPay',
    method: 'post',
    data: data,
  })
}

//申请退赛
export function applyQuitMatch(data) {
  return request({
    url: '/match/establish/quitMatch',
    method: 'post',
    data: data
  })
}

//赛事报名人数
export function matchApplyCount(matchId) {
  return request({
    url: '/match/establish/matchApplyCount/' + matchId,
    method: 'get',
  })
}


// 导出赛事建立Excel
export function exportEstablish(ids) {
  if (ids.length === 0) {
    ids[0] = -1
  }

  return request({
    url: '/match/establish/export/' + ids,
    method: 'get',
  })
}


// 导出赛事建立Csv
export function exportEstablishCsv(ids) {
  if (ids.length === 0) {
    ids[0] = -1
  }
  return request({
    url: '/match/establish/exportCsv/' + ids,
    method: 'get',
  })
}

// 判断赛事是否已开赛
// 开赛为true 没开赛为false
export function isMatchBegins(matchStartTime) {
  return new Date().getTime() > new Date(matchStartTime).getTime();
}


// 获取定制服务周期状态
// 正常 stocking; 加急 urgent; 没时间 noTime
export function getServiceStockingState(matchStartTime, matchCustomService) {
  if (!matchStartTime) {
    return 'stocking';
  }

  // 当前时间戳
  const nowTime = new Date().getTime();
  // 赛事开始时间
  const startTime = new Date(matchStartTime).getTime();

  if (nowTime > startTime) {
    return 'noTime';
  }

  // 差值是毫秒 转换成天数
  let differDay = Math.floor((startTime - nowTime) / 86400000);

  // console.log("还差");
  // console.log(differDay);
  // console.log(differDay >= matchCustomService.stockingCycle);
  // console.log(matchCustomService.urgentCycle && differDay >= matchCustomService.urgentCycle);

  // 判断状态
  if (differDay >= matchCustomService.stockingCycle) {
    return 'stocking';
  } else if (matchCustomService.urgentCycle && differDay >= matchCustomService.urgentCycle) {
    return 'urgent';
  } else {
    return 'noTime';
  }
}

//获取小程序码 受限制生成10万次
export function wxAppletCode(wxAppletCodeParams) {
  return request({
    url: '/common/getWXCode',
    method: 'post',
    data: wxAppletCodeParams
  })
}

//获取小程序码 不受限制
export function infinitizeWxAppletCode(wxAppletCodeParams) {
  return request({
    url: '/common/getWXInfinitizeCode',
    method: 'post',
    data: wxAppletCodeParams
  })
}
  // let name=new Date().getTime();
  // let base64 = url.toString()
  // let byteCharacters = atob(
  //   base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, "")
  // )
  // let byteNumbers = new Array(byteCharacters.length)
  // for (let i = 0; i < byteCharacters.length; i++) {
  //   byteNumbers[i] = byteCharacters.charCodeAt(i)
  // }
  // let byteArray = new Uint8Array(byteNumbers)
  // let blob = new Blob([byteArray], {
  //   type: undefined
  // })
  // let aLink = document.createElement("a")
  // aLink.download = `${name}.png` //这里写保存时的图片名称
  // aLink.href = URL.createObjectURL(blob)
  // aLink.click()

//下载小程序码
export function downloadIamge(imgsrc, downloadPreviewText) {

  // let name= downloadPreviewText +  new Date().getTime();
  // const image = new Image();
  // // 解决跨域 Canvas 污染问题
  // image.setAttribute("crossOrigin", "anonymous");
  // image.src = imgsrc;
  // image.onload = () => {
  //   const canvas = document.createElement("canvas");
  //   canvas.width = image.width;
  //   canvas.height = image.height;
  //   const context = canvas.getContext("2d");
  //   context.drawImage(image, 0, 0, image.width, image.height);
  //   canvas.toBlob((blob) => {
  //     const url = URL.createObjectURL(blob);
  //     const a = document.createElement("a");
  //     a.download = name;
  //     a.href = url;
  //     a.click();
  //     a.remove();
  //     URL.revokeObjectURL(url);
  //   });
  // };


  //创建一个a标签
  const a_b = document.createElement('a');
  //设置href指向
  a_b.href = imgsrc;
  //设置图片下载名字
  a_b.download = downloadPreviewText;
  //点击a标签
  a_b.click()

}

// 根据订单id查询对应的赛事信息
export function getMatchByOrderNo(orderNo) {
  return request({
    url: '/match/establish/getMatchByOrderNo/' + orderNo,
    method: 'get',
  })
}

// 修改赛事订单的金额
export function updateOrderMatchByNo(orderNo, enrollFee) {
  return request({
    url: `/order/match/updateOrderMatchByNo/${orderNo}/${enrollFee}`,
    method: 'get',
  })
}

// 校验赛事中的邮寄服务是否存在一个及以上的
export function thereIsMoreThanOneMailService(matchId) {
  return request({
    url: `/match/establish/thereIsMoreThanOneMailService/${matchId}`,
    method: 'get',
  })
}

//赛事建立使用证书图片套打
export function matchPictureSet(data) {
  return request({
    url: `/match/establish/matchPictureSet`,
    data: data,
    method: 'post',
  })
}

//根据赛事id查询对应的服装尺码
export function getClothesSizeByMatchId(matchId) {
  return request({
    url: `/match/size/getClothesSizeByMatchId/${matchId}`,
    method: 'get',
  })
}

//根据赛事id查询对应的鞋子尺码
export function getShoeSizeByMatchId(matchId) {
  return request({
    url: `/match/size/getShoeSizeByMatchId/${matchId}`,
    method: 'get',
  })
}

// 判断是否是慧云管理员
export function getHyAdminFlag() {
  return request({
    url: '/match/establish/getHyAdminFlag',
    method: 'get'
  })
}

//获取月度赛事模板的赛事
export function getMonthModelMatch(type) {
  return request({
    url: '/match/establish/getMonthModelMatch/' + type,
    method: 'get'
  })
}

// 新增渠道二维码
export function getWXCodeAndId(wxAppletCodeParams) {
  return request({
    url: '/wxApi/common/getWXCodeAndId',
    method: 'post',
    data: wxAppletCodeParams
  })
}