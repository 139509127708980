import request from '@/utils/request'

// 代理商统计列表
export function selectList(query) {
  return request({
    url: '/dataManagement/agentStatistics/selectList',
    method: 'get',
    params: query
  })
}

// 奖牌数(赛事购买)详情
export function selectEventPurchaseDetails(query) {
  return request({
    url: '/dataManagement/agentStatistics/selectEventPurchaseDetails',
    method: 'get',
    params: query
  })
}

// 奖牌数详情
export function selectMedalDetails(query) {
  return request({
    url: '/dataManagement/agentStatistics/selectMedalDetails',
    method: 'get',
    params: query
  })
}

// 芯片数详情
export function selectChipDetails(query) {
  return request({
    url: '/dataManagement/agentStatistics/selectChipDetails',
    method: 'get',
    params: query
  })
}
