import request from "@/utils/request";

// 查询媒体资源列表
export function listMedia(query) {
  return request({
    url: "/rc/media/list",
    method: "get",
    params: query,
  });
}

// 查询媒体资源详细
export function getMedia(mediaId) {
  return request({
    url: "/rc/media/" + mediaId,
    method: "get",
  });
}

// 新增媒体资源
export function addMedia(data) {
  return request({
    url: "/rc/media",
    method: "post",
    data: data,
  });
}

// 修改媒体资源
export function updateMedia(data) {
  return request({
    url: "/rc/media",
    method: "put",
    data: data,
  });
}

// 删除媒体资源
export function delMedia(mediaIds) {
  return request({
    url: "/rc/media/" + mediaIds,
    method: "delete",
  });
}

// 导出媒体资源
export function exportMedia(ids) {
  if (ids.length === 0) {
    ids[0] = -1;
  }
  return request({
    url: "/rc/media/export/" + ids,
    method: "get",
  });
}
// 查询播放列（节目单）列表
export function getPlaylist(query) {
  return request({
    url: "/rc/playBill/mediaNum",
    method: "get",
    params: query,
  });
}

// 审核
export function auditPlaylist(mediaId, auditStatus) {
  return request({
    url: `/rc/media/examine/${mediaId},${auditStatus}`,
    method: "put",
  });
}
//导出媒体广告到Excel
export function exportMediaExcel(ids) {
  if (0 === ids.length) {
    ids = [-1];
  }
  return request({
    url: '/rc/media/exportMediaExcel/' + ids,
    method: 'get',
  })
}
//导出媒体广告到Excel
export function exportMediaCsv(ids) {
  if (0 === ids.length) {
    ids = [-1];
  }
  return request({
    url: '/rc/media/exportMediaCsv/' + ids,
    method: 'get',
  })
}

