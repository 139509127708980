import WVideo from "@/views/device/widget/video/w-video";
import WVideoConfig from "@/views/device/widget/video/w-video-config";

export const videoConfig = {
  "x": null,
  "y": null,
  "w": 20,
  "h": 20,
  "i": null,
  preserveAspectRatio: true,
  label: '视频',
  icon: 'el-icon-video-camera-solid',
  children: {
    component: 'w-video', // WVideo.name,
    componentConfig: 'w-video-config', // WVideoConfig.name,
    props: {
      value: 'https://gossv.cfp.cn/videos/mts_videos/medium/VCG42N1413379379.mp4',
      mediaName:'',
    },
  },
  selected: false
}
