import request from '@/utils/request'

// 查询赛事建立列表
export function listAuditList(query) {
  return request({
    url: '/match/audit/list',
    method: 'get',
    params: query
  })
}

// 查询赛事列表
export function matchList(query) {
  return request({
    url: '/match/audit/matchList',
    method: 'get',
    params: query
  })
}

//查询赛事定制服务
export function serviceList(query) {
  return request({
    url: '/match/audit/serviceList',
    method: 'get',
    params: query,
  })
}

//赛事定制服务备货
export function updateServiceStocking(ids, flag) {
  return request({
    url: '/match/audit/editStocking/' + ids + '/' + flag,
    method: 'get',
  })
}

//赛事审核退赛审核
export function updateMatchServiceOrderStatus(matchId, orderStatus) {
  return request({
    url: '/match/audit/editOrderStatus/' + matchId + "/" + orderStatus,
    method: 'get',
  })
}

//强制退赛
export function forceQuitMatch(matchId,templateCode) {
  return request({
    url: `/match/audit/forceQuitMatch/${matchId}/${templateCode}`,
    method: 'get'
  })
}


//查看报名情况
export function lookApply(matchId, pageNum, pageSize) {
  return request({
    url: '/match/audit/findTeamApply',
    method: 'get',
    params: {
      matchId: matchId,
      "pageNum": pageNum,
      "pageSize": pageSize,
    }
  })
}


export function listApplyDetails(query) {
  return request({
    url: '/match/audit/listApplyDetails/',
    method: 'get',
    params:query
  })
}

export function organizer(data) {
  return request({
    url: '/match/audit/setOrganizer',
    method: 'post',
    data:data
  })
}

//导出excel
export function exportAuditExcel(ids) {
  if (ids.length == 0) {
    ids[0] = -1
  }
  return request({
    url: '/match/audit/exportAuditExcel/' + ids,
    method: 'get',
  })
}

//导出Csv
export function exportAuditCsv(ids) {
  if (ids.length == 0) {
    ids[0] = -1
  }
  return request({
    url: '/match/audit/exportAuditCsv/' + ids,
    method: 'get',
  })
}

//查看支付明细
export function auditPayDetail(matchId) {
  return request({
    url: '/match/audit/playDetail/' + matchId,
    method: 'get',
  })
}

//已报名用户
export function matchApplyUser(query) {
  return request({
    url: '/match/audit/matchApplyUser',
    method: 'POST',
    params: {
      pageNum: query.pageNum,
      pageSize: query.pageSize,
    },
    data: query,
  })
}

//定制服务邮寄
export function customizedServiceByMail(query) {
  return request({
    url: '/match/audit/customizedServiceByMail',
    method: 'get',
    params: query
  })
}

//定制服务收件人选择
export function recipient(query) {
  return request({
    url: '/match/audit/recipient',
    method: 'get',
    params: query
  })
}

//定制服务邮寄成功
export function customizedServiceByMailSuccess(query) {
  return request({
    url: '/match/audit/customizedServiceByMailSuccess',
    method: 'get',
    params: query
  })
}

//查看邮寄
export function toViewMail(query) {
  return request({
    url: '/match/audit/toViewMail',
    method: 'get',
    params: query
  })
}


// 查询芯片记录列表
export function ProductionList(query) {
  return request({
    url: '/match/audit/productionList',
    method: 'get',
    params: query
  })
}

//绑定芯片
export function bindingChip(params) {
  console.log(params)
  return request({
    url: '/match/audit/bindingChip',
    method: 'post',
    data: params
  })
}

//取消绑定芯片
export function cancelBindingChip(params) {
  return request({
    url: '/match/audit/cancelBindingChip',
    method: 'post',
    data: params
  })
}

//随机分配芯片
export function randomAllot(params) {
  return request({
    url: '/match/audit/randomAllot',
    method: 'post',
    data: params
  })
}

//导出用户绑定芯片关系
export function exportUserBindChip(params) {
  if (params.userIdList.length == 0) {
    params.userIdList[0] = -1
  }
  return request({
    url: '/match/audit/exportUserBindChipExcel',
    method: 'post',
    data: params,
  })
}
//下载设置芯片号码导入模板
export function  handleImportTemplate() {
  return request({
    url: '/match/audit/handleImportTemplate',
    method: 'get',
  })
}

//下载绑定EPC编号导入模板
export function  handleChipEpcImportTemplate() {
  return request({
    url: '/match/audit/handleChipEpcImportTemplate',
    method: 'get',
  })
}

//热门赛事置顶
export function tournamentTop(params) {
  return request({
    url: '/match/audit/tournamentTop',
    method: 'post',
    data: params
  })
}

//大屏置顶
export function screenTop(params) {
  return request({
    url: '/match/audit/screenTop',
    method: 'post',
    data: params
  })
}

//取消热门赛事置顶
export function cancelTournamentTop(params) {
  return request({
    url: '/match/audit/cancelTournamentTop',
    method: 'post',
    data: params
  })
}

//取消大屏置顶
export function cancelScreenTop(params) {
  return request({
    url: '/match/audit/cancelScreenTop',
    method: 'post',
    data: params
  })
}

//赛事记录（有队伍）
export function haveATeamList(params) {
  return request({
    url: '/match/record/haveATeamList',
    method: 'get',
    params: params
  })
}

//赛事记录（无队伍）
export function haveANoTeamList(params) {
  return request({
    url: '/match/record/haveANoTeamList',
    method: 'get',
    params: params
  })
}
//赛事记录（有队伍）导出
export function exportHaveATeamList(params) {
  return request({
    url: '/match/record/export',
    method: 'get',
    params: params
  })
}

//赛事记录（无队伍）导出
export function exportNoTeam(params) {
  return request({
    url: '/match/record/exportNoTeam',
    method: 'get',
    params: params
  })
}

//赛事记录详情（有队伍）
export function haveATeamDetails(params) {
  return request({
    url: '/match/record/haveATeamDetails',
    method: 'get',
    params: params
  })
}

//导出赛事记录详情（有队伍）
export function exportHaveATeamDetails(params) {
  return request({
    url: '/match/record/exportHaveATeamDetails',
    method: 'get',
    params: params
  })
}

//赛事记录详情（无队伍）
export function haveANoTeamDetails(params) {
  return request({
    url: '/match/record/haveANoTeamDetails',
    method: 'get',
    params: params
  })
}

//导出赛事记录详情（无队伍）
export function exportHaveANoTeamDetails(params) {
  return request({
    url: '/match/record/exportHaveANoTeamDetails',
    method: 'get',
    params: params
  })
}

//打卡记录
export function clockingRecordList(params) {
  return request({
    url: '/match/record/clockingRecordList',
    method: 'get',
    params: params
  })
}

//补卡
export function replacementCard(params) {
  return request({
    url: '/match/record/replacementCard',
    method: 'get',
    params: params
  })
}

//补卡中的补录打卡点
export function getGreenwayCheck(params) {
  return request({
    url: '/match/record/greenwayCheck',
    method: 'get',
    params: params
  })
}

//删除打卡点
export function deleteCheckInfo(params) {
  return request({
    url: '/match/record/deleteCheckInfo',
    method: 'post',
    params: params
  })
}

//同步补卡
export function synchronousFillingPort(params) {
  return request({
    url: '/match/record/synchronousFilling',
    method: 'get',
    params: params
  })
}

//可以补卡的数据
export function replaceableCard(params) {
  return request({
    url: '/match/record/replaceableCard',
    method: 'get',
    params: params
  })
}

//修改打卡时间
export function changeTheClockTime(params) {
  return request({
    url: '/match/record/changeTheClockTime',
    method: 'get',
    params: params
  })
}

//短信通知
export function middleSignInform(query) {
  return request({
    url: '/match/audit/smsNotification',
    method: 'post',
    data: query
  })
}

/**
 * 该赛事用户通知列表
 * @param query
 * @returns {*}
 */
export function matchApplyMessageUser(query){
  return request({
    url: '/match/audit/matchApplyMessageUser',
    method: 'post',
    params: {
      pageNum: query.pageNum,
      pageSize: query.pageSize,
    },
    data: query,
  })
}


//截至尾款期限
export function balancePaymentDeadline(row) {
  if (row.matchClassify === 'INSIDE' && row.matchChargeType === 'SF' && row.applyStatus != 'YQX') {
    // //队员付 ，未付尾款
    // if(row.payMethod === 'DYF'){
    //   return row.costPayed ? false : true
    // }
    // //办赛方付 ，报名人数小于队伍人数
    // if(row.payMethod === 'BSF'){
    //   return row.applyNumber < row.teamPeoples ? true : false
    // }
    if (
      ((row.payStatus === 'DZFDJ' || row.payStatus === 'DZFFY') || row.stockNumber != null || (row.payMethod === 'DYF' && row.applyNumber < row.teamPeoples))) {
      return true;
    }
  }
  return false
}

//计算尾款剩余天数
export function balancePaymentDay(row) {
  var date1 = row.matchStartTime.replace(/-/g, "/");  //赛事比赛时间
  var date2 = new Date();    //当前时间
  var date3 = new Date(date1).getTime() - date2.getTime();   //时间差的毫秒数

  //计算出相差天数
  let day = Math.floor(date3 / (24 * 3600 * 1000))

  return day < 0 ? '0天【已开赛】' : day + '天'
}

//状态 鼠标放上去显示更改时间
export function titleTime(row, flag) {
  if (flag === 'match') {
    return row.matchUpdateTime ? row.matchUpdateTime : matchCreateTime
  }
  if (flag === 'order') {
    return row.orderUpdateTime ? row.orderUpdateTime : row.orderCreateTime
  }
}

// 判断是否是慧云管理员
export function getHyAdminFlag() {
  return request({
    url: '/match/establish/getHyAdminFlag',
    method: 'get'
  })
}

// 失效免费芯片
export function failureOrNotChip(orderIdList) {
  return request({
    url: '/match/audit/failureOrNotChip/' + orderIdList,
    method: 'get'
  })
}


//下载导入跑者模板
export function  downImportUser() {
  return request({
    url: '/match/audit/downImportUser',
    method: 'get',
  })
}

//待调剂队伍list
export function teamToBeTransferredList(params) {
  return request({
    url: '/match/audit/teamToBeTransferredList',
    method: 'post',
    params: params
  })
}

//手动调剂列表
export function manualDispensingList(params) {
  return request({
    url: '/match/audit/manualDispensingList',
    method: 'post',
    params: params
  })
}
//手动调剂非列表
export function transfer(params) {
  return request({
    url: '/match/audit/transfer',
    method: 'post',
    params: params
  })
}

//手动确认按钮
export function confirmButton(params) {
  return request({
    url: '/match/audit/confirmButton',
    method: 'post',
    data: params
  })
}

//自动调剂
export function automaticDispensingList(params) {
  return request({
    url: '/match/audit/automaticDispensingList',
    method: 'post',
    data: params
  })
}

//自动调剂确认
export function automaticDispensingListConfirmButton(params) {
  return request({
    url: '/match/audit/automaticDispensingListConfirmButton',
    method: 'post',
    data: params
  })
}

// 保存赛事排行顺序
export function updateSortViewNumByMatchId(matchId, sortViewNum) {
  return request({
    url: '/match/audit/updateSortViewNumByMatchId?matchId=' + matchId + "&sortViewNum=" + sortViewNum,
    method: 'get'
  })
}

//设为调剂失败
export function setToDispensingFailure(params) {
  return request({
    url: '/match/audit/setToDispensingFailure',
    method: 'get',
    params: params
  })
}

//下载批次导入模板
export function exportBatchTemplate() {
  return request({
    url: '/match/audit/exportBatchTemplate',
    method: 'get',
  })
}
