import request from '@/utils/request'

// 查询绿道自媒体管理列表
export function listMedia(query) {
  return request({
    url: '/greenway/media/list',
    method: 'get',
    params: query
  })
}

// 查询绿道自媒体管理详细
export function getMedia(mediaId) {
  return request({
    url: '/greenway/media/' + mediaId,
    method: 'get'
  })
}

// 新增绿道自媒体管理
export function addMedia(data) {
  return request({
    url: '/greenway/media',
    method: 'post',
    data: data
  })
}

// 修改绿道自媒体管理
export function updateMedia(data) {
  return request({
    url: '/greenway/media/edit',
    method: 'post',
    data: data
  })
}

// 删除绿道自媒体管理
export function delMedia(mediaId) {
  return request({
    url: '/greenway/media/del/' + mediaId,
    method: 'post'
  })
}

// 导出绿道自媒体管理
export function exportMedia(query) {
  return request({
    url: '/greenway/media/export',
    method: 'get',
    params: query
  })
}
