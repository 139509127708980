import { render, staticRenderFns } from "./RecentMatch1.vue?vue&type=template&id=1349cdb1&scoped=true&"
import script from "./RecentMatch1.vue?vue&type=script&lang=js&"
export * from "./RecentMatch1.vue?vue&type=script&lang=js&"
import style0 from "./RecentMatch1.vue?vue&type=style&index=0&id=1349cdb1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1349cdb1",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/echo/zuwen/hyzp_vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1349cdb1')) {
      api.createRecord('1349cdb1', component.options)
    } else {
      api.reload('1349cdb1', component.options)
    }
    module.hot.accept("./RecentMatch1.vue?vue&type=template&id=1349cdb1&scoped=true&", function () {
      api.rerender('1349cdb1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/device/screen-preview/module/RecentMatch1.vue"
export default component.exports