import request from '@/utils/request'

// 查询当前赛事所有跑者参赛记录
export function listCompetitionSettingRecord(params) {
  return request({
    url: '/match/competitionSetting/list?pageNum='+params.pageNum+'&pageSize='+params.pageSize,
    method: 'POST',
    data: params
  })
}

// 查询当前赛事所有跑者参赛记录
export function listByMatchIdSelectGreenway(matchId) {
  return request({
    url: '/match/competitionSetting/listByMatchIdSelectGreenway/' + matchId,
    method: 'GET',
  })
}

/**
 * 统一参赛设置
 */
export function unifyCompetitionSetting(data) {
  return request({
    url: '/match/competitionSetting/unifyCompetitionSetting',
    method: 'POST',
    data: data
  })
}

/**
 * 统一参赛设置
 */
export function resetEntryTime(data) {
  return request({
    url: '/match/competitionSetting/resetEntryTime',
    method: 'POST',
    data: data
  })
}

/**
 * 统一参赛设置
 */
export function delPlayMatchPersonalInfoAndCheckPoint(playIds) {
  return request({
    url: '/match/competitionSetting/delPlayMatchPersonalInfoAndCheckPoint/'+ playIds,
    method: 'GET',
    params: playIds
  })
}

/**
 * 批次赋值列表
 */
export function listOrderMatchEnrollBatch(params) {
  return request({
    url: '/match/competitionSetting/listOrderMatchEnrollBatch',
    method: 'GET',
    params
  })
}

/**
 * 批次赋值-用户列表
 */
export function listOrderMatchEnrollByBatch(params) {
  return request({
    url: '/match/competitionSetting/listOrderMatchEnrollByBatch',
    method: 'GET',
    params
  })
}