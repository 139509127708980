<template>
  <div class="app-container">
    <div style="margin-bottom: 20px;">
      <el-page-header @back="$router.back()" content="数据大屏详情">
      </el-page-header>
    </div>
  <div class="layout">
    <div class="left">
      <div class="widget-list">
        <div
          v-for="item in widgetList"
          :key="item.children.component"
          @drag="(e)=>{drag({e,dragProps: item})}"
          @dragend="(e)=>{dragend({e,dragProps: item})}"
          :draggable="true"
          unselectable="on"
          class="w-item"
        >
          <i :class="item.icon"></i>
          <span class="text">{{ item.label }}</span>
        </div>
      </div>

      <el-divider></el-divider>

      <div
        v-if="currentEditItem"
        class="widget-form"
      >
        <!--    小组件whxy配置    -->
        <option-config
          :currentEditItem="currentEditItem"
          @change="changeOptions">
        </option-config>
        <!--    小组件属性配置    -->
        <component
          :is="currentEditItem.children.componentConfig"
          :currentEditItem="currentEditItem"
          @change="changeProps"
        ></component>
      </div>
    </div>
    <div class="center">
      <div class="header">
        <div style="display: flex;">
          <div style="display: flex;align-items: center;">
            <span>背景颜色:</span>
            <el-color-picker
              v-model="backgroundColor"
              show-alpha
              :predefine="predefineColors">
            </el-color-picker>
          </div>

          <el-select
            v-model="borderBox"
            clearable
            style="margin-left:10px;"
            placeholder="请选择边框"
          >
            <el-option
              v-for="item in 11"
              :key="item"
              :value="`dv-border-box-${item}`" :label="`边框${item}`"
            ></el-option>
          </el-select>
          <el-input
            v-if="borderBox === 'dv-border-box-11'"
            v-model="title"
            style="width: 150px;margin-left: 10px;"
            placeholder="请输入标题"
          ></el-input>
        </div>
        <div>
          <el-button @click="fillScreen('view')">全屏预览</el-button>
          <el-button @click="fillScreen('edit')">全屏编辑</el-button>
          <el-button @click="printLayout">打印layout</el-button>
        </div>
      </div>
      <div ref="content">
        <big-data-screen
          ref="bigDataScreen"
          :mode="mode"
          :layout="layout"
          :borderBox="borderBox"
          :title="title"
          :backgroundColor="backgroundColor"
          @move="moveEvent"
          @moved="movedEvent"
          @select="selectGridItem"
          @remove="removeItem"
        ></big-data-screen>
      </div>
    </div>
  </div>

  </div>
</template>

<script>
import {nanoid} from 'nanoid'

import VueGridLayout from 'vue-grid-layout';
import WImg from "@/views/device/widget/img/w-img";
import WVideo from "@/views/device/widget/video/w-video";
import WText from "@/views/device/widget/text/w-text";
import WClock from "@/views/device/widget/clock/w-clock";
import WTextConfig from "@/views/device/widget/text/w-text-config";
import WImgConfig from "@/views/device/widget/img/w-img-config";
import WVideoConfig from "@/views/device/widget/video/w-video-config";
import WClockConfig from "@/views/device/widget/clock/w-clock-config";
import OptionConfig from "@/views/device/widget/optionConfig";
import BigDataScreen from "@/views/device/components/big-data-screen";
import widgetConfig from "@/views/device/widget/config"

import WWeather from "@/views/device/widget/weather/w-weather";
import WWeatherConfig  from "@/views/device/widget/weather/w-weather-config";

let mouseXY = {"x": null, "y": null};
// let DragPos = {"x": null, "y": null, "w": 1, "h": 1, "i": null};
export default {
  name: "index",
  components: {
    BigDataScreen,
    OptionConfig,
    WClockConfig,
    WVideoConfig,
    WImgConfig,
    WTextConfig,
    WClock,
    WText,
    WVideo,
    WImg,

    WWeather,
    WWeatherConfig,

    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem
  },
  props: {},
  data() {
    return {
      selectI: null, // 当前选中编辑块的唯一 i 值
      isClick: true,
      isFull: false,
      widgetList: widgetConfig,
      title: '',
      backgroundColor: '#282c34',
      predefineColors: [
        '#282c34',
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        'rgba(255, 69, 0, 0.68)',
        'rgb(255, 120, 0)',
        'hsv(51, 100, 98)',
        'hsva(120, 40, 94, 0.5)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
      ],
      borderBox: 'dv-border-box-1',
      mode: 'edit',
      layout: [
        {
          "x": 0,
          "y": 0,
          "w": 10,
          "h": 10,
          "i": nanoid(),
          label: '图片',
          icon: 'el-icon-picture',
          children: {
            component: WImg.name,
            componentConfig: WImgConfig.name,
            props: {
              value: 'https://mmbiz.qpic.cn/mmbiz_jpg/n0xNG3zuy36YeUkGBVzCcWB6ibNulz9Q47KicSPzNWhTUXwpE0BicPfckuTc1x0zY6UCXVib9DGY2Cbngl2skVxq2A/640?wx_fmt=jpeg&wxfrom=5&wx_lazy=1&wx_co=1',
              objectFit: 'cover'
            }
          },
          selected: false
        },
        {
          "x": 10,
          "y": 10,
          "w": 10,
          "h": 10,
          "i": nanoid(),
          label: '文字',
          icon: 'el-icon-tickets',
          children: {
            component: WText.name,
            componentConfig: WTextConfig.name,
            props: {
              value: '文字',
              fontSize: 14,
              color: '#ffffff',
              textAlign: 'left',
            },
          },
          selected: false
        }
      ],
    };
  },
  computed: {
    currentEditItem() {
      return this.layout.find(item => item.i === this.selectI)
    }
  },
  methods: {
    changeProps({key, value}) {
      this.layout = this.layout.map(item => {
        if (item.i === this.selectI) {
          return {
            ...item,
            children: {
              ...item.children,
              props: {
                ...item.children.props,
                [key]: value
              }
            }
          }
        } else {
          return {
            ...item
          }
        }
      })
    },
    changeOptions({key, value}) {
      this.layout = this.layout.map(item => {
        if (item.i === this.selectI) {
          return {
            ...item,
            [key]: value
          }
        } else {
          return {
            ...item
          }
        }
      })
    },
    printLayout() {
      console.log(this.layout)
    },
    moveEvent(i, newX, newY) {
      this.isClick = false
    },
    movedEvent(i, newX, newY) {
      // 解决点击事件和拖动事件冲突问题
      setTimeout(() => {
        this.isClick = true
      }, 300)
    },
    drag: function ({e, dragProps}) {
      let parentRect = this.$refs.content.getBoundingClientRect();
      let mouseInGrid = false;
      if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right)) && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
        mouseInGrid = true;
      }
      if (mouseInGrid === true && (this.layout.findIndex(item => item.i === 'drop')) === -1) {
        this.layout.push({
          x: (this.layout.length * 2) % (this.colNum || 12),
          y: this.layout.length + (this.colNum || 12), // puts it at the bottom
          w: dragProps.w,
          h: dragProps.h,
          i: 'drop',
        });
      }
      let index = this.layout.findIndex(item => item.i === 'drop');
      if (index !== -1) {
        try {
          this.$refs.bigDataScreen.$refs.gridlayout.$children[this.layout.length].$refs.item.style.display = "none";
        } catch {
        }
        let el = this.$refs.bigDataScreen.$refs.gridlayout.$children[index];
        el.dragging = {"top": mouseXY.y - parentRect.top, "left": mouseXY.x - parentRect.left};
        let new_pos = el.calcXY(mouseXY.y - parentRect.top, mouseXY.x - parentRect.left);
        if (mouseInGrid === true) {
          this.$refs.bigDataScreen.$refs.gridlayout.dragEvent('dragstart', 'drop', new_pos.x, new_pos.y, dragProps.h, dragProps.w);
          dragProps.i = nanoid();
          dragProps.x = this.layout[index].x;
          dragProps.y = this.layout[index].y;
        }
        if (mouseInGrid === false) {
          this.$refs.bigDataScreen.$refs.gridlayout.dragEvent('dragend', 'drop', new_pos.x, new_pos.y, dragProps.h, dragProps.w);
          this.layout = this.layout.filter(obj => obj.i !== 'drop');
        }
      }
    },
    dragend: function ({e, dragProps}) {
      let parentRect = this.$refs.content.getBoundingClientRect();
      let mouseInGrid = false;
      if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right)) && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
        mouseInGrid = true;
      }
      if (mouseInGrid === true) {
        // alert(`Dropped element props:\n${JSON.stringify(DragPos, ['x', 'y', 'w', 'h'], 2)}`);
        this.$refs.bigDataScreen.$refs.gridlayout.dragEvent('dragend', 'drop', dragProps.x, dragProps.y, dragProps.h, dragProps.w);
        this.layout = this.layout.filter(obj => obj.i !== 'drop');
        // UNCOMMENT below if you want to add a grid-item
        this.layout.push({
          ...dragProps
        });
      }
    },
    removeItem: function (val) {
      const index = this.layout.map(item => item.i).indexOf(val);
      this.layout.splice(index, 1);
    },
    fillScreen(mode) {
      this.mode = mode
      let elem = this.$refs.content;  //获取到需要大屏展示的元素
      console.log(elem);
      this.requestFullScreen(elem);
    },
    requestFullScreen(element) {
      // console.log('全屏')
      this.isFull = true
      let requestMethod = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullScreen;
      if (requestMethod) {
        requestMethod.call(element);
      } else if (typeof window.ActiveXObject !== "undefined") {
        // for IE，这里其实就是模拟了按下键盘的F11，使浏览器全屏
        let wscript = new ActiveXObject("WScript.Shell");
        if (wscript !== null) {
          wscript.SendKeys("{F11}");
        }
      }
    },
    exitScreen(element) {
      console.log('退出全屏')
      this.isFull = false
      let requestMethod = element.cancelFullScreen || element.webkitCancelFullScreen || element.mozCancelFullScreen || element.exitFullScreen;
      if (requestMethod) {
        requestMethod.call(element);
      } else if (typeof window.ActiveXObject !== "undefined") {
        //for IE，这里和fullScreen相同，模拟按下F11键退出全屏
        let wscript = new ActiveXObject("WScript.Shell");
        if (wscript != null) {
          wscript.SendKeys("{F11}");
        }
      }
    },
    selectGridItem(i) {
      if (!this.isClick) {
        return
      }

      this.layout = this.layout.map((item) => {
        if (item.i === i) {
          return {
            ...item,
            selected: !item.selected
          }
        } else {
          return {
            ...item,
            selected: false
          }
        }
      })
      this.selectI = this.layout.find(item => item.selected)?.i
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.isFull = !!(document.webkitIsFullScreen || document.mozFullScreen ||
        document.msFullscreenElement || document.fullscreenElement
      );
      if(!this.isFull){
        this.mode = 'edit'
      }
    }, false)
    document.addEventListener("dragover", function (e) {
      mouseXY.x = e.clientX;
      mouseXY.y = e.clientY;
    }, false);
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
.layout {
  display: flex;
  height: 100vh;

  .left {
    width: 300px;
    margin: 10px;
    padding: 15px 10px;
    background: #fff;
    box-shadow: 0 0 4px 0 rgba(187, 187, 187, 0.6);

    .widget-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-row-gap: 10px;
      grid-column-gap: 10px;

      .w-item {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        background: rgb(229, 229, 229);
        border-radius: 4px;
        padding: 10px;

        .text {
          font-size: 14px;
          color: rgb(102, 102, 102);
          margin-top: 10px;
        }
      }
    }

    .widget-form {
      margin-top: 20px;
    }

  }

  .center {
    flex-grow: 1;

    .header {
      margin: 10px 0;
      padding: 15px 10px;
      background: #fff;
      box-shadow: 0 0 4px 0 rgba(187, 187, 187, 0.6);
      display: flex;
      justify-content: space-between;
    }
  }


  .right {
    width: 350px;
    margin: 10px;
    padding: 15px 10px;
    background: #fff;
    box-shadow: 0 0 4px 0 rgba(187, 187, 187, 0.6);
    position: relative;

    .close-icon {
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 10px
    }
  }

}
</style>
