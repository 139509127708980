import request from '@/utils/request'

// 查询运营奖牌管理列表
export function listMedal(query) {
  return request({
    url: '/operate/goodMedal/listgoodMedal',
    method: 'get',
    params: query
  })
}

// 查询运营奖牌管理详细
export function getMedal(medalId) {
  return request({
    url: '/operate/goodMedal/' + medalId,
    method: 'get'
  })
}

// 新增运营奖牌管理
export function addMedal(data) {
  return request({
    url: '/operate/goodMedal/add',
    method: 'post',
    data: data
  })
}
// 修改运营赛事奖章
export function updateMedal(data) {
  return request({
    url: '/operate/goodMedal/update',
    method: 'post',
    data: data
  })
}



// 删除奖牌
export function delMedal(medalId) {
  return request({
    url: '/operate/goodMedal/delgoodMedal/' + medalId,
    method: 'post'
  })
}

