import request from '@/utils/request'

//赛事总览数据集合
export function listMatchOverview(data) {
  return request({
    url: '/match/overview/listMatchOverview?' + 'pageNum=' +data.pageNum + '&pageSize=' + data.pageSize,
    method: 'POST',
    data: data
  })
}

//赛事总览数据集合
export function exportMatchOverview(data) {
  return request({
    url: '/match/overview/exportMatchOverview',
    method: 'POST',
    data: data
  })
}

