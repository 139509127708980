import request from '@/utils/request'

//查询运营赛事意见反馈回复列表
export function listFeedbackReply(query) {
  return request({
    url: '/operate/feedback/reply/list',
    method: 'get',
    params: query
  })
}

// 查询运营赛事意见反馈列表
export function listFeedback(query) {
  return request({
    url: '/operate/feedback/list',
    method: 'get',
    params: query
  })
}

// 查询意见附件
export function getByAffixInfo(query) {
  return request({
    url: '/operate/feedback/getByAffixInfo/' + query,
    method: 'get',
    params: query
  })
}

// 查询运营赛事意见反馈详细
export function getFeedback(feedbackId) {
  return request({
    url: '/operate/feedback/' + feedbackId,
    method: 'get'
  })
}


// 回复运营赛事意见反馈
export function replyFeedback(data) {
  return request({
    url: '/operate/feedback/replyFeedback',
    method: 'post',
    data: data
  })
}

