var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "可绑定媒体资源",
            visible: _vm.MediaVisible,
            width: "1000px",
            height: "900px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.MediaVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { data: _vm.toMediaList, border: "" },
              on: { "current-change": _vm.handleCurrentChange },
            },
            [
              _c("el-table-column", {
                key: "1",
                attrs: { width: "35" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: scope.row },
                            model: {
                              value: _vm.mediaRow,
                              callback: function ($$v) {
                                _vm.mediaRow = $$v
                              },
                              expression: "mediaRow",
                            },
                          },
                          [_vm._v(_vm._s(""))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "媒体名称",
                  align: "center",
                  prop: "mediaName",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "媒体类型",
                  align: "center",
                  prop: "mediaFileType",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("dict-tag", {
                          attrs: {
                            options: _vm.dict.type.play_file_type,
                            value: scope.row.mediaFileType,
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "在线播放地址", align: "center", prop: "url" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.mediaFileType === "1000"
                          ? _c("el-image", {
                              staticStyle: {
                                width: "50px",
                                height: "50px",
                                cursor: "pointer",
                              },
                              attrs: {
                                src: _vm.API + scope.row.url,
                                "preview-src-list": [_vm.API + scope.row.url],
                              },
                            })
                          : _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#1890ff",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.previewVideo(scope.row)
                                  },
                                },
                              },
                              [_vm._v("视频预览")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total3 > 0,
                expression: "total3 > 0",
              },
            ],
            attrs: {
              total: _vm.total3,
              page: _vm.queryParams.pageNum,
              limit: _vm.queryParams.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryParams, "pageNum", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryParams, "pageSize", $event)
              },
              pagination: _vm.getToMediaList,
            },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitMedia } },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.cancelRestock2 } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "视频预览",
            visible: _vm.videoVisible,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.videoVisible = $event
            },
            close: _vm.cancelVideo,
          },
        },
        [
          _c("div", { staticClass: "video" }, [
            _c("video", {
              attrs: {
                width: "100%",
                height: "100%",
                src: _vm.API + _vm.videoUrl,
                autoplay: "",
                controls: "",
              },
            }),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancelVideo } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }