var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "recentMatch",
      style: {
        height: _vm.height,
        backgroundImage: "url(" + _vm.background + ")",
      },
    },
    [
      _c("div", { staticClass: "p_title p_title_down" }, [_vm._v("热门赛事")]),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("hr", {
            staticStyle: {
              border: "#ffffff 1px solid",
              "margin-bottom": "2px",
            },
          }),
          _c(
            "el-row",
            { staticClass: "p_name" },
            [
              _c(
                "el-col",
                { staticStyle: { "text-align": "center" }, attrs: { span: 4 } },
                [_vm._v("类别")]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { span: 12 },
                },
                [_vm._v("赛事名称")]
              ),
              _c(
                "el-col",
                { staticStyle: { "text-align": "center" }, attrs: { span: 8 } },
                [_vm._v("比赛日期")]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "scollData",
              staticStyle: {
                height: "100%",
                overflow: "scroll",
                "overflow-x": "hidden",
                "overflow-y": "scroll",
              },
            },
            [
              _c(
                "vue-seamless-scroll",
                {
                  ref: "seamlessScrollRecent",
                  attrs: {
                    "class-option": _vm.classOption,
                    data: _vm.listRecentMatch,
                  },
                },
                _vm._l(_vm.listRecentMatch, function (item, index) {
                  return _c(
                    "el-row",
                    {
                      key: index,
                      staticStyle: { "margin-top": "1.5vh", height: "5.5vh" },
                      attrs: { gutter: 10 },
                    },
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "line",
                          staticStyle: { "text-align": "center" },
                          attrs: { span: 4 },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.matchClassify == "INSIDE"
                                  ? "内部赛"
                                  : "公开赛"
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "line",
                          staticStyle: { "padding-left": "5%" },
                          attrs: { span: 12 },
                        },
                        [_vm._v(" " + _vm._s(item.matchName) + " ")]
                      ),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "spot" }, [
                          _vm._v(
                            " " +
                              _vm._s(item.matchStartTime.replace(/-/g, "/")) +
                              " ~ " +
                              _vm._s(item.matchEndTime.replace(/-/g, "/")) +
                              " "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }