import request from '@/utils/request'

// 查询软件升级迭代日志列表
export function listUpgradeLog(query) {
    return request({
    url: '/monitor/upgradeLog/listUpgradeLog',
    method: 'get',
    params: query
  })
}

// 清空操作日志
export function cleanUpgradeLog() {
  return request({
    url: '/monitor/upgradeLog/cleanUpgradeLog',
    method: 'post'
  })
}


// 删除软件升级迭代日志
export function delUpgradeLog(upgradeId) {
  return request({
    url: '/monitor/upgradeLog/delUpgradeLog/' + upgradeId,
    method: 'post'
  })
}


// 导出软件升级迭代日志
export function exportUpgradeLog(query,updateIds) {
  if (0 === updateIds.length) {
    updateIds = [-1];
  }
  return request({
    url: '/monitor/upgradeLog/export/'+updateIds,
    method: 'get',
    params: query
  })
}
