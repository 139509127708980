import request from '@/utils/request'

// 查询根目录列表
export function listRootByType(type) {
  return request({
    url: '/ad/ad_folder/list/root/' + type,
    method: 'get'
  })
}

//查询指定folderId下一级目录列表
export function listSubFolder(folderId) {
  return request({
    url: '/ad/ad_folder/list/sub_folder/' + folderId,
    method: 'get'
  })
}

//查询指定folderId下一级目录列表, 不显示当前目录
export function listSubFolderForEdit(folderId,myFolderId) {
  return request({
    url: '/ad/ad_folder/list/sub_folder_for_edit/' + folderId +'/'+ myFolderId,
    method: 'get'
  })
}
// 按类型查询所有目录列表
export function getAllListRootByType(params) {
  return request({
    url: '/ad/ad_folder/list/all_by_type',
    method: 'get',
    params
  })
}
//新增分组目录
export function addFolder(data) {
  return request({
    url: '/ad/ad_folder/add_ad_folder', ///ad/ad_content
    method: 'post',
    params: {
      customerId : data.customId,
      folderType : data.folderType,
      folderName : data.folderName
    }
  })
}

//删除目录、其子目录、目录下所有内容
export function removeFolder(folderId) {
  return request({
    url: '/ad/ad_folder/delete/' + folderId,
    method: 'delete'
  })
}
export function isRemovableFolder(folderIds,folderType){
  return request({
    url: '/ad/ad_folder/is_ad_folder_removable',
    method: 'get',
    params : {
      adFolderIds:folderIds,
      folderType:folderType
    }
  })
}

// 修改目录
export function updateFolder(data) {
  return request({
    url: '/ad/ad_folder',
    method: 'put',
    params : {
      folderId: data.folderId,
      folderName: data.folderName
    }
  })
}
//
// // 删除运营赛事奖章
// export function delMedal(medalId) {
//   return request({
//     url: '/operate/medal/delete/' + medalId,
//     method: 'post'
//   })
// }

/**
 * 查询所有客户和分组
 * @param type
 * @returns {AxiosPromise}
 */
export function listAllCustomersAndFolders(type) {
  return request({
    url: '/ad/ad_folder/list_all_ad_customers_and_folders',
    method: 'get',
    params : {
      folderType :type
    }
  })
}

/**
 * 根据客户ID查询分组
 * @param type
 * @returns {AxiosPromise}
 */
export function selectFoldersListByCustomer(query) {
  return request({
    url: '/ad/ad_folder/select_folders_list_by_customer',
    method: 'get',
    params : query
  })
}


