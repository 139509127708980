import request from "@/utils/request";

// 查询设备信息列表
export function listInfo(query) {
  return request({
    url: "/rc/info/ScreenList",
    method: "get",
    params: query,
  });
}

// 查询设备信息详细
export function getInfo(diId) {
  return request({
    url: "/rc/info/" + diId,
    method: "get",
  });
}

// 删除设备信息
export function delInfo(diId) {
  return request({
    // url: "/rc/info/" + diId,
    url: "/rc/info/removeDevAndMore/" + diId,
    method: "delete",
  });
}

// 导出设备信息
export function exportInfo(dtCode, ids) {
  // if (ids.length === 0) {
  //   ids[0] = -1;
  // }
  return request({
    url: `/rc/info/export/${dtCode}&${ids}`,
    method: "get",
    // params: query,
  });
}
// 解绑绿道
export function noTrack(query) {
  return request({
    url: "/rc/greenway_device/" + query,
    method: "delete",
  });
}
// 切换自动状态
export function updateAuto(deviceCode, autoFlag) {
  return request({
    url: `/rc/info/auto/${deviceCode},${autoFlag}`,
    method: "put",
  });
}
// 切换页面类型
export function updatePage(deviceCode, pageType) {
  return request({
    url: `/rc/info/device/page/${deviceCode},${pageType}`,
    method: "put",
  });
}

// 上报web version
export function sendWebVersion(data) {
  return request({
    url: '/rc/info/device/reported',
    method: 'post',
    data: data,
  })
}

// 获取地图
export function getScreenPicture(query) {
  return request({
    url: "/rc/picture/getScreenPicture",
    method: "get",
    params: query,
  });
}
// post
export function postPicture(data) {
  return request({
    url: '/rc/picture',
    method: 'post',
    data: data
  })
}

//设备上架
export function deviceUpperFrame(diId) {
  return request({
    url: "/rc/info/device/deviceUpperFrame/" + diId,
    method: "get",
  });
}



//设备下架
export function deviceDownFrame(diId) {
  return request({
    url: "/rc/info/device/deviceDownFrame/" + diId,
    method: "get",
  });
}
