var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        position: "fixed",
        bottom: "50px",
        left: "20px",
        "z-index": "9999",
      },
      on: { click: _vm.reloadPage },
    },
    [_vm._v("版本时间：" + _vm._s(_vm.vTime))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }