import request from '@/utils/request'

//查询打卡点距离
export function selectPointDistance(cpDistanceId){
  return request({
    url:"/greenway/distance/pointDistance/"+cpDistanceId,
    method: 'get',
  })
}


//查询打卡点距离列表
export  function getPointDistance(query){
  return request({
    url:"/greenway/distance/list",
    method: 'get',
    params: query
  })
}


//查询打卡点距离列表(无分页)
export  function getPointDistanceNoPage(query){
  return request({
    url:"/greenway/distance/listNoPage",
    method: 'get',
    params: query
  })
}



//新增打卡点距离
export function addPointDistance(query) {
  return request({
    url:"/greenway/distance/add",
    method: 'POST',
    params: query
  })
}

//修改打卡点距离
export function updatePointDistance(query) {
  return request({
    url:"/greenway/distance/update",
    method: 'POST',
    params: query
  })
}

//删除打卡点距离
export function deletePointDistance(cpDistanceId) {
  return request({
    url:"/greenway/distance/delete/"+cpDistanceId,
    method: 'POST',
  })
}


//判断是打卡点距离否在路线中使用
export function getLineCount(cpDistanceId) {
  return request({
    url: '/greenway/distance/getGreenwayLineCount/'+cpDistanceId,
    method: 'get',
  })
}
