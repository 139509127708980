import request from '@/utils/request'

// 查询打卡次数排名
export function listClockingTimesRanking(query) {
  return request({
    url: '/match/ranking/clockingTimes',
    method: 'get',
    params: query
  })
}

// 查询打卡次数排名队伍
export function listClockingTimesIsTeam(query) {
  return request({
    url: '/match/ranking/clockingTimesIsTeam',
    method: 'get',
    params: query
  })
}

// 查询打卡次数排名队内
export function listClockingTimesIsTeamInside(query) {
  return request({
    url: '/match/ranking/clockingTimesIsTeamInside',
    method: 'get',
    params: query
  })
}

// 查询打卡次数排名队伍个人
export function listClockingTimesIsTeamPersonage(query) {
  return request({
    url: '/match/ranking/clockingTimesIsTeamPersonage',
    method: 'get',
    params: query
  })
}

// 查询累计里程排名
export function listMatchAccumulatedMileageRanking(query) {
  return request({
    url: '/match/ranking/accumulatedMileage',
    method: 'get',
    params: query
  })
}


// 查询累计里程排名队伍
export function listAccumulatedMileageIsTeam(query) {
  return request({
    url: '/match/ranking/accumulatedMileageIsTeam',
    method: 'get',
    params: query
  })
}

// 查询累计里程排名队内
export function listAccumulatedMileageIsTeamInside(query) {
  return request({
    url: '/match/ranking/accumulatedMileageIsTeamInside',
    method: 'get',
    params: query
  })
}

// 查询累计里程排名队伍个人
export function listAccumulatedMileageIsTeamPersonage(query) {
  return request({
    url: '/match/ranking/accumulatedMileageIsTeamPersonage',
    method: 'get',
    params: query
  })
}

// 查询目标里程排名
export function listTargetMileageRanking(query) {
  return request({
    url: '/match/ranking/targetMileage',
    method: 'get',
    params: query
  })
}

// 查询目标里程排名队伍
export function listTargetMileageRankingIsTeam(query) {
  return request({
    url: '/match/ranking/targetMileageRankingIsTeam',
    method: 'get',
    params: query
  })
}

// 查询目标里程排名队内
export function listTargetMileageRankingIsTeamInside(query) {
  return request({
    url: '/match/ranking/targetMileageRankingIsTeamInside',
    method: 'get',
    params: query
  })
}

// 查询目标里程排名队伍个人
export function listTargetMileageRankingIsTeamPersonage(query) {
  return request({
    url: '/match/ranking/targetMileageRankingIsTeamPersonage',
    method: 'get',
    params: query
  })
}

// 查询早鸟挑战赛
export function listChallengeMatchRanking(query) {
  return request({
    url: '/match/ranking/challengeMatch',
    method: 'get',
    params: query
  })
}

// 查询单次跑得快
export function listRunFastMatchRanking(query) {
  return request({
    url: '/match/ranking/runFastMatchRanking',
    method: 'get',
    params: query
  })
}

// 查询刷新个人最好成绩
export function listNewPersonalBestRanking(query) {
  return request({
    url: '/match/ranking/newPersonalBestRanking',
    method: 'get',
    params: query
  })
}

// 目标里程团队表格一
export function exportMBLCOneExcel(query) {
  return request({
    url: '/match/ranking/exportMBLCOneExcel',
    method: 'get',
    params: query
  })
}
// 目标里程团队表格二
export function exportMBLCTwoExcel(query) {
  return request({
    url: '/match/ranking/exportMBLCTwoExcel',
    method: 'get',
    params: query
  })
}
// 目标里程团队表格三
export function exportMBLCThreeExcel(query) {
  return request({
    url: '/match/ranking/exportMBLCThreeExcel',
    method: 'get',
    params: query
  })
}
// 目标里程非团队表格
export function exportMBLCNoTeamExcel(query) {
  return request({
    url: '/match/ranking/exportMBLCNoTeamExcel',
    method: 'get',
    params: query
  })
}
// 累计里程团队表格一
export function exportLJLCOneExcel(query) {
  return request({
    url: '/match/ranking/exportLJLCOneExcel',
    method: 'get',
    params: query
  })
}
// 累计里程团队表格二
export function exportLJLCTwoExcel(query) {
  return request({
    url: '/match/ranking/exportLJLCTwoExcel',
    method: 'get',
    params: query
  })
}
// 累计里程团队表格三
export function exportLJLCThreeExcel(query) {
  return request({
    url: '/match/ranking/exportLJLCThreeExcel',
    method: 'get',
    params: query
  })
}
// 累计里程非团队表格
export function exportLJLCNoTeamExcel(query) {
  return request({
    url: '/match/ranking/exportLJLCNoTeamExcel',
    method: 'get',
    params: query
  })
}
// 打卡次数团队表格一
export function exportDKCSOneExcel(query) {
  return request({
    url: '/match/ranking/exportDKCSOneExcel',
    method: 'get',
    params: query
  })
}
// 打卡次数团队表格二
export function exportDKCSTwoExcel(query) {
  return request({
    url: '/match/ranking/exportDKCSTwoExcel',
    method: 'get',
    params: query
  })
}
// 打卡次数团队表格三
export function exportDKCSThreeExcel(query) {
  return request({
    url: '/match/ranking/exportDKCSThreeExcel',
    method: 'get',
    params: query
  })
}
// 打卡次数非团队表格
export function exportDKCSNoTeamExcel(query) {
  return request({
    url: '/match/ranking/exportDKCSNoTeamExcel',
    method: 'get',
    params: query
  })
}

// 早鸟挑战赛
export function exportZNTZSExcel(query) {
  return request({
    url: '/match/ranking/exportZNTZSExcel',
    method: 'get',
    params: query
  })
}

// 刷新个人最好成绩
export function exportSXGRZHCJExcel(query) {
  return request({
    url: '/match/ranking/exportSXGRZHCJExcel',
    method: 'get',
    params: query
  })
}

// 单次跑得快
export function exportDCPDKExcel(query) {
  return request({
    url: '/match/ranking/exportDCPDKExcel',
    method: 'get',
    params: query
  })
}
