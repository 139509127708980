import request from '@/utils/request'
//获取赛道地址经纬度
export function getAddressCoordinates(address) {
    return request({
      url: '/greenway/track/getAddressCoordinates',
      method: 'get',
      params:{
        "address":address
      }
    })

  }
  //根据经度纬度获取地址信息
  export function getAddressInfo(lat, lng) {
    return request({
      url: '/greenway/track/getAddressInfo/'+lat +'/' + lng,
      method: 'get',
    })
  }
  export function getKeywordAddressList(address) {
    return request({
      url: '/greenway/track/getKeywordAddressList',
      method: 'get',
      params: {
        "keyword":address
      },
    })
   }
   // 新增设备信息
export function addInfo(data) {
    return request({
      url: '/rc/info/addDevAndCW',
      method: 'post',
      data: data
    })
  }

  // 修改设备信息
  export function updateInfo(data) {
    return request({
      url: '/rc/info',
      method: 'put',
      data: data
    })
  }

// 修改设备调试密码
export function updateDevicePwd(devicePwd) {
  return request({
    url: '/rc/info/device/updateDevicePwd/' + devicePwd,
    method: 'get',
  })
}
