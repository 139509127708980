
import request from "@/utils/request";
// 查询分享列表
export function findList(query) {
  return request({
    url: '/operate/share/findList',
    method: 'get',
    params: query
  })
}
