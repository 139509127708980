import request from '@/utils/request'

// 查询order_bill_demand列表
export function listDemand(query) {
  return request({
    url: '/order/demand/list',
    method: 'get',
    params: query
  })
}
// 线上开票
export function  Invoice(data) {
  return request({
    url: '/order/demand/onlineInvoice',
    method: 'post',
    data: data
  })
}
// 查询发票详细
export function getBill(billId) {
  return request({
    url: '/order/demand/' + billId,
    method: 'get'
  })
}
// 导出order_bill_demand
export function exportDemand(query,orderIds) {

  if (0 === orderIds.length) {
    orderIds = [-1];
  }

  return request({
    url: '/order/demand/export/'+orderIds,
    method: 'post',
    data: query
  })
}
