<template>
  <div :class="dataMatchType+' dataMatch'" :style="{height:height,backgroundImage:'url('+ background +')'}">
    <div class="p_title p_title_down">数据展示</div>
    <el-row class="elRow">
      <el-col :span="12">
        <span>{{listData.total}}</span>
        <p>总赛事场次</p>
      </el-col>
      <el-col :span="12">
        <span>{{listData.participants}}</span>
        <p>总参赛人数</p></el-col>
    </el-row>
    <el-row class="elRow">
      <el-col :span="12">
        <span>{{listData.finishVisits}}</span>
        <p>总完赛人次</p>
      </el-col>
      <el-col :span="12">
        <span>{{listData.enterToday}}</span>
        <p>今日参赛数</p>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import {totalsessions} from "@/api/device/screen-preview/screen-preview";

var source = null;
export default {
  name: "TotalSessions",
  props:['heightTotalSessions','backgroundTotalSessions'],
  data() {
    return {
      //定时器
      intervalId: null,
      //容器高度
      height:this.heightTotalSessions,
      //进度条高度
      listDataHeight:null,
      //边框
      background:require('@/assets/images/'+this.backgroundTotalSessions),
      //展示数据
      listData:{
        // total:600,participants:50,finishVisits:5320,enterToday:68
      },
      deviceCode: "",
      //进度条背景色
      customColor: "rgb(12, 119, 218)",
      dataMatchType:"dataMatch1"
    };
  },
  created(){
    // 如果路由有deviceCode，说明是在平台预览，否则是在大屏设备端展示
    if (this.$route.query.deviceCode) {
      this.deviceCode = this.$route.query.deviceCode;
      this.getDataList();
    }else {
      this.getDeviceCode();
    }
  },
  watch: {
    height: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue) {
          //设置进度条高度
          if(newValue == '25vh'){
            this.listDataHeight = '4.2vh'
            this.dataMatchType = "dataMatch2"
          }else{
            this.listDataHeight = '3.5vh'
            this.dataMatchType = "dataMatch1"
          }
        }
      },
    },
  },

  methods: {
    // 查询设备编号
    getDeviceCode() {
      this.$bridge.callHandler("searchDeviceCode", "", (data) => {
        var settings = JSON.parse(data);
        this.deviceCode = settings.deviceCode;

        if (this.deviceCode) {
          this.getDataList();
        }
      });
    },
    /** 查询赛事总场次 */
    getDataList() {
        totalsessions(this.deviceCode).then((response) => {
          this.listData = response.data;
        });
    },
    setData(value) {
      if (value > 100) {
        value = value + "";
        var totalData = 10 ** value.length;
        value = (value / totalData) * 100;
      }
      return value;
    },
    //进度条文字
    format1(percentage) {
      return this.listData.total;
    },
    format2(percentage) {
      return this.listData.participants;
    },
    format3(percentage) {
      return this.listData.finishVisits;
    },
    format4(percentage) {
      return this.listData.enterToday;
    },
  },
  mounted() {
    //收到完赛消息重新调赛事数据、赛事排名接口
    this.$emitter.on('getMqttData', ()=>{
      this.getDataList()
    })
    this.intervalId = setInterval(()=>{
      this.getDataList()
    }, 5 * 60 * 1000)
  },
  beforeDestroy() {
    if(this.intervalId){
      clearInterval(this.intervalId)
    }
  }
}
</script>

<style lang="scss" scoped>
.dataMatch1{
  padding: 6vh 2vw 2vh 2vw;
}
.dataMatch2{
  padding: 7vh 2vw 2vh 2vw;
}
.dataMatch {
  margin-top: 2vh;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat,no-repeat;
  background-size: 100% 100%;
  //padding: 6.5vh 2vw 2vh 2vw;
  .elRow {
    text-align: center;
    span{
      font-size: 22px;
    }
    p{
      font-size: 16px;
      margin: 10px 0px;
    }
  }
  .p_title_down {
    position: absolute;
    left: 10px;
    top: 5px;
  }
}
</style>
