var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fullscreen",
    {
      attrs: { fullscreen: _vm.fullscreen },
      on: {
        "update:fullscreen": function ($event) {
          _vm.fullscreen = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "screen2" },
        [
          _c(
            "el-container",
            { staticStyle: { height: "100%" } },
            [
              _c("el-header", [
                _c("div", { staticClass: "mainTitle" }, [
                  _c(
                    "div",
                    {
                      staticClass: "top",
                      on: {
                        click: function ($event) {
                          return _vm.toggle()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.screenTitle))]
                  ),
                ]),
              ]),
              _c(
                "el-main",
                { staticStyle: { "padding-top": "43px" } },
                [
                  _c("w-clock", { staticClass: "clock" }),
                  _c("w-weather", { staticClass: "weather" }),
                  _c(
                    "div",
                    { staticStyle: { "padding-top": "10px", height: "100%" } },
                    [_c("big-screen-view")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }