import request from '@/utils/request'

// 查询赛事分享统计
export function listMatchShareCensus(data) {
  return request({
    url: '/operate/share/listMatchShareCensus?pageNum='+data.pageNum+'&pageSize='+data.pageSize,
    method: 'POST',
    data: data
  })
}


// 导出赛事分享统计
export function exportMatchShareCensus(data) {
  return request({
    url: '/operate/share/exportMatchShareCensus',
    method: 'POST',
    data: data
  })
}

// 查询赛事分享报名用户信息
export function listMatchShareCensusPlayUser(data) {
  return request({
    url: '/operate/share/listMatchShareCensusPlayUser?pageNum='+data.pageNum+'&pageSize='+data.pageSize,
    method: 'POST',
    data: data
  })
}
