import WClock from "@/views/device/widget/clock/w-clock";
import WClockConfig from "@/views/device/widget/clock/w-clock-config";

export const clockConfig = {
  "x": null,
  "y": null,
  "w": 10,
  "h": 5,
  "i": null,
  label: '时间',
  icon: 'el-icon-date',
  children: {
    component: 'w-clock',// WClock.name,
    componentConfig: 'w-clock-config', // WClockConfig.name,
    props: {
      format: '{y}-{m}-{d} {h}:{i}:{s} 星期{a}',
      color: '#ffd700',
      fontSize: 14,
      textAlign: 'left'
    },
  },
  selected: false
}
