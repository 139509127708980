import request from '@/utils/request'
// 查询列表
export function list(query) {
  return request({
    url: '/parkArea/matchRecords/list',
    method: 'get',
    params: query
  })
}


