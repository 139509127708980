import {clockConfig} from "@/views/device/widget/clock/config";
import {textConfig} from "@/views/device/widget/text/config";
import {imgConfig} from "@/views/device/widget/img/config";
import {videoConfig} from "@/views/device/widget/video/config";
import {weatherConfig} from "@/views/device/widget/weather/config";
// import {runMapConfig} from "@/views/device/widget/run-map/config";

const widgetConfig = [
  textConfig,
  imgConfig,
  videoConfig,
  clockConfig,
  weatherConfig,
  // runMapConfig,
]

export default widgetConfig
