import { compressAccurately } from 'image-conversion' //图片压缩api

const  pngToWhiteBg = (file) => {
  let read = new FileReader();
  read.readAsDataURL(file); // 文件转base64
  return new Promise((resolve, reject) => {
    read.onload = (e) => {
      let img = new Image();
      img.src = e.target.result;
      img.onload = async () => {
        // 生成canvas
        let canvas = document.createElement("canvas");
        let context = canvas.getContext("2d");
        // 绘制图片到canvas上
        canvas.width = img.width;
        canvas.height = img.height;

        // 在canvas绘制前填充白色背景
        context.fillStyle = "#fff";
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.drawImage(img, 0, 0);
        let base64 = canvas.toDataURL(file["type"], 1);
        let newFile = dataUrlToFile(base64);
        resolve(newFile);
      };
    };
  });
}
const  dataUrlToFile = (dataurl) => {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}
export async function compressAccuratelyEnd(file,isVerifyWidthAndHeight = false,isCompress = true,maxFileSize = 10)  {
    // file 文件名称
    // isVerifyWidthAndHeight 是否检查图片长宽比例
    // isCompress 是否开启压缩
    // maxFileSize 最大上传文件大小 默认为10M
    const isTypeAndLt2M = await new Promise(function (resolve, reject) {

        const isType = file.type === 'image/jpeg' || file.type === 'image/png';
        //上传最大限制
        const isLt2M = file.size / 1024 / 1024 < maxFileSize;
        if (!isType) {
            reject('上传图片只能是 JPG 或 PNG 格式!');
        } else if (!isLt2M) {
            reject(`上传图片大小不能超过${maxFileSize}MB!`);
        } else {
            resolve()
        }
    }).then(
        async () => {
                // 注意这里需要用Promise函数来阻止图片没有压缩好,就直接将文件上传
                const iscompress = await new Promise(async (resolve, reject) => {
                    const isJPG = file.type === 'image/jpeg';
                    const isPng = file.type === 'image/png';
                    const imgType = file.type
                    const imgName = file.name
                    let compress = 0.2 * 1024 * 1024    // 假设图片限制不能大于0.2M
                    let sizeOver = file.size > compress; //文件大小 是否大于指定大小
                    //当图片大于0.2M时进行压缩
                    if (sizeOver && compress && isCompress) {
                        //当图片在1 - 5M 直接 压缩到0.2M  超过5M压缩到0.4M
                        let size = (file.size / 1024 / 1024) <= 5 ? 200 : 400
                        // 由于png透明图片压缩jpeg后会默认填充黑底 所以做处理改为白底填充
                        const newFile = await pngToWhiteBg(file)
                        const res = await compressAccurately(newFile, {
                            size, //需要压缩的大小
                            accuracy: 0.9, //精度 0.8-0.99之间 默认值0.95
                            type: 'image/jpeg',
                            width: 105,
                            height: 105,
                            scale: 0.5,
                        });
                        file = res; //把得到的新的图片文件赋值给原文件,然后进行上传   得到的是bold对象
                        file = new window.File([file], imgName, { type: imgType }) //将bold对象转换为file文件
                        resolve(file)
                    } else {
                        resolve(file) // 通过resolve将Promise函数返回成功回调,进行后面操作
                    }
                })
                return iscompress;


        },

        (mes) => {
            this.$message.error(mes);
            return Promise.reject(false);
        }
    );

    if (isVerifyWidthAndHeight) {
        await new Promise(function (resolve, reject) {
            let _URL = window.URL || window.webkitURL;
            let image = new Image();

            image.onload = function () {
                const proportion = image.width / image.height;
                const valid = 1.3 < proportion && proportion < 1.8;
                valid ? resolve() : reject()
            };
            image.src = _URL.createObjectURL(file)
        }).then(
            () => {
            },
            () => {
                this.$message({
                    showClose: true,
                    center: true,
                    duration: 3000,
                    message: '建议上传宽高比率为 1 比 1.3 ~ 1.8 之间的长方形图片 ',
                    type: 'warning'
                });

            }
        );
    }
    return isTypeAndLt2M


}
