import request from '@/utils/request'

// 查询已完赛名单列表
export function listMatchComplete(query) {
  return request({
    url: '/rc/screenMatch/completeMatch',
    method: 'get',
    params: query
  })
}
// 查询当前赛事列表
export function listCurrentMatch(query) {
  return request({
    url: '/rc/screenMatch/currentMatch/' + query + '/20',
    method: 'get',
  })
}
// 查询赛事讲解视屏 广告
export function listGetVideoUrl(dtCode) {
  return request({
    url: '/rc/space/device/ad/' + dtCode,
    method: 'get',
  })
}
// 查询最近赛事列表
export function listRecentMatchForm(query) {
  return request({
    url: '/rc/screenMatch/recentMatch/' + query,
    method: 'get',
    params: query
  })
}
// 方块统计
export function totalsessions(query) {
  return request({
    url: '/rc/screenMatch/getTotal/' + query,
    method: 'get'
  })
}
// 关闭SSE连接
export const getCloseSseConnect = (dtCode) => {
  return request({
    url: '/sse/CloseSseConnect/' + dtCode,
    method: 'get',
  })
}

//赛事大屏地图
export const getTrackImage = (query) => {
  return request({
    // url: '/rc/screenMatch/getTrackImage/' + deviceCode,
    // method: 'get',
    url: "/rc/picture/getScreenPicture",
    method: "get",
    params: query,
  })
}
// 根据设备编码获取页面信息
export function getPageType(deviceCode) {
  return request({
    url: '/rc/info/device/getPage/' + deviceCode,
    method: 'get',
  })
}
// 个人赛事
export function getPersonalMatch(deviceData) {
  return request({
    url: '/rc/screenMatch/personalMatch?deviceCode=' + deviceData.deviceCode+'&rfidEpcNo=' + deviceData.rfidEpcNo,
    method: 'get',
  })
}
// 根据设备编码获取完赛数据
export function overGameDevice(deviceCode) {
  return request({
    url: '/rc/log/device/overGame/' + deviceCode,
    method: 'get',
  })
}

// 获取完赛数据（最近100条）
export function playFinishRecord() {
  return request({
    url: '/rc/log/device/playFinishRecord',
    method: 'get',
  })
}


// 月度累积里程排行及累积打卡排行接口
export function monthlyRanking(data) {
  return request({
    url: '/rc/screenMatch/monthlyRanking',
    method: 'get',
    params:data,
  })
}
// 月度组别排行接口
export function monthlyGroupRanking(data) {
  return request({
    url: '/rc/screenMatch/monthlyGroupRanking',
    method: 'get',
    params:data,
  })
}
//赛事大屏获取名称
export function getTitle(data) {
  return request({
    url: '/rc/info/device/getScreenShowName' ,
    method: 'post',
    data: data,
  })
}
