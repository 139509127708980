import WWeather from "@/views/device/widget/weather/w-weather";
import WWeatherConfig  from "@/views/device/widget/weather/w-weather-config";

export const weatherConfig = {
  "x": null,
  "y": null,
  "w": 14,
  "h": 5,
  "i": null,
  label: '天气',
  icon: 'el-icon-sunny',
  children: {
    component: 'w-weather', // WWeather.name,
    componentConfig: 'w-weather-config', // WWeather.name,
    props: {
      city:'深圳',
      tmpNew:'',
      briefNew:'',
      nowIcon:'',
      fontSize: 14,
      color: '#ffd700',
      textAlign: 'left',
    },
  },
  selected: false
}
