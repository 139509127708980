var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse },
      style: {
        backgroundColor:
          _vm.sideTheme === "theme-dark"
            ? _vm.variables.menuBackground
            : _vm.variables.menuLightBackground,
      },
    },
    [
      _c("transition", { attrs: { name: "sidebarLogoFade" } }, [
        _vm.collapse
          ? _c("div", { key: "collapse", staticClass: "sidebar-logo-link" }, [
              _vm.logo
                ? _c("img", {
                    staticClass: "sidebar-logo",
                    attrs: { src: _vm.logo },
                  })
                : _c(
                    "h1",
                    {
                      staticClass: "sidebar-title",
                      style: {
                        color:
                          _vm.sideTheme === "theme-dark"
                            ? _vm.variables.logoTitleColor
                            : _vm.variables.logoLightTitleColor,
                      },
                    },
                    [_vm._v(_vm._s(_vm.title) + " ")]
                  ),
            ])
          : _c("div", { key: "expand", staticClass: "sidebar-logo-link" }, [
              _vm.logo
                ? _c("img", {
                    staticClass: "sidebar-logo",
                    attrs: { src: _vm.logo },
                  })
                : _vm._e(),
              _c(
                "h1",
                {
                  staticClass: "sidebar-title",
                  style: {
                    color:
                      _vm.sideTheme === "theme-dark"
                        ? _vm.variables.logoTitleColor
                        : _vm.variables.logoLightTitleColor,
                  },
                },
                [_vm._v(_vm._s(_vm.title) + " ")]
              ),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }