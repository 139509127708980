import request from '@/utils/request'

// 查询绿道打卡点列表
export function listPoint(query) {
  return request({
    url: '/greenway/point/list',
    method: 'get',
    params: query
  })
}

// 无分页
export function listPointNoPaging(query) {
  return request({
    url: '/greenway/point/listNoPaging',
    method: 'get',
    params: query
  })
}
// 无分页(CP点列表赛事打卡点距离)
export function listToSelect(query) {
  return request({
    url: '/greenway/point/listToSelect',
    method: 'get',
    params: query
  })
}

// 查询绿道打卡点详细
export function getPoint(checkPointId) {
  return request({
    url: '/greenway/point/' + checkPointId,
    method: 'get'
  })
}

// 新增绿道打卡点
export function addPoint(data) {
  return request({
    url: '/greenway/point/pointAdd',
    method: 'post',
    data: data
  })
}

// 修改绿道打卡点
export function updatePoint(data) {
  return request({
    url: '/greenway/point/edit',
    method: 'post',
    data: data
  })
}

// 删除绿道打卡点
export function delPoint(checkPointId) {
  return request({
    url: '/greenway/point/del/' + checkPointId,
    method: 'post'
  })
}

// 导出绿道打卡点
export function exportPoint(query) {
  return request({
    url: '/greenway/point/export',
    method: 'get',
    params: query
  })
}

//判断打卡点是否在路线种使用
export function getLineCount(checkPointId,greenwayId) {
  return request({
    url: '/greenway/point/getGreenwayLineCount/'+checkPointId,
    method: 'get',
  })
}

//获取绑定设备
export function getDeviceInfo(query){
  return request({
    url: '/greenway/point/getDeviceInfoList',
    method: 'get',
    params: query,
  })
}

//打卡点多绿道共用：绿道关联打卡点
export function listByGreenwayId(query){
  return request({
    url: '/greenway/point/listByGreenwayId',
    method: 'get',
    params: query,
  })
}
//打卡点多绿道共用：删除关联打卡点
export function pointRelationDel(query){
  return request({
    url: '/greenway/point/pointRelationDel',
    method: 'get',
    params: query,
  })
}
//打卡点多绿道共用：添加关联打卡点
export function pointRelationAdd(query){
  return request({
    url: '/greenway/point/pointRelationAdd',
    method: 'get',
    params: query,
  })
}