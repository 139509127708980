<template>
  <div class="bigScreen">
    <!--                <img src="@/assets/images/login-background.jpg" width="100%" height="100%"/>-->
    <!--自定义-->
<!--    <big-data-screen-->
<!--      v-show="adType == 'ZDY'"-->
<!--      ref="bigDataScreen"-->
<!--      :mode="mode"-->
<!--      :layout="custom.layout"-->
<!--      :borderBox="borderBox"-->
<!--      :title="title"-->
<!--      :backgroundColor="custom.backgroundColor"-->
<!--      :width="custom.width"-->
<!--      :height="custom.height"-->
<!--    ></big-data-screen>-->
    <!-- 广告单 -->
    <div class="p_video">
      <el-carousel
        ref="carousel"
        @change="carouselChange"
        :autoplay="false"
        class="imgClass"
        arrow="never"
      >
        <el-carousel-item
          v-for="(item, index) in advertisementList"
          :key="item.playbillId"
        >
          <!-- 只渲染当前轮播所在位置数据  -->
          <div class="fill" v-if="currentPosition === index">
            <!-- 图片 -->
            <img
              v-if="item.mediaFileType === '1000' || !item.mediaFileType"
              width="100%"
              height="100%"
              :src="item.url"
            />
            <!-- 视频 -->
            <video
              ref="videoAd"
              v-if="item.mediaFileType === '1001'"
              :src="item.url"
              autoplay="autoplay"
              :muted="false"
              class="videoStyle"
              @pause="videoPause"
              :loop="videoLoop"
              type="video/mp4"
            ></video>
            <!-- 自定义 -->
            <big-data-screen
              v-if="item.mediaFileType === '1002'"
              ref="bigDataScreen"
              :mode="mode"
              :layout="custom.layout"
              :borderBox="borderBox"
              :title="title"
              :backgroundColor="custom.backgroundColor"
              :width="custom.width"
              :height="custom.height"
            ></big-data-screen>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
import BigDataScreen from "@/views/device/components/big-data-screen";
import { nanoid } from "nanoid";
import WImg from "@/views/device/widget/img/w-img";
import WImgConfig from "@/views/device/widget/img/w-img-config";
import WText from "@/views/device/widget/text/w-text";
import WTextConfig from "@/views/device/widget/text/w-text-config";
// 查询设备绑定的媒体广告
import { listGetVideoUrl } from "@/api/device/screen-preview/screen-preview";
import { mqttTopic, onMqttMessage, unsubscribe } from "@/utils/mqtt";
export default {
  name: "AdvertEdit",
  components: {
    BigDataScreen,
  },
  data() {
    return {
      // 类型
      adType: "JMD",
      // 自定义广告
      custom: {
        backgroundColor: "#282c34",
        layout: [],
        width: 830,
        height: 690,
      },
      borderBox: "dv-border-box-1",
      mode: "view",
      title: "",
      deviceCode: "",
      //   广告单
      rcAdPlaybillList: [],
      mediaFileType: null,
      //当前播放视频下标
      curr: 0,
      intervalTime: 10000,
      //   广告单
      advertisementList: [
        {
          playbillId: "1",
          url: "",
        },
      ],
      currentPosition: 0, //保存当前轮播位置下标
      nextTimer: null, //当前广告轮播时间
      videoLoop: false, //视频是否循环播放
    };
  },
  created() {
    // 请求layout数据
    // this.init()
    // this.getDeviceCode();
    if (this.$route.query.deviceCode) {
      this.deviceCode = this.$route.query.deviceCode;
      this.getMqttData();
      this.init();
    } else {
      this.getDeviceCode();
    }
  },
  mounted() {
    //监听视频播放结束，自动播放下一个
    this.$nextTick(() => {
      // this.$refs.videoAd.addEventListener("ended", () => {
      //   this.runVideoUrl();
      // });
      // this.init();
    });
  },
  watch: {},
  methods: {
    //mqtt订阅
    getMqttData() {
      //连接mqtt mqtt发消息即调接口
      onMqttMessage({
        topic: this.deviceCode + mqttTopic.push_to_device_ad,
      });
      this.$emitter.on(
        this.deviceCode + mqttTopic.push_to_device_ad,
        ({ topic, message }) => {
          if (topic === this.deviceCode + mqttTopic.push_to_device_ad) {
            this.$nextTick(() => {
              this.init();
            });
          }
        }
      );
    },
    // 查询设备编号
    getDeviceCode() {
      this.$bridge.callHandler("searchDeviceCode", "", (data) => {
        var settings = JSON.parse(data);
        this.deviceCode = settings.deviceCode;
        if (this.deviceCode) {
          //连接mqtt mqtt发消息即调接口
          this.getMqttData();
          this.init();
        }
      });
    },
    init() {
      // 获取deviceCode
      // let deviceCode = "Test-MatchScreen-01"
      // 请求layout数据
      this.advertisementList = [];
      listGetVideoUrl(this.deviceCode).then((response) => {
        if (response.data) {
          // if (response.data.adType == "ZDY") {
          //   //   自定义广告
          //   this.adType = response.data.adType;
          //   this.custom = response.data.rcAdCustom;
          //   this.custom.layout = response.data.rcAdCustom.content.con;
          // } else if (response.data.adType == "JMD") {
          //   // 广告单广告
          //   this.adType = response.data.adType;
          //   this.rcAdPlaybillList = response.data.rcAdPlaybillList;
          //   this.intervalTime = this.rcAdPlaybillList[0]?.switchoverTime
          //     ? this.rcAdPlaybillList[0].switchoverTime * 1000
          //     : 10 * 1000;
          //   // 如果是设备进来，就用127的地址
          //   if (this.$route.query.device == 1) {
          //     this.rcAdPlaybillList.map((item) => {
          //       item.url = "https://127.0.0.1:8082" + item.url;
          //     });
          //   } else {
          //     this.rcAdPlaybillList.map((item) => {
          //       item.url = process.env.VUE_APP_BASE_API + item.url;
          //     });
          //   }
          //
          //   this.booleanEnd(response.data.stopTime);
          // }
          const rcAdCustom = response.data.rcAdCustom || []
          const rcAdPlaybillList = response.data.rcAdPlaybillList || []
          //当前轮播位置归零
          this.currentPosition = 0
          //判断是否有数据
          if(rcAdCustom.length === 0 && rcAdPlaybillList.length === 0){
            //   使用默认图片
            // this.defaultImg();
            this.advertisementList = [];
          }else {
            //给自定义数据加区分类型,统一
            rcAdCustom.forEach(item => {
              item.mediaFileType=  "1002";
            })
            rcAdPlaybillList.forEach(item => {
                if (this.$route.query.device == 1) {
                    item.url = "https://127.0.0.1:8082" + item.url;
                } else {
                    item.url=  process.env.VUE_APP_BASE_API + item.url;
                }
            })
            //数组合并
            this.advertisementList = rcAdPlaybillList.concat(rcAdCustom)
            //触发第一个广告轮播时间
            this.isNext(0);
          }
        } else {
          this.advertisementList = null;
          this.adType = "JMD";
        }
      });
    },

    // 判断是否广告位投放结束时间
    booleanEnd(stopTime) {
      let nowTime = new Date() / 1000;
      let endTime = new Date(stopTime).getTime() / 1000;
      if (endTime >= nowTime) {
        if (this.rcAdPlaybillList[0].mediaFileType == "1000") {
          this.mediaFileType = "1000";
        } else {
          this.mediaFileType = "1001";
          this.$nextTick(() => {
            this.runVideoUrl();
          });
        }
        // }else{
        //   this.$message.error('该广告位绑定广告单已过投放结束时间，请重新绑定！');
      }
    },
    //触发轮播翻页时
    carouselChange(index) {
      //保存当前轮播位置下标
      this.currentPosition = index;
      if(index === 0){
        this.init();
      }else{
        this.isNext(index);
      }
    },

    //监听视频播放完成后自动翻页
    videoPause() {
      this.next();
    },
    //自动翻页
    next() {
      //清除上一次轮播时间
      clearTimeout(this.nextTimer);
      //判断是否有广告位或只有一条广告位
      if (!this.advertisementList || this.advertisementList.length <= 1) {
        this.init();
        return;
      }
      this.$refs.carousel.next();
    },
    //判断当前广告位轮播时间
    isNext(index) {
      // //如果广告为只有一个,默认视频循环播放
      // if (this.advertisementList.length === 1) {
      //   this.videoLoop = true;
      // } else {
      //   this.videoLoop = false;
      // }
      //清除上一次轮播时间
      clearTimeout(this.nextTimer);
      //当前广告位数据
      const current = this.advertisementList[index] || "";
      //广告位为图片时
      let switchTime = (current.switchoverTime || 0) ? current.switchoverTime * 1000 : 1000*10
      if (current.mediaFileType === "1000") {
        this.nextTimer = setTimeout(() => {
          this.next();
        },switchTime );
      } else if (current.mediaFileType === "1002") {
        //广告位为自定义时
        this.custom = current;
        const content= JSON.parse(current.content)
        this.custom.layout = content.con;
        this.nextTimer = setTimeout(() => {
          this.next();
        },switchTime);
      }
    },
    /** 循环播放视频队列 */
    runVideoUrl() {
      let video = this.$refs.videoAd;
      video.src = this.rcAdPlaybillList[this.curr].url;
      video.load();
      video.play();
      this.curr++;
      if (this.curr >= this.rcAdPlaybillList.length) {
        this.curr = 0;
      }
    },
  },
  beforeDestroy() {
    if (this.deviceCode) {
      // 销毁前取消订阅
      unsubscribe(this.deviceCode + mqttTopic.push_to_device_ad);
    }
    //清除定时器
    clearTimeout(this.nextTimer);
  },
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.bigScreen {
  overflow: hidden;
  padding: 0;
  margin: 0;
  //width: 900px;
  //height: 1000px;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .p_video {
    width: 100%;
    height: 100%;
  }
  ::v-deep .el-carousel__item.is-animating {
    width: 100%;
    height: 100%;
  }
  ::v-deep .el-carousel__container {
    width: 100%;
    height: 100%;
  }
  ::v-deep .el-carousel--horizontal {
    width: 100%;
    height: 100%;
  }
}
.fill {
  height: 100%;
}
.videoStyle {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
</style>
