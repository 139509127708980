<template>
  <div className="box">
    <el-table
      :data="list"
      :show-header="headerStatus"
      style="width: 100%; overflow: hidden"
      :row-style="{ height: '40px' }"
      :header-cell-style="{ 'text-align': 'center' }"
      :cell-style="{ 'text-align': 'center', padding: '5px' }"
    >
      <el-table-column
        prop="rankingValue"
        label="排名"
        v-if="headerStatus == true"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope" style="position: relative">
          <img
            v-if="scope.row.rankingValue == 1"
            style="position: absolute; top: 5px; left: 44%"
            width="35"
            height="28"
            src="../../../../assets/images/first.png"
            alt=""
          />
          <img
            v-else-if="scope.row.rankingValue == 2"
            style="position: absolute; top: 5px; left: 44%"
            width="35"
            height="28"
            src="../../../../assets/images/twice.png"
            alt=""
          />
          <img
            v-else-if="scope.row.rankingValue == 3"
            style="position: absolute; top: 5px; left: 44%"
            width="35"
            height="28"
            src="../../../../assets/images/third.png"
            alt=""
          />
          <span v-else>{{ scope.row.rankingValue }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="playUserName"
        label="用户名"
        align="center"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <div style="float: left; position: relative; padding: 0 70px">
            <el-avatar
              :size="20"
              :src="getImgPath(scope.row.avatar)"
              fit="cover"
              style="position: absolute; top: 50%; transform: translateY(-50%)"
            ></el-avatar>
            <span style="margin-left: 30px">{{ scope.row.playUserName }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="score"
        :label="scoreLabel"
        :show-overflow-tooltip="true"
      ></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "MatchTable",
  props: ["list", "status", "matchRule"],
  computed: {},
  data() {
    return {
      headerStatus: false,
      scoreLabel: "",
    };
  },
  created() {},
  watch: {
    status: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue == 1) {
          this.headerStatus = true;
        }
      },
    },
    matchRule: {
      immediate: true,
      handler(newValue, oldValue) {
        if (
          newValue == "DCPDK" ||
          newValue == "SXGRZHCJ" ||
          newValue == "ZNTZS"
        ) {
          this.scoreLabel = "用时";
        } else if (newValue == "DKCS") {
          this.scoreLabel = "打卡次数";
        } else if (newValue == "MBLC") {
          this.scoreLabel = "完赛日期";
        } else if (newValue == "LJLC") {
          this.scoreLabel = "累计里程";
        }
      },
    },
  },
  methods: {
    getImgPath(imgPath) {
      // 正则匹配以 http:// 或者 https:// 开头
      if (/^(http|https):\/\//.test(imgPath)) {
        return imgPath;
      } else {
        return process.env.VUE_APP_BASE_API + imgPath;
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.box {
  overflow: hidden;
  width: 100%;
  height: 100%;
}
::v-deep .el-avatar > img {
  width: 100%;
  height: 100%;
}
</style>
