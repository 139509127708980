<template>
  <div class="container">
    <div style="display: flex; align-items: center">
      <el-input
        v-model.trim="queryParams.deviceName"
        placeholder="请输入设备名称"
        clearable
        size="small"
        @keyup.enter.native="handleQuery"
      />
      <!-- <el-select
        v-model="queryParams.DtCode"
        placeholder="请选择设备类型"
        clearable
        size="small"
      >
        <el-option
          v-for="dict in dict.type.device_type1"
          :key="dict.value"
          :label="dict.label"
          :value="dict.value"
        />
      </el-select> -->
      <el-button
        style="background: #2d8d9c; border: 0; color: #fff; margin-left: 10px"
        icon="el-icon-search"
        size="mini"
        @click="handleQuery"
      >
      </el-button>
      <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">
      </el-button>
    </div>
    <operation-card :infoList="infoList"></operation-card>
    <div class="page">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNum"
        :page-size="queryParams.pageSize"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { screenList } from "@/api/device/operation-maintenance/operation-maintenance";
import operationCard from "./components/operationCard.vue";
export default {
  components: { operationCard },
  dicts: ["device_type1"],
  data() {
    return {
      // 总条数
      total: 0,
      // 设备信息表格数据
      infoList: [],
      // 显示搜索条件
      showSearch: true,
      // 查询参数
      queryParams: {
        DtCode: "",
        pageNum: 1,
        pageSize: 12,
        deviceName: null,
        deviceCode: null,
        greenwayName: null,
        isUpDown: "SJ",
      },
      //导出参数
      query: {},
    };
  },
  created() {
    this.getList();
  },
  methods: {
    /** 查询设备信息列表 */
    getList() {
      this.loading = true;
      screenList(this.queryParams).then((response) => {
        this.infoList = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParams.deviceName = null;
      this.handleQuery();
    },

    //监听 pagesize 改变的事件
    handleSizeChange(newsize) {
      this.queryParams.pageSize = newsize;
      this.getList();
    },
    // 监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      this.queryParams.pageNum = newPage;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 10px;
  background: linear-gradient(135deg, #a5f2d5 0%, #38bcf0 100%);
  min-height: 100%;
  .pull {
    position: absolute;
    right: 50px;
    top: -70px;
  }
  .page {
    margin: 15px 0;
  }
  .el-pagination {
    text-align: center;
  }
  ::v-deep .el-input__inner {
    background-color: rgba(255, 255, 255, 0.3);
  }

  .pagination-container {
    background-color: transparent;
  }
}
</style>
