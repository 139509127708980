<template>
  <div ref="bigDataScreenContentRef" class="main" :style="{height: contentHeight + 'px', backgroundColor: backgroundColor}">
    <!--<component :is='borderBox || "div"' :title="title" ref="borderBoxRef">-->
      <grid-layout
        ref="gridlayout"
        :layout.sync="layout"
        :col-num="100"
        :row-height="contentHeight / 100"
        :max-rows="100"
        :is-draggable="true"
        :is-resizable="true"
        :is-mirrored="false"
        :vertical-compact="false"
        :margin="[0, 0]"
        :use-css-transforms="true"
        :prevent-collision="true"
        :isDraggable="mode === 'edit'"
        :isResizable="mode === 'edit'"
      >

        <grid-item
          v-for="item in layout"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          :i="item.i"
          :key="item.i"
          :preserveAspectRatio="item.preserveAspectRatio"
          :class="classNames('vue-grid-item', {'vue-grid-item__edit': mode === 'edit'}, {'vue-grid-item__selected': mode === 'edit' && item.selected})"
          @move="moveEvent"
          @moved="movedEvent"
        >
          <div style="height: 100%;" @click.stop="selectGridItem(item.i)">
            <!--                  {{item.i}}-->
            <span v-if="mode === 'edit'" class="remove" @click="removeItem(item.i)">x</span>
            <component
              v-if="item.children"
              :is="item.children.component"
              v-bind="item.children.props"
              v-on="item.children.events"
            >
            </component>
          </div>

        </grid-item>
      </grid-layout>
    <!--</component>-->
  </div>
</template>

<script>
import VueGridLayout from "vue-grid-layout";
import WClock from "@/views/device/widget/clock/w-clock";
import WText from "@/views/device/widget/text/w-text";
import WVideo from "@/views/device/widget/video/w-video";
import WImg from "@/views/device/widget/img/w-img";
import WRunMap from "@/views/device/widget/run-map/w-run-map";

import WWeather from "@/views/device/widget/weather/w-weather";

import ResizeObserver from 'resize-observer-polyfill';
import classNames from 'classnames'
import _ from 'lodash'
export default {
  name: "big-data-screen",
  components: {
    WClock,
    WText,
    WVideo,
    WImg,
    WWeather,
    WRunMap,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem
  },
  props: {
    layout: {
      type: Array,
      default: () => []
    },
    // 编辑模式 或 预览模式
    mode: {
      type: String,
      default: 'edit',
      validator: function (value) {
        // 这个值必须匹配下列字符串中的一个
        return ['edit', 'view'].indexOf(value) !== -1
      }
    },
    // 边框
    borderBox: {
      type: String,
      default: 'div'
    },
    // 标题
    title: {
      type: String,
      default: ''
    },
    // 背景颜色
    backgroundColor: {
      type: String,
      default: ''
    },
    // 需要适配的屏幕宽度
    width: {
      type: Number,
      default: 1920
      // default: 2040
    },
    // 需要适配的屏幕高度
    height: {
      type: Number,
      default: 1080
      // default: 2448
    }
  },
  data() {
    return {
      contentHeight: '', // 大屏内容的高度
    };
  },
  watch:{
    width(curVal,oldVal){
      if(curVal){
        this.width = curVal
        // this.resizeObserver()
      }
    },
    height(curVal,oldVal){
      if(curVal){
        this.height = curVal
        // this.resizeObserver()
      }
    }
  },
  methods: {
    moveEvent(i, newX, newY) {
      this.$emit('move', i, newX, newY)
    },
    movedEvent(i, newX, newY) {
      this.$emit('moved', i, newX, newY)
    },
    // 点击选择某个子元素
    selectGridItem(i) {
      if (this.mode === 'view') {
        return
      }
      this.$emit('select', i)
    },
    // 移除元素
    removeItem(i) {
      this.$emit('remove', i)
    },
    // 监听内容宽度后改变高度，以保证内容的宽高比
    resizeObserver() {
      const ro = new ResizeObserver(_.throttle((entries, observer) => {
        for (const entry of entries) {
          const {left, top, width, height} = entry.contentRect;
          // console.log('Element:', entry.target);
          console.log(`Element's size: ${ width }px x ${ height }px`);
          // console.log(`Element's paddings: ${ top }px ; ${ left }px`);
          this.$store.commit('app/SET_WIDTH', width)
          this.contentHeight = width * this.height / this.width
          this.$nextTick(() => {
            // this.$refs.borderBoxRef?.initWH()
          })
        }
      }, 300));
      ro.observe(this.$refs.bigDataScreenContentRef);
    },
    classNames
  },
  mounted() {
    this.resizeObserver()
    console.log("backgroundColor",this.backgroundColor)
  },
  destroyed() {

  }
}
</script>

<style lang="scss" scoped>
.main {
  border: 1px #000 solid;
  width: 100%;

  .vue-grid-layout {

  }


  .vue-grid-item {
    overflow: hidden;
  }

  .vue-grid-item__edit {
    border: 1px dashed #eee;
  }

  .vue-grid-item__selected {
    border: 2px dashed #eee;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    overflow: hidden;
  }

  .remove {
    position: absolute;
    right: 2px;
    top: 0;
    cursor: pointer;
    z-index: 9999;
  }
}
</style>
