var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fullscreen",
    {
      attrs: { fullscreen: _vm.fullscreen },
      on: {
        "update:fullscreen": function ($event) {
          _vm.fullscreen = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "screen2" },
        [
          _c(
            "el-container",
            [
              _c("el-header", [
                _c("div", { staticClass: "mainTitle" }, [
                  _c(
                    "div",
                    {
                      staticClass: "top",
                      on: {
                        click: function ($event) {
                          return _vm.toggle()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.screenTitle))]
                  ),
                ]),
              ]),
              _c(
                "el-main",
                { staticStyle: { "padding-top": "43px" } },
                [
                  _c("w-clock", { staticClass: "clock" }),
                  _c("w-weather", { staticClass: "weather" }),
                  _c(
                    "el-row",
                    { attrs: { gutter: 15 } },
                    [
                      _c(
                        "el-col",
                        { staticClass: "left", attrs: { span: 6 } },
                        [
                          _c("track-image", {
                            attrs: {
                              heightTrackImage: "35.5vh",
                              backgroundTrackImage: "index/赛事路线1.png",
                            },
                          }),
                          _c("over-game", {
                            attrs: {
                              heightOverGame: "53vh",
                              heightTbody: "93%",
                              backgroundOverGame: "index/恭喜完赛1.png",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticClass: "center", attrs: { span: 12 } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "text-align": "center",
                                position: "absolute",
                                top: "6px",
                                width: "100%",
                              },
                            },
                            [
                              _c("div", { staticClass: "p_title" }, [
                                _c("span", [
                                  _vm._v(" " + _vm._s(_vm.ptitle) + " "),
                                ]),
                              ]),
                            ]
                          ),
                          _c("current-match", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.ptitle === "赛事排名",
                                expression: "ptitle === '赛事排名'",
                              },
                            ],
                            attrs: {
                              marqueeIndex: "1",
                              heightCurrentMatch: "81vh",
                            },
                          }),
                          _c("personal-match", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.ptitle === "个人赛事",
                                expression: "ptitle === '个人赛事'",
                              },
                            ],
                            attrs: {
                              marqueeIndex: "1",
                              heightCurrentMatch: "81vh",
                            },
                            on: {
                              timeTitle: _vm.getTitle,
                              changePage: _vm.changePage,
                            },
                          }),
                        ],
                        1
                      ),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "right" },
                          [
                            _c(
                              "div",
                              { staticClass: "matchVideo" },
                              [_c("big-screen-view")],
                              1
                            ),
                            _c("recent-match", {
                              attrs: {
                                heightRecentMatch: "33.6vh",
                                backgroundRecentMatch: "index/最近赛事1.png",
                              },
                            }),
                            _c("total-sessions", {
                              attrs: {
                                heightTotalSessions: "23vh",
                                backgroundTotalSessions: "index/赛事数据1.png",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }