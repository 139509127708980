import request from '@/utils/request'
// 查询绿道列表
// export function listTrack(query) {
//     return request({
//       url: '/greenway/track/list',
//       // method: 'get',
//       method: 'post',
//       data: query,
//     })
//   }

// 查询绿道列表
export function listTrack(query) {
  return request({
    url: '/greenway/track/list',
    // method: 'get',
    method: 'post',

    params: {
      pageNum: query.pageNum,
      pageSize: query.pageSize,
    },
    data: query,
  })
}
  // 绑定绿道
  export function bindTrack(query) {
    return request({
      url: '/rc/greenway_device',
      method: 'post',
      data: query,
    })
  }
   // 解绑绿道
   export function removeBindGreenway(query) {
    return request({
      url: '/rc/greenway_device/removeBindGreenway',
      method: 'delete',
      data: query,
    })
  }
// 查询可绑定设备的绿道列表
export function listByDevice(query) {
  return request({
    url: '/greenway/track/listByDevice',
    method: 'post',
    params: {
      pageNum: query.pageNum,
      pageSize: query.pageSize,
    },
    data: query,
  })
}