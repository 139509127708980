var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: {
        color: _vm.color,
        fontSize: _vm.size + "px",
        textAlign: _vm.textAlign,
      },
    },
    [_vm._v(" " + _vm._s(_vm.time) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }