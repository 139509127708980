import request from '@/utils/request'

//获取办赛需求赛事合同方案详细信息
export function getContractInfo(params) {
  return request({
    url: '/emd/config/getInfo/' + params,
    method: 'get',
  })
}

//新增或修改办赛需求赛事合同方案
export function addOrUpdate(data) {
  return request({
    url: '/emd/config/saveOrUpdateEmdContractInvoiceConfig',
    method: 'POST',
    data: data
  })
}


//下载企业发票信息模板
export function  handleTeamTemplate() {
  return request({
    url: '/emd/config/handleTeamTemplate',
    method: 'get',
  })
}

//下载个人发票信息模板
export function  handleTeamTemplateThen() {
  return request({
    url: '/emd/config/handleTeamTemplateThen',
    method: 'get',
  })
}



//获取上传的合同发票信息
export function  getUploadFileData() {
  return request({
    url: '/emd/config/getUploadFileData',
    method: 'get',
  })
}

