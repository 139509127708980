import request from '@/utils/request'

// 获取节目单列表
export function getPlayList(query) {
  return request({
    url: '/ad/ad_list/list',
    method: 'get',
    params: query
  })
}

// 获取节目单详情
export function getPlayListDetail(playlistId) {
  return request({
    url: '/ad/ad_list/' + playlistId,
    method: 'get'
  })
}

// 新增、复制节目单
export function addOrCopyPlayList(params,data) {
  return request({
    url: '/ad/ad_list/add_ad_list/',
    method: 'post',
    data: data,
    params: params
  })
}

// 编辑节目单
export function updatePlayList(params,data) {
  return request({
    url: '/ad/ad_list',
    method: 'put',
    data: data,
    params: params
  })
}

// 判断节目单是否可删除
export function isRemovablePlaylist(data) {
  return request({
    url: '/ad/ad_list/is_ad_list_removable',
    method: 'get',
    params: data
  })
}

// 删除节目单
export function deletePlayList(adListIds) {
  return request({
    url: '/ad/ad_list/' + adListIds,
    method: 'delete'
  })
}

// 预览节目单
export function previewPlayList(data) {
  return request({
    url: '/ad/ad_list/',
    method: 'post',
    data: data
  })
}

// 批量移动节目单
export function moveTo(playlistIds,new_parentId) {
  return request({
    url: '/ad/ad_list/move_playlists_to',
    method: 'put',
    params:{
      playlistIds:playlistIds,
      new_parentId:new_parentId,
    }
  })
}
