<template>
  <div
    :class="{ container: true, marquee1: index === 1, marquee2: index === 2 }"
    :style="{ height: height }"
  >
    <div class="p_title" v-show="index === 2">
      <span>个人赛事</span>
    </div>
    <div class="personalMatch">
      <el-row class="information" :gutter="10">
        <el-col :span="5">
          <el-avatar
            shape="square"
            :size="130"
            :src="personalData.avatar"
          ></el-avatar>
        </el-col>
        <el-col :span="8" style="padding: 10px">
          <div class="userName scollData">{{ personalData.userName }}</div>
          <div class="userName">
            累计参赛：{{ personalData.totalEntries }}场
          </div>
        </el-col>
        <el-col :span="4" style="position: relative">
          <img
            src="@/assets/images/编组15@2x.png"
            width="90"
            style="margin: 20px"
            alt=""
          />
          <!--<img src="@/assets/images/编组16@2x.png" width="90" style="margin:30px" alt="">
          <img src="@/assets/images/编组17.png" width="90" style="margin:30px" alt=""> -->
          <!-- <img src="@/assets/images/编组17@2x.png" width="90" style="margin:30px" alt=""> -->
        </el-col>
        <el-col :span="7"
          ><div style="line-height: 130px; font-size: 76px; margin-left: 20px">
            NO.{{ personalData.rankingValue }}
          </div></el-col
        >
      </el-row>
      <div class="match" style="line-height: 10px">
        <p>赛事项目：{{ personalData.matchName }}</p>
        <p>
          赛事状态：<el-button
            type="primary"
            style="font-size: 20px; padding: 5px 20px; color: #a5fffd"
            >{{ personalData.matchType }}</el-button
          >
        </p>
        <p>参赛时间：{{ personalData.timeOfEntry }}</p>
      </div>
      <div class="data">
        <div class="ones">
          <div v-show="personalData.matchMileage">
            {{ personalData.matchMileage }}
          </div>
          <div v-show="!personalData.matchMileage">123</div>
          <div>赛事里程</div>
        </div>
        <div class="ones">
          <div v-show="personalData.finishTime">
            {{ personalData.finishTime }}
          </div>
          <div v-show="!personalData.finishTime">123</div>
          <div>完赛时长</div>
        </div>
        <div class="ones">
          <div v-show="personalData.punchInStatistics">
            {{ personalData.punchInStatistics }}
          </div>
          <div v-show="!personalData.punchInStatistics">123</div>
          <div>打卡统计</div>
        </div>
        <div class="ones">
          <div v-show="personalData.averagePace">
            {{ personalData.averagePace }}
          </div>
          <div v-show="!personalData.averagePace">123</div>
          <div>平均配速(KM/H)</div>
        </div>
        <div class="ones">
          <div class="bigData" v-show="personalData.fastestPace">
            {{ personalData.fastestPace }}
          </div>
          <div v-show="!personalData.fastestPace">123</div>
          <div>最快配送(KM/H)</div>
        </div>
        <div class="ones">
          <div v-show="personalData.consumptionOfEnergy">
            {{ personalData.consumptionOfEnergy }}
          </div>
          <div v-show="!personalData.consumptionOfEnergy">123</div>
          <div>千卡(kcal)</div>
        </div>
      </div>
      <div class="time">
        <p>{{ time }}s后返回赛事排名</p>
      </div>
    </div>
  </div>
</template>
<script>
import { getPersonalMatch } from "@/api/device/screen-preview/screen-preview";
export default {
  name: "PersonalMatch",
  props: ["heightCurrentMatch", "backgroundCurrentMatch", "marqueeIndex"],
  components: {},
  computed: {},
  data() {
    return {
      personalData: {},
      height: this.heightCurrentMatch,
      // background:require('@/assets/images/'+this.backgroundCurrentMatch),
      trackImageUrl: "",
      deviceCode: "",
      headerStatus: false,
      time: 59,
      index: null,
      interval: null,
    };
  },
  created() {
    this.getDeviceCode();
    if (this.$route.query.deviceCode) {
      this.deviceCode = this.$route.query.deviceCode;
    }
  },
  watch: {
    marqueeIndex: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue == 1) {
          this.index = 1;
        } else {
          this.index = 2;
        }
      },
    },
  },
  mounted() {
    // this.$nextTick(()=>{
    //   this.toPersonal()
    // })
    //刷卡进入个人赛事
    this.$bridge.registerHandler("toPersonal", this.toPersonal);
  },
  methods: {
    //跳转进入个人赛事
    toPersonal(data, responseCallback) {
      // getPersonalMatch({deviceCode:'MatchScreen-Test-02',rfidEpcNo:'000000000002022083000332'}).then((response) => {
      getPersonalMatch({
        deviceCode: this.deviceCode,
        rfidEpcNo: data.rfidEpcNo,
      }).then((response) => {
        if (response.code === 200) {
          this.personalData = response.data;
          //开始定时
          this.timeset();
          //切换页面
          this.$emit("changePage", "个人赛事");
        }
      });
      responseCallback("大屏-刷卡，执行成功");
    },
    //60s定时
    timeset() {
      let me = this;
      me.isDisabled = true;
      me.interval = window.setInterval(function () {
        --me.time;
        if (me.time < 1) {
          window.clearInterval(me.interval);
          me.interval = null;
          //60结束 跳转回赛事排名页面
          me.$emit("timeTitle", "赛事排名");
        }
      }, 1000);
    },
    // 查询设备编号
    getDeviceCode() {
      this.$bridge.callHandler("searchDeviceCode", "", (data) => {
        var settings = JSON.parse(data);
        this.deviceCode = settings.deviceCode;

        if (this.deviceCode) {
        }
      });
    },
  },
  beforeDestroy() {
    // 组件销毁前清除定时器
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
};
</script>

<style lang="scss" scoped>
.scollData {
  overflow: hidden;
  text-overflow: ellipsis; //多出部分以省略号形式隐藏
  white-space: nowrap;
  width: 100%; /* 禁止换行  normal可以换行 */
}
.marquee2 {
  background: url(../../../../assets/images/datascreen/图层3@2x.png) no-repeat 0
    0;
  background-size: 100% 100%;
  padding: 7vh 1.5vw 2vh 2vw;
  .personalMatch {
    height: 54vh;
    .ones {
      > div:first-child,
      > div:nth-child(2) {
        font-size: 40px;
      }
    }
  }
  .p_title {
    padding-top: 3px;
  }
}
.marquee1 {
  padding: 1vh 1.5vw 2vh 2vw;
  height: 100%;
  .ones {
    padding: 4vh 1vw;
    > div:first-child,
    > div:nth-child(2) {
      font-size: 40px;
      margin-bottom: 4%;
    }
  }
}
.container {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  font-size: 18px;
  //background: #585925;
  //弹框样式
  //height: 100vh;
  //width:100vw;
  .personalMatch {
    color: #a5fffd;
    .information {
      width: 45vw;
    }
    .userName {
      line-height: 55px;
    }
    //弹框样式
    //position: absolute;
    //left:26%;
    //top:14vh;
    //width:48%;
    //height:84%;
    //padding:2vh 2.5vw;
    .data {
      width: 100%;
      height: 37%;
      .ones {
        width: 33%;
        height: 50%;
        box-sizing: border-box;
        text-align: center;
        float: left;
        overflow: hidden;
        //margin-top: 3%;
        > div:nth-child(2) {
          color: transparent;
        }
      }
    }
    .time {
      clear: both;
      padding-top: 4%;
      text-align: center;
    }
  }
}
</style>
