import request from '@/utils/request'
import {console} from "vuedraggable/src/util/helper";

// 退款审核
export function refundApproval(data) {
  return request({
    url: '/order/orderCommon/refundApproval',
    method: 'post',
    data: data
  })
}
