<template>
  <el-form>
    <el-form-item
      label="格式："
    >
      <el-input
        :value="currentEditItem.children.props.format"
        @input="(value)=>changeProps('format', value)"
        style="width: 100%"
      ></el-input>
    </el-form-item>
    <el-form-item
      label="字体大小："
    >
      <el-input-number
        :value="currentEditItem.children.props.fontSize"
        @change="(value)=>changeProps('fontSize', value)"
        style="width: 100%"
        :min="1"
        :max="1000"
      ></el-input-number>
    </el-form-item>

    <el-form-item
      label="对齐：">
      <el-radio-group
        v-model="currentEditItem.children.props.textAlign"
        @change="(value)=>changeProps('textAlign', value)"
      >
        <el-radio label="left">左</el-radio>
        <el-radio label="center">居中</el-radio>
        <el-radio label="right">右</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="颜色">
      <el-color-picker
        :value="currentEditItem.children.props.color"
        @change="(value)=>changeProps('color', value)"
        show-alpha
        :predefine="predefineColors">
      </el-color-picker>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: "w-clock-config",
  components: {},
  props: {
    currentEditItem: {
      type: Object
    }
  },
  data() {
    return {
      predefineColors: [
        '#282c34',
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        'rgba(255, 69, 0, 0.68)',
        'rgb(255, 120, 0)',
        'hsv(51, 100, 98)',
        'hsva(120, 40, 94, 0.5)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
      ],
    };
  },
  methods: {
    changeProps(key, value) {
      this.$emit('change', {key,value})
    }
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
