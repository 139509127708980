import {mqttClient, emitter} from '@/main'

export const mqttTopic = {
  push_to_device_ad:  "_push_to_device_ad", // 数据大屏广告topic
  finish_match_play_info: "_finish_match_play_info",// 数据大屏完赛topic
  push_to_device_screen_page: "_push_to_device_screen_page"// 数据大屏切换页面类型topic
}
function subscribe(topic){
  mqttClient.subscribe(topic, {qos: 2},(err)=>{
    if(!err){
      console.log(`订阅主题 ${topic} 成功`)
      // 避免多次执行 on('message')
    }
  })
}

// 监听订阅的topic消息
export function onMqttMessage({topic}) {
  // 由于mqttClient是异步创建的，所以定时监测是否已创建
  const intervalId = setInterval(()=>{
    // console.log(mqttClient)
    if(mqttClient){
      clearInterval(intervalId)
    }else {
      return
    }
    // 已建立mqtt链接
    if(mqttClient.connected) {
      subscribe(topic)
    }else {
      // 如果还没有连接成功，就监听连接，确保订阅一定能成功
      console.log('mqtt正在建立连接中...')
      mqttClient.on('connect', () => {
        console.log(`mqtt：${process.env.VUE_APP_MQTT}  连接成功...`)
        // 连接成功之后再订阅
        subscribe(topic)
      })
    }
  }, 10)
}

// 取消订阅
export function unsubscribe(topic){
  emitter.off(topic)
  mqttClient.unsubscribe(topic, {}, (err)=>{
    if(!err){
      console.log(`取消订阅${topic}成功`)
    }
  })
}
