var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bigScreen" }, [
    _c(
      "div",
      { staticClass: "p_video" },
      [
        _c(
          "el-carousel",
          {
            ref: "carousel",
            staticClass: "imgClass",
            attrs: { autoplay: false, arrow: "never" },
            on: { change: _vm.carouselChange },
          },
          _vm._l(_vm.advertisementList, function (item, index) {
            return _c("el-carousel-item", { key: item.playbillId }, [
              _vm.currentPosition === index
                ? _c(
                    "div",
                    { staticClass: "fill" },
                    [
                      item.mediaFileType === "1000" || !item.mediaFileType
                        ? _c("img", {
                            attrs: {
                              width: "100%",
                              height: "100%",
                              src: item.url,
                            },
                          })
                        : _vm._e(),
                      item.mediaFileType === "1001"
                        ? _c("video", {
                            ref: "videoAd",
                            refInFor: true,
                            staticClass: "videoStyle",
                            attrs: {
                              src: item.url,
                              autoplay: "autoplay",
                              loop: _vm.videoLoop,
                              type: "video/mp4",
                            },
                            domProps: { muted: false },
                            on: { pause: _vm.videoPause },
                          })
                        : _vm._e(),
                      item.mediaFileType === "1002"
                        ? _c("big-data-screen", {
                            ref: "bigDataScreen",
                            refInFor: true,
                            attrs: {
                              mode: _vm.mode,
                              layout: _vm.custom.layout,
                              borderBox: _vm.borderBox,
                              title: _vm.title,
                              backgroundColor: _vm.custom.backgroundColor,
                              width: _vm.custom.width,
                              height: _vm.custom.height,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }