<template>
  <div>
    <el-dialog
      title="可绑定媒体资源"
      :visible.sync="MediaVisible"
      width="1000px"
      height="900px"
      append-to-body
    >
      <el-table
        v-loading="loading"
        :data="toMediaList"
        border
        @current-change="handleCurrentChange"
      >
        <el-table-column key="1" width="35">
          <template slot-scope="scope">
            <el-radio :label="scope.row" v-model="mediaRow">{{ "" }}</el-radio>
          </template>
        </el-table-column>
        <!--<el-table-column type="selection" width="55" align="center" />-->
        <el-table-column label="媒体名称" align="center" prop="mediaName" />
        <el-table-column label="媒体类型" align="center" prop="mediaFileType">
          <template slot-scope="scope">
            <dict-tag
              :options="dict.type.play_file_type"
              :value="scope.row.mediaFileType"
            />
          </template>
        </el-table-column>
        <el-table-column label="在线播放地址" align="center" prop="url">
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.mediaFileType === '1000'"
              style="width: 50px; height: 50px; cursor: pointer"
              :src="API + scope.row.url"
              :preview-src-list="[API + scope.row.url]"
            >
            </el-image>
            <span
              v-else
              style="color: #1890ff; cursor: pointer"
              @click="previewVideo(scope.row)"
            >视频预览</span
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="total3 > 0"
        :total="total3"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getToMediaList"
      />
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitMedia"
        >确 定</el-button
        >
        <el-button @click="cancelRestock2">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 视频预览 -->
    <el-dialog
      title="视频预览"
      :visible.sync="videoVisible"
      width="800px"
      @close="cancelVideo"
    >
      <div class="video">
        <video
          width="100%"
          height="100%"
          :src="API + videoUrl"
          autoplay
          controls
        ></video>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelVideo">取 消</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import { getAddMedia, getToMedia } from '@/api/device/program-guides/program-guides'
import {listMedia} from "@/api/device/mediao/mediao";
export default {
  name: 'media-resources',
  dicts: ["play_file_type"],
  props: {
    mediaFileType: {
      type: String,
    },
  },
  data(){
    return{
      MediaVisible: false,
      // 遮罩层
      loading: true,
      toMediaList: [],
      total3: 0,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        mediaFileType:null,
        auditStatus:'1',
      },
      mediaCodes: [],
      // 选中行
      mediaRow:null,
      // 代理服务器地址
      API: process.env.VUE_APP_BASE_API,
      //选中的视频url
      videoUrl: "",
      //是否预览视频
      videoVisible: false,
    }
  },
  created() {
    if(this.mediaFileType){
      this.queryParams.mediaFileType = this.mediaFileType
    }

  },
  mounted() {
    this.getToMediaList()
  },
  methods:{
    //多选资源媒体获取数组
    handleCurrentChange(val) {
      // 否则未选中号码类型会报错
      if (val) {
        this.mediaRow = val;
      }
    },
    /** 查询媒体资源列表 */
    getToMediaList() {
      this.loading = true;
      listMedia(this.queryParams).then((response) => {
        this.toMediaList = response.rows;
        this.total3 = response.total;
        this.loading = false;
      });
    },
    //批量新增绑定的媒体资源
    submitMedia() {
      this.$emit("submit",this.mediaRow);
      this.MediaVisible = false;
    },
    // 取消对话框
    cancelRestock2() {
      this.MediaVisible = false;
    },
    //打开视频预览弹框
    previewVideo(row) {
      this.videoUrl = row.url;
      this.videoVisible = true;
    },
    //关闭视频预览弹框
    cancelVideo() {
      this.videoUrl = "";
      this.videoVisible = false;
    },
  },
}
</script>

<style scoped>

</style>
