<template>
  <div>
    <el-form>
      <el-form-item
        label="城市：">
<!--        <input type="text" v-model="city">-->
<!--        <button @click.prevent="searchWeather">提交</button>-->
        <el-input placeholder="请输入内容" v-model="city">
          <el-button slot="append" icon="el-icon-search" @click.prevent="searchWeather"></el-button>
        </el-input>
      </el-form-item>


      <el-form-item
        label="字体大小："
      >
        <el-input-number
          :value="currentEditItem.children.props.fontSize"
          @change="(value)=>changeProps('fontSize', value)"
          style="width: 100%"
          :min="1"
          :max="1000"
        ></el-input-number>
      </el-form-item>

      <el-form-item
        label="对齐：">
        <el-radio-group
          v-model="currentEditItem.children.props.textAlign"
          @change="(value)=>changeProps('textAlign', value)"
        >
          <el-radio label="left">左</el-radio>
          <el-radio label="center">居中</el-radio>
          <el-radio label="right">右</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="颜色">
        <el-color-picker
          :value="currentEditItem.children.props.color"
          @change="(value)=>changeProps('color', value)"
          show-alpha
          :predefine="predefineColors">
        </el-color-picker>
      </el-form-item>

    </el-form>
  </div>
</template>

<script>
export default {
  name: "w-weather-config",
  components: {},
  props: {
    currentEditItem: {
      type: Object
    },
  },
  data() {
    return {
      city:this.currentEditItem.children.props.city,
      predefineColors: [
        '#282c34',
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        'rgba(255, 69, 0, 0.68)',
        'rgb(255, 120, 0)',
        'hsv(51, 100, 98)',
        'hsva(120, 40, 94, 0.5)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
      ],
    };
  },
  methods: {
    changeProps(key, value) {
      this.$emit('change', {key,value})
    },

    async searchWeather(){
      let key = 'e2138a2ae39242c898493885eb195048';
      //获取城市的ID
      let httpUrl = `https://geoapi.qweather.com/v2/city/lookup?location=${this.city}&key=${key}`
      let res = await fetch(httpUrl)
      let result = await res.json()
      let id = result.location[0].id
      //根据城市id获取具体的天气
      let httpUrl1 = `https://devapi.qweather.com/v7/weather/now?location=${id}&key=${key}`
      let res1 = await fetch(httpUrl1)
      let result1 = await res1.json()
      let now = result1.now
      // this.tmpNew =now.temp
      // this.briefNew = now.text
      // this.winIcon = now.icon
      console.log("now",now)
      console.log("now.icon",now.icon)

      this.changeProps('tmpNew', now.temp)
      this.changeProps('briefNew', now.text)
      this.changeProps('nowIcon', now.icon)
    },

  },
  mounted() {
    this.searchWeather()
  }
}
</script>

<style scoped>

</style>
