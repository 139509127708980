<template>
  <div style="position: fixed; bottom: 50px; left: 20px;z-index: 9999;" @click="reloadPage">版本时间：{{ vTime }}</div>
</template>

<script>
import {parseTime} from "@/utils/ruoyi";

export default {
  name: "VersionTime",
  components: {},
  props: {},
  data() {
    return {
      vTime: parseTime(window.versionTime),
    };
  },
  methods: {
    reloadPage(){
      window.location.reload()
    }
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
