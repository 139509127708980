import request from '@/utils/request'

// 查询APP版本信息列表
export function listAppVersion(query) {
  return request({
    url: '/system/appVersion/list',
    method: 'get',
    params: query
  })
}

// 查询APP版本信息详细
export function getAppVersion(versionId) {
  return request({
    url: '/system/appVersion/' + versionId,
    method: 'get'
  })
}

// 新增APP版本信息
export function addAppVersion(data) {
  return request({
    url: '/system/appVersion',
    method: 'post',
    data: data
  })
}

// 修改APP版本信息
export function updateAppVersion(data) {
  return request({
    url: '/system/appVersion',
    method: 'put',
    data: data
  })
}

// 删除APP版本信息
export function delAppVersion(versionId) {
  return request({
    url: '/system/appVersion/' + versionId,
    method: 'delete'
  })
}

// 导出APP版本信息
export function exportAppVersion(query) {
  return request({
    url: '/system/appVersion/export',
    method: 'get',
    params: query
  })
}

//上架APP版本信息
export function releaseVersion(versionId) {
  return request({
    url: '/system/appVersion/releaseVersion/' + versionId,
    method: 'put'
  })
}

//下架APP版本信息
export function underVersion(versionId) {
  return request({
    url: '/system/appVersion/underVersion/' + versionId,
    method: 'put'
  })
}
