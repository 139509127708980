import request from "@/utils/request";

//查询绿道列表
export function greenWaysList() {
  return request({
    url: "/ad/ad_schedule/list_greenways",
    method: "get",
  });
}
//按绿道+分辨率查设备列表
export function getDeviceByGwOrDt(params) {
  return request({
    url: "/ad/ad_schedule/list_devices_by_gw_and_resolution",
    method: "get",
    params,
  });
}
//查询当前广告日程被占用详情
export function listAdScheduleInUse(data) {
  return request({
    url: "/ad/ad_schedule/list_ad_schedule_in_use",
    method: "post",
    data,
  });
}
// 新增广告日程
export function addAdSchedule(data) {
  return request({
    url: "/ad/ad_schedule/add_ad_schedule",
    method: "post",
    data,
  });
}
// 查询设备类型列表
export function getDeviceTypes() {
  return request({
    url: "/ad/ad_schedule/list_device_types",
    method: "get",
  });
}
// 按月查询广告发布列表
export function getScheduleByMonth(params) {
  return request({
    url: "/ad/ad_schedule/list_ad_schedule_by_month",
    method: "get",
    params,
  });
}
//按周或日查询广告发布列表
export function getScheduleByWeekOrDay(params) {
  return request({
    url: "/ad/ad_schedule/list_ad_schedule_by_week_or_day",
    method: "get",
    params,
  });
}
// 新增广告发布
export function addSchedule(data) {
  return request({
    url: "/ad/ad_schedule/add_ad_schedule",
    method: "put",
    data,
  });
}

//批量删除节目单
export function deletePlaylist(data) {
  return request({
    url: "/ad/ad_schedule/delete_playlist",
    method: "delete",
    data,
  });
}