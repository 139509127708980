import request from '@/utils/request'

// 新查询流量统计ECharts
export function TrafficStatisticsList(query) {
  return request({
    url: '/dataManagement/TrafficStatistics/newList',
    method: 'get',
    params: query
  })
}

// 新查询流量统计表格列表
export function newTrafficStatisticsList(query) {
  return request({
    url: '/dataManagement/TrafficStatistics/newTrafficStatisticsList',
    method: 'get',
    params: query
  })
}
// 查询省市区接口
export function getTrackAddressList(query) {
  return request({
    url: '/greenway/track/getTrackAddressList',
    method: 'get',
    params: query
  })
}