import request from '@/utils/request'

// 查询运营赛事奖章列表
export function listMedal(query) {
  return request({
    url: '/operate/medal/list',
    method: 'get',
    params: query
  })
}

// 查询运营赛事奖章详细
export function getMedal(medalId) {
  return request({
    url: '/operate/medal/' + medalId,
    method: 'get'
  })
}

// 新增运营赛事奖章
export function addMedal(data) {
  return request({
    url: '/operate/medal',
    method: 'post',
    data: data
  })
}

// 修改运营赛事奖章
export function updateMedal(data) {
  return request({
    url: '/operate/medal/update',
    method: 'post',
    data: data
  })
}

// 删除运营赛事奖章
export function delMedal(medalId) {
  return request({
    url: '/operate/medal/delete/' + medalId,
    method: 'post'
  })
}
