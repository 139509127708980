<template>
  <div class="welcomeInfo" v-if="loginUserInfo">
    <el-tooltip :disabled="showGreenway" class="item" effect="dark" placement="bottom">
        <template slot="content" >
          <div v-html="allGreenwayInfoTitle"></div>
        </template>
      <div v-html="title"></div>
    </el-tooltip>
  </div>
</template>

<script>
import {getWelcomeInfo} from "@/api/system/agent";
export default {
  data() {
    return {
      loginUserInfo: "",
      title: "",
      allGreenwayInfoTitle : "",
      showGreenway:true,
    };
  },
  created() {

  },
  async mounted() {
    this.loginUserInfo = this.$store.state.user.loginUserInfo;
    let greenwayInfo = '';
    let greenwayAllInfo = '';
    let welcomeInfo = '';
    const flag = await getWelcomeInfo(this.loginUserInfo.userId).then(res => {
        if (res.data.userType === '00'){
          welcomeInfo = res.data.deptName;
        }else if (res.data.userType === '05'){
          welcomeInfo = res.data.customerName;
        }else {
          welcomeInfo = res.data.deptName;
          greenwayAllInfo = res.data.greenwayNames;
          let temp = res.data.greenwayNames;
          let end = this.findSecondOccurrence(temp,'、');
          if (temp.indexOf("、") === -1){
            greenwayInfo = temp;
          }else if (end === -1){
            greenwayInfo = temp.substring(0,temp.indexOf('、',0)) + '等'+res.data.greenwayCount+'个赛道';
          }else {
            greenwayInfo = temp.substring(0,end) + '等'+res.data.greenwayCount+'个赛道';
          }
        }
    });

    this.title = `<span style="font-weight:bold;color:var(--theme-color)">欢迎，${this.loginUserInfo.userName}</span>
                    <span style="margin-left:15px">( ${welcomeInfo} )</span>`

    if(greenwayInfo.length>0){
      this.title += `<span style="margin-left:15px">拥有赛道: ${greenwayInfo}</span>`;
      this.allGreenwayInfoTitle = `<span style="margin-left:15px">拥有赛道: ${greenwayAllInfo}</span>`;
      this.showGreenway = false;
    }
  },
  methods: {
    findSecondOccurrence(str, char) {
      // 查找第一次出现的位置
      var indexFirst = str.indexOf(char);
      // 如果字符不存在，返回-1
      if (indexFirst === -1) {
        return -1;
      }
      // 查找第二次出现的位置，从第一次出现位置的下一个字符开始
      var indexSecond = str.indexOf(char, indexFirst + 1);
      // 返回第二次出现的位置，如果没有找到则返回-1
      return indexSecond === -1 ? -1 : indexSecond;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/themePublicStyle.scss";
.welcomeInfo {
  margin-right: 15px;
  max-width: 5000px;
  @extend %overflowTest;
}
</style>
