import WImg from "@/views/device/widget/img/w-img";
import WImgConfig from "@/views/device/widget/img/w-img-config";

export const imgConfig = {
  "x": null,
  "y": null,
  "w": 10,
  "h": 10,
  "i": null,
  label: '图片',
  icon: 'el-icon-picture',
  children: {
    component: 'w-img',//WImg.name,
    componentConfig: 'w-img-config',//WImgConfig.name,
    props: {
      // value: 'https://img.zcool.cn/community/0186025d143ecaa8012051cd9c2eb7.jpg@1280w_1l_2o_100sh.jpg',
      value: '',
      objectFit: 'cover',
      mediaName:'',
    },
  },
  selected: false
}
