<template>
  <div class="overGame" :style="{height:height,backgroundImage:'url('+ background +')'}">
    <div class="p_title"><span>恭喜完赛({{listComplete.length}})</span></div>
    <div class="marquee">
      <el-row class="title">
        <el-col :span="10" style="text-align: center;width:42%">
          用户名
        </el-col>
        <el-col :span="4" style="text-align: center;width:16%">
          配速
        </el-col>
        <el-col :span="10" style="text-align: center;width:42%">
          用时
        </el-col>
      </el-row>
      <div class="tbody" :style="{height:heightTbody}">
        <vue-seamless-scroll :class-option="classOption" :data="listComplete" ref="seamlessScrollOver">
          <el-row v-for="(item,index) in listComplete" :key="`${item.matchId}_${item.userId}_${item.playRecordId}`">
            <el-col class="scollData" style="text-align: center;width:42%">
              {{item.userName}}
            </el-col>
            <el-col style="text-align: center;width:16%">
              {{item.pace}}
            </el-col>
            <el-col style="text-align: center;width:42%">
              {{item.costTime}}
            </el-col>
          </el-row>
        </vue-seamless-scroll>
      </div>

    </div>
    <div class="marqueeCover"></div>
  </div>
</template>
<script>
import {overGameDevice} from "@/api/device/screen-preview/screen-preview";
import {onMqttMessage, mqttTopic, unsubscribe} from "@/utils/mqtt";
import {updatePage} from "@/api/device/data-screen/data-screen";
import vueSeamlessScroll from 'vue-seamless-scroll'

var source = null;
export default {
  name: "OverGame",
  props:['heightOverGame','backgroundOverGame','heightTbody'],
  components: {
    vueSeamlessScroll
  },
  computed: {

  },
  data() {
    return {
      classOption:{
        limitMoveNum:10,
        hoverStop: false,
      },
      // listComplete: [],
      listComplete: [
        // {userName:'1无完赛者完',pace:'0.456',costTime:'00:00:000',name:'内部联谊赛'},
        // {userName:'2无完赛者完赛者',pace:'0.456',costTime:'00:00:000',name:'公开赛'},
        // {userName:'3完赛者完赛者',pace:'0.456',costTime:'00:00:000',name:'公开赛'},
        // {userName:'4完赛者完赛者',pace:'0.456',costTime:'00:00:000',name:'内部联谊赛'},
        // {userName:'5完赛者完',pace:'0.456',costTime:'00:00:000',name:'内部联谊赛'},
        // {userName:'6完赛者完赛者',pace:'0.456',costTime:'00:00:000',name:'公开赛'},
        // {userName:'7完赛者完赛者',pace:'0.456',costTime:'00:00:000',name:'公开赛'},
        // {userName:'8完赛者完赛者',pace:'0.456',costTime:'00:00:000',name:'内部联谊赛'},
        // {userName:'9完赛者完',pace:'0.456',costTime:'00:00:000',name:'内部联谊赛'},
        // {userName:'10完赛者完赛者',pace:'0.456',costTime:'00:00:000',name:'公开赛'},
        // {userName:'11完赛者完',pace:'0.456',costTime:'00:00:000',name:'内部联谊赛'},
        // {userName:'12完赛者完赛者',pace:'0.456',costTime:'00:00:000',name:'公开赛'},
        // {userName:'13完赛者完赛者',pace:'0.456',costTime:'00:00:000',name:'公开赛'},
        // {userName:'14完赛者完赛者',pace:'0.456',costTime:'00:00:000',name:'内部联谊赛'},
        // {userName:'15完赛者完',pace:'0.456',costTime:'00:00:000',name:'内部联谊赛'},
        // {userName:'16完赛者完赛者',pace:'0.456',costTime:'00:00:000',name:'公开赛'},
        // {userName:'17完赛者完赛者',pace:'0.456',costTime:'00:00:000',name:'公开赛'},
        // {userName:'18完赛者完赛者',pace:'0.456',costTime:'00:00:000',name:'内部联谊赛'},
        // {userName:'19完赛者完',pace:'0.456',costTime:'00:00:000',name:'内部联谊赛'},
        // {userName:'20完赛者完赛者',pace:'0.456',costTime:'00:00:000',name:'公开赛'},
      ],
      deviceCode: "",
      headerStatus: true,
      height:this.heightOverGame,
      background:require('@/assets/images/'+this.backgroundOverGame),
      timer: null,
      pageType:null,
      //mqtt定时
      timeHandle:null,

    };
  },
  created(){

    if (this.$route.query.deviceCode) {
      this.deviceCode = this.$route.query.deviceCode;
      this.getOverGame()
      this.getMqttData();
    }else{
      this.getDeviceCode()
    }
  },
  watch: {
  },
  methods: {
    // 查询设备编号
    getDeviceCode() {
      this.$bridge.callHandler("searchDeviceCode", "", (data) => {
        var settings = JSON.parse(data);
        this.deviceCode = settings.deviceCode;
        if (this.deviceCode) {
          this.getMqttData();
          this.getOverGame()
        }
      });
    },
    //mqtt订阅
    getMqttData(){
      onMqttMessage({
        topic: this.deviceCode + mqttTopic.finish_match_play_info,
      })
      this.$emitter.on(this.deviceCode + mqttTopic.finish_match_play_info, ({topic, message})=>{
        if(topic === this.deviceCode + mqttTopic.finish_match_play_info){
          console.log("完赛MQTT消息message",JSON.parse(message.toString()))
          this.$emitter.emit('getMqttData')
          if(this.pageType){
            //发送完赛消息重新定时
            this.timeset();
          }
          const msgObj = JSON.parse(message.toString())
          const obj = this.listComplete.filter(item=>(item.userId === msgObj.userId))
          // 如果数据中已经有了该数据，就覆盖原先重复的数据
          if(obj?.length){
            this.listComplete.forEach((item)=>{
              if(item.userId === msgObj.userId){
                Object.assign(item, msgObj);
              }
            })
          }else{
            this.listComplete.unshift(msgObj)
          }

          // listData length无变化，仅仅是属性变更，手动调用下组件内部的reset方法
          this.$nextTick(()=>{
            if(this.$refs.seamlessScrollOver){
              this.$refs.seamlessScrollOver.reset()
            }else {
              console.log('this.$refs.seamlessScrollOver对象获取不到')
            }
          })

          if(this.listComplete.length>30){
            this.listComplete.pop()
          }
        }
      })
    },
    //定时
    timeset(){
      //调用代码清除定时器
      clearTimeout(this.timeHandle);
      console.log('进入定时')
      //自动 && SS：完赛mqtt计时十分钟没消息转广告大屏；10分钟有消息消除定时再重新定时十分钟
      this.timeHandle = setTimeout(() => {
        this.sendPageType()
      }, 10*60*1000);//10*60*1000
    },
    sendPageType(){
      this.pageType = "GG"
      this.$emitter.emit('changePageType',this.pageType)
    },
    getOverGame(){
      overGameDevice(this.deviceCode).then((res) => {
        this.listComplete=[]
        if (res.data) {
          res.data.forEach((item,index)=>{
            if(index<30){
                  this.listComplete.push(item)
                }
          })


        }
      });
    }
  },
  mounted() {
    // this.$nextTick(()=>{
    //   this.autoCycle()
    // })
    //监听赛事大屏跳转广告大屏事件
    this.$emitter.on('setTimeOutOverGame', (item)=>{
      this.pageType = item.pageType
      this.timeset();
    })

  },
  beforeDestroy() {
    if(this.intervalId){
      clearInterval(this.intervalId)
    }
    if(this.deviceCode){
      // 销毁前取消订阅
      unsubscribe(this.deviceCode + mqttTopic.finish_match_play_info)
    }
  },
}
</script>

<style lang="scss" scoped>
.scollData{
  overflow: hidden;
  text-overflow: ellipsis;//多出部分以省略号形式隐藏
  white-space: nowrap;
  width:42%;/* 禁止换行  normal可以换行 */
}
.overGame {
  margin-top: 2vh;
  background-size: 100% 100%;
  background-repeat: no-repeat,no-repeat;
  padding: 7.5vh 1vw 2vh 1vw;
  position: relative;
  .p_title {
    position: absolute;
    top: 2%;
    left:1%;
  }
  .marqueeCover{
    position: absolute;
    top:0;
    left:0;
    z-index:100;
    height:100%;
    width:100%;
  }
  .marquee {
    height: 96%;
    padding:0;
    z-index:11;
    .title{
      background: linear-gradient(to right, rgb(65, 254, 232), rgb(8, 75, 213));
    }
    .tbody{
      //height: 90%;
      overflow: hidden;
      overflow-x: hidden;
      overflow-y: scroll;
    }
    .el-row{
      line-height:40px;
      height:40px;
      font-size:18px;
      color: #a5fffd;
      overflow: hidden;
    }
  }
  .tbody::-webkit-scrollbar {
    display: none;
  }
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper {
    overflow: hidden !important;
  }
}
</style>
