import request from '@/utils/request'

// 查询左侧节点
export function queryNodeList(query) {
  return request({
    url: '/emd/emdRiteConfig/node',
    method: 'get',
    params: query
  })
}

// 查询右侧table
export function queryNodeFlowList(query) {
  return request({
    url: '/emd/emdRiteConfig/nodeFlow',
    method: 'get',
    params: query
  })
}

// 查询名称
export function nodeFlowName(query) {
  return request({
    url: '/emd/emdRiteConfig/nodeFlowName',
    method: 'get',
    params: query
  })
}

// 保存或者修改
export function addOrUpdate(query) {
  return request({
    url: '/emd/emdRiteConfig/addOrUpdate',
    method: 'post',
    data: query
  })
}


