import request from '@/utils/request'


//查询代理商名下已授权/总数
export function getAuditSummary(url) {
  return request({
    url: url,
    method: 'get'
  })
}
