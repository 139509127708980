var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loginUserInfo
    ? _c(
        "div",
        { staticClass: "welcomeInfo" },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                disabled: _vm.showGreenway,
                effect: "dark",
                placement: "bottom",
              },
            },
            [
              _c("template", { slot: "content" }, [
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.allGreenwayInfoTitle) },
                }),
              ]),
              _c("div", { domProps: { innerHTML: _vm._s(_vm.title) } }),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }