<template>
  <div class="matchVideo">
    <div class="p_title">赛事讲解</div>
    <div class="p_video" style="">
      <el-carousel v-show="adType === '1000'" class="imgClass">
        <el-carousel-item v-for="item in videoList" :key="item.playbillId" style="">
                  <img
                    width="100%"
                    height="100%"
                    :src="item.url"
                  />
        </el-carousel-item>
      </el-carousel>
      <video
        v-show="adType === '1001'"
        ref="videoAd"
        autoplay="autoplay"
        controls
        :muted="true"
        type="video/mp4"
        width="100%"
        height="100%"
      ></video>
    </div>


      <!--
       如果此时间段内无视频，则播放该默认视频
      <video
        v-if="!currentSource.url"
        src="/dev-api/profile/advertising/2022/12/27/4dbca683-c37e-42e0-9e3c-db048d3d4f00.mp4"
        autoplay="autoplay"
        controls
        :muted="true"
        type="video/mp4"
        width="100%"
        height="100%"
        loop
      ></video>-->
<!--    </div>-->
  </div>
</template>
<script>
import {listGetVideoUrl,} from "@/api/device/screen-preview/screen-preview";
export default {
  name: "AdvertEdit",
  computed: {

  },
  data() {
    return {
      //广告视频轮播
      videoList: [], //视频队列
      curr: 0, //当前播放视频下标
      intervalId: null, //定时器id
      currentSource: {}, // 当前循环播放媒体资源
      deviceCode: "",
      // 当前媒体资源 图片/视频
      adType:null,
      //当前视频路径
      playUrl:null,
      videoUrl:null,
      video:null,
    };
  },
  created(){
    this.getDeviceCode();
    if (this.$route.query.deviceCode) {
      this.deviceCode = this.$route.query.deviceCode;
    this.getGetVideoUrl();
    }
  },
  watch: {
  },
  methods: {
    // 查询设备编号
    getDeviceCode() {
      this.$bridge.callHandler("searchDeviceCode", "", (data) => {
        var settings = JSON.parse(data);
        this.deviceCode = settings.deviceCode;
        if (this.deviceCode) {
          this.getGetVideoUrl();
        }
      });
    },
    /** 查询赛事讲解视屏 广告 */
    getGetVideoUrl() {
      listGetVideoUrl(this.deviceCode).then((response) => {
        if (response.code === 200) {
          if(response.data.adType == "JMD"){
            this.videoList = response.data.rcAdPlaybillList;
            this.videoList.map((item) => {
              item.url = process.env.VUE_APP_BASE_API + item.url;

            });
            let nowTime = (new Date()) / 1000
            let endTime = (new Date(response.data.stopTime)).getTime() / 1000
            if(endTime >= nowTime){
              if(this.videoList[0].mediaFileType == '1000'){
                this.adType = '1000'
              }else{
                this.adType = '1001'
                this.$nextTick(() => {
                  this.runVideoUrl()
                });
              }
            }else{
              this.$message.error('该广告位绑定广告单已过投放结束时间，请重新绑定！');
            }

          }

        }
      });
    },
    /** 循环播放视频队列 */
    runVideoUrl(){
      let video = this.$refs.videoAd;
      video.src = this.videoList[this.curr].url;
      console.log(video.src,'video.src');
      console.log(this.curr,'this.curr')

      video.load();
      video.play();
      this.curr++;
      if (this.curr >= this.videoList.length) {
        this.curr = 0;
      }
    },
    //时间转为秒
    timeToSec(time) {
      let s = "";
      let hour = time.split(":")[0];
      let min = time.split(":")[1];
      let sec = time.split(":")[2];
      s = Number(hour * 3600) + Number(min * 60) + Number(sec);
      return s;
    },
    // 获取当前24小时制时间(00:00:00)
    getNowTime() {
      const now = new Date();
      return `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;
    },
  },
  mounted() {
      //监听视频播放结束，自动播放下一个
      this.$nextTick(() => {
        this.$refs.videoAd.addEventListener("ended", () => {
          this.runVideoUrl();
        });
      });
    //创建定时器
    // this.intervalId = setInterval(() => {
    //   this.videoList.map((item) => {
    //     // 播放时间不为空
    //     if (item.beginPlayTime && item.overPlayTime) {
    //       // 当前时间的时间戳
    //       const nowSec = this.timeToSec(this.getNowTime());
    //       //开始与结束时间的时间戳
    //       const beginPlaySec = this.timeToSec(item.beginPlayTime);
    //       const overPlaySec = this.timeToSec(item.overPlayTime);
    //
    //       // 如果当前时间在该媒体资源需要播放的时间段内
    //       if (beginPlaySec < nowSec && overPlaySec > nowSec) {
    //         this.currentSource = item;
    //         console.log("item", item);
    //       } else {
    //         this.currentSource = {};
    //       }
    //     }
    //   });
    // }, 1000);
  },
  beforeDestroy() {
    // 组件销毁前清除定时器
    // if (this.intervalId) {
    //   clearInterval(this.intervalId);
    // }
  },
}
</script>

<style lang="scss" scoped>
.matchVideo {
  position: relative;
  height: 29vh;
  background: url(../../../../assets/images/赛事讲解.png) no-repeat 0 0;
  background-size: 100% 100%;
  padding:5vh 2vw 2vh 2vw ;
  box-sizing: border-box;
  overflow: hidden;
  .p_title {
    position: absolute;
    left: 0.5vw;
    top: 0;
  }
  .p_video{
    width:100%;
    height:100%;
    .imgClass{
      width:100%;
      height:100%;
      box-sizing: border-box;
      position:relative;
      img {
        position: absolute;
        left:0;
        top:0;
        bottom:0;
        right:0
      }
    }
  }


  ::v-deep .el-carousel__container{
    height:100%
  }
}
</style>
