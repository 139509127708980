var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { className: "box" } },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%", overflow: "hidden" },
          attrs: {
            data: _vm.list,
            "show-header": _vm.headerStatus,
            "row-style": { height: "40px" },
            "header-cell-style": { "text-align": "center" },
            "cell-style": { "text-align": "center", padding: "5px" },
          },
        },
        [
          _vm.headerStatus == true
            ? _c("el-table-column", {
                attrs: {
                  prop: "rankingValue",
                  label: "排名",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.rankingValue == 1
                            ? _c("img", {
                                staticStyle: {
                                  position: "absolute",
                                  top: "5px",
                                  left: "44%",
                                },
                                attrs: {
                                  width: "35",
                                  height: "28",
                                  src: require("../../../../assets/images/first.png"),
                                  alt: "",
                                },
                              })
                            : scope.row.rankingValue == 2
                            ? _c("img", {
                                staticStyle: {
                                  position: "absolute",
                                  top: "5px",
                                  left: "44%",
                                },
                                attrs: {
                                  width: "35",
                                  height: "28",
                                  src: require("../../../../assets/images/twice.png"),
                                  alt: "",
                                },
                              })
                            : scope.row.rankingValue == 3
                            ? _c("img", {
                                staticStyle: {
                                  position: "absolute",
                                  top: "5px",
                                  left: "44%",
                                },
                                attrs: {
                                  width: "35",
                                  height: "28",
                                  src: require("../../../../assets/images/third.png"),
                                  alt: "",
                                },
                              })
                            : _c("span", [
                                _vm._v(_vm._s(scope.row.rankingValue)),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3851054050
                ),
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              prop: "playUserName",
              label: "用户名",
              align: "center",
              "show-overflow-tooltip": true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          float: "left",
                          position: "relative",
                          padding: "0 70px",
                        },
                      },
                      [
                        _c("el-avatar", {
                          staticStyle: {
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                          },
                          attrs: {
                            size: 20,
                            src: _vm.getImgPath(scope.row.avatar),
                            fit: "cover",
                          },
                        }),
                        _c("span", { staticStyle: { "margin-left": "30px" } }, [
                          _vm._v(_vm._s(scope.row.playUserName)),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "score",
              label: _vm.scoreLabel,
              "show-overflow-tooltip": true,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }