import request from '@/utils/request'

// 查询打卡点打卡成功率报列表
export function listCp(query) {
  return request({
    url: '/match/check/modeOneData',
    method: 'get',
    params: query
  })
}

// 打卡成功率页面：模式二
export function listCpTwo(query) {
  return request({
    url: '/match/check/modeTwoData',
    method: 'get',
    params: query
  })
}

// 查询同步时间
export function syncTime() {
  return request({
    url: '/match/check/queryTime',
    method: 'get'
  })
}

// 同步
export function generateData() {
  return request({
    url: '/match/check/generateData',
    method: 'get'
  })
}
