import request from "@/utils/request";
import { console } from "vuedraggable/src/util/helper";

// 【芯片柜】查询货道库存列表
export function chipListInfo(query) {
  return request({
    url: "/rc/replenish/chip/query",
    method: "get",
    params: query,
  });
}

// 根据号码段查询芯片库芯片数量
export function postCountByShowNo(data) {
  return request({
    url: "/rc/production/countByShowNo",
    method: "POST",
    params: data,
  });
}

// 【芯片柜】各货道补货功能
// export function postAdd(data) {
//   return request({
//     url: "/rc/replenish/chip/add",
//     method: "POST",
//     data: data,
//   });
// }

// 【芯片柜】各货道补货功能【号码段】
export function postAddByShowNo(data) {
  return request({
    url: "/rc/replenish/chip/add/part",
    method: "POST",
    data: data,
  });
}

// 【芯片柜】各货道补货功能【选择号码】
export function postAddByEpcNos(data) {
  return request({
    url: "/rc/replenish/chip/add/choose",
    method: "POST",
    data: data,
  });
}

// 【芯片柜】各货道补货功能【数量】
export function postNumber(data) {
  return request({
    url: "/rc/replenish/chip/add/number",
    method: "POST",
    data: data,
  });
}

// 已分配到设备的号码类型列表
export function assignedChip(query) {
  return request({
    url: "/rc/relation/query/chips",
    method: "get",
    params: query,
  });
}

// 查询已分配到设备的所有芯片信息列表
export function rfidList(query) {
  return request({
    url: "/rc/relation/query/rfidList",
    method: "get",
    params: query,
  });
}

// 根据号码段查询芯片库芯片数量【已分配到设备】
export function countByShowNoOfDevice(data) {
  return request({
    url: "/rc/production/countByShowNoOfDevice",
    method: "POST",
    data: data,
  });
}

// 【芯片柜】清空设备库存
export function removeChipDeviceReplenish(deviceCode) {
  return request({
    url: "/rc/replenish/chip/remove/device/" + deviceCode,
    method: "delete",
    // data: data,
  });
}

// 【芯片柜】清空货道库存
export function removeChipCargoWayReplenish(data) {
  return request({
    url: `rc/replenish/chip/remove/cargo`,
    method: "delete",
    data: data,
  });
}

//查询货道号码信息
export function getCargoEpcList(cargoCode) {
  return request({
    url: "/rc/production/cargoEpcList/" + cargoCode,
    method: "get",
  });
}

//查询货道号码信息（多参）
export function cargoEpcListByParam(query) {
  return request({
    url: "/rc/production/cargoEpcListByParam",
    method: "get",
    params: query,
  });
}

