import request from '@/utils/request'

// 左侧报价方案项目
export function getLeftProject(query) {
  return request({
    url: '/emd/quoteConfig/getLeftProject',
    method: 'get',
    params: query
  })
}

// 顶部赛事信息
export function getUpperMatchInfo(query) {
  return request({
    url: '/emd/quoteConfig/getUpperMatchInfo',
    method: 'get',
    params: query
  })
}

// 左侧数据
export function getLeftTreeData(query) {
  return request({
    url: '/emd/quoteConfig/getLeftTreeData',
    method: 'get',
    params: query
  })
}

// 保存或修改
export function addOrUpdate(data) {
  return request({
    url: '/emd/quoteConfig/addOrUpdate',
    method: 'post',
    data: data
  })
}

// 右侧table数据
export function getRightTable(query) {
  return request({
    url: '/emd/quoteConfig/getRightTable',
    method: 'get',
    params: query
  })
}


