<template>
  <div
    class="box"
  >
    <img
      :src="value1"
      :style="{width: '100%', height: '100%',objectFit}"
    >
  </div>
</template>

<script>
import {imgConfig} from "@/views/device/widget/img/config";

export default {
  name: "w-img",
  components: {},
  props: {
    value: {
      type: String,
      default: imgConfig.children.props.value
    },
    objectFit: {
      type: String,
      default: imgConfig.children.props.objectFit
    }
  },
  computed:{
    value1(){
      // 如果是设备进来，就用127的地址
      if(this.$route.query.device == 1){
        return 'https://127.0.0.1:8082' + this.value
      }else {
        return process.env.VUE_APP_BASE_API + this.value
      }
    }
  },
  data() {
    return {
    };
  },
  methods: {

  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.box {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

</style>
