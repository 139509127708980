// 内容超出的长度
export const nameLength = 20;
export const contentLength = 40;

export function nameFormat(row, column, cellValue) {
  if (cellValue == null) {
    return ''
  }

  return cellValue.length > nameLength ? cellValue.slice(0, nameLength) + '...' : cellValue
}

export function priceFormat(row, column, cellValue) {
  return null !== cellValue ? cellValue + ' 元' : ''
}

export function hourFormat(row, column, cellValue) {
  return null !== cellValue ? cellValue + ' 小时' : ''
}

export function customLeastTimeFormat(row, column, cellValue) {
  return null !== cellValue ? cellValue + ' 天' : ''
}

export function contentFormat(row, column, cellValue) {
  if (cellValue == null) {
    return ''
  }

  return cellValue.length > contentLength ? cellValue.slice(0, contentLength) + '...' : cellValue
}


// 方式一：
// 标签上加 :formatter="priceFormat"
// import { nameFormat, priceFormat, hourFormat, customLeastTimeFormat, contentFormat } from '@/api/common/formats'
// methods: {} 块里加 nameFormat, priceFormat, hourFormat, customLeastTimeFormat, contentFormat,

// 方式一：
// 标签上加 :formatter="priceFormat"
// main.js 中 import { nameFormat, priceFormat, hourFormat, customLeastTimeFormat, contentFormat } from '@/api/common/formats'
// main.js 中 挂载到全局
