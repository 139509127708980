var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("router-view"),
      _c(
        "div",
        { staticClass: "copyShareLink", on: { click: _vm.copyShareLinkFn } },
        [_vm._v("点击复制分享链接")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }