import request from '@/utils/request'



// 查询存包订单详细
export function getRecord(orderId) {
   return request({
    url: '/order/record/getRecord/' + orderId,
    method: 'get'
  })
}


