// 生成 19 位的 ID
export function generateID() {
  const time = new Date().getTime();
  let RandomSixStr = '';
  let remainingLength = 19 - String(time).length;

  for (let i = 0; i < remainingLength; i++) {
    RandomSixStr += String(Math.floor(Math.random() * 10))
  }

  return String(time + RandomSixStr);
}
