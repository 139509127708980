var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        [
          _c("el-form-item", { attrs: { label: "图片资源：" } }, [
            _c(
              "div",
              {
                on: {
                  click: function ($event) {
                    return _vm.chooseMedia()
                  },
                },
              },
              [
                _c("el-input", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    value: _vm.currentEditItem.children.props.mediaName,
                    rows: 4,
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "模式：" } },
            [
              _c(
                "el-radio-group",
                {
                  on: {
                    change: function (value) {
                      return _vm.changeProps("objectFit", value)
                    },
                  },
                  model: {
                    value: _vm.currentEditItem.children.props.objectFit,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.currentEditItem.children.props,
                        "objectFit",
                        $$v
                      )
                    },
                    expression: "currentEditItem.children.props.objectFit",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "cover" } }, [
                    _vm._v("cover"),
                  ]),
                  _c("el-radio", { attrs: { label: "none" } }, [
                    _vm._v("none"),
                  ]),
                  _c("el-radio", { attrs: { label: "contain" } }, [
                    _vm._v("contain"),
                  ]),
                  _c("el-radio", { attrs: { label: "fill" } }, [
                    _vm._v("fill"),
                  ]),
                  _c("el-radio", { attrs: { label: "scale-down" } }, [
                    _vm._v("scale-down"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("media-resources", {
        ref: "mediaResources",
        attrs: { mediaFileType: "1000" },
        on: { submit: _vm.choiceVlaue },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }