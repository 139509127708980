var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        [
          _c(
            "el-form-item",
            { attrs: { label: "城市：" } },
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: "请输入内容" },
                  model: {
                    value: _vm.city,
                    callback: function ($$v) {
                      _vm.city = $$v
                    },
                    expression: "city",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.searchWeather($event)
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "字体大小：" } },
            [
              _c("el-input-number", {
                staticStyle: { width: "100%" },
                attrs: {
                  value: _vm.currentEditItem.children.props.fontSize,
                  min: 1,
                  max: 1000,
                },
                on: {
                  change: function (value) {
                    return _vm.changeProps("fontSize", value)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "对齐：" } },
            [
              _c(
                "el-radio-group",
                {
                  on: {
                    change: function (value) {
                      return _vm.changeProps("textAlign", value)
                    },
                  },
                  model: {
                    value: _vm.currentEditItem.children.props.textAlign,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.currentEditItem.children.props,
                        "textAlign",
                        $$v
                      )
                    },
                    expression: "currentEditItem.children.props.textAlign",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "left" } }, [_vm._v("左")]),
                  _c("el-radio", { attrs: { label: "center" } }, [
                    _vm._v("居中"),
                  ]),
                  _c("el-radio", { attrs: { label: "right" } }, [_vm._v("右")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "颜色" } },
            [
              _c("el-color-picker", {
                attrs: {
                  value: _vm.currentEditItem.children.props.color,
                  "show-alpha": "",
                  predefine: _vm.predefineColors,
                },
                on: {
                  change: function (value) {
                    return _vm.changeProps("color", value)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }