<template>
  <div
    :class="{ marquee: true, marquee1: index === 1, marquee2: index === 2 }"
    :style="{ height: height }"
  >
    <div class="p_title" v-show="index === 2">
      <span>赛事排名 </span>
    </div>
    <!--    <el-carousel :interval="10000">-->
    <!--      <el-carousel-item v-for="(item, index) in listCurrentMatchs" :key="index">-->
    <!--        &lt;!&ndash;赛事标题&ndash;&gt;-->
    <!--        -->
    <!--      </el-carousel-item>-->
    <!--    </el-carousel>-->
    <div v-for="(item, index) in listCurrentMatchs" :key="index">
      <div v-if="index === currentIndex">
        <div :class="marqueeIndex == 2 ? 'p_top' : 'p_dataScreen'">
          <span style="display: block" v-if="item.matchName != '月度排行榜'">
            {{ item.matchName }}
            <!--             && item.matchRule != 'monthly'&& item.matchRule != 'score'-->
            <template v-if="item.matchRule">
              （
              <dict-label
                :options="dict.type.match_rule"
                :value="item.matchRule"
              />
              ）
            </template>
          </span>
          <span
            v-else
            style="display: block; font-weight: bold; font-size: 28px"
          >
            {{ month }}{{ item.matchName }}
          </span>
          <template v-if="item.lineGroup">
            <!--             v-if="item.matchRule == 'DCPDK' || item.matchRule == 'SXGRZHCJ'|| item.matchRule == 'ZNTZS'"-->
            <p class="lineGroup">组别：{{ item.lineGroup }}KM</p>
          </template>
        </div>
        <!--listData1 && listData2-->
        <div v-if="item.listData1 && item.listData2">
          <!--thead-->
          <el-row class="p_name" style="padding: 7px; padding-top: 0px">
            <!--            <el-col style="margin-left: 16px" :span="5">名次</el-col>-->
            <el-col :span="12">{{ item.thead1 }}</el-col>
            <el-col :span="12">{{ item.thead2 }}</el-col>
          </el-row>
          <!--tbody-->
          <el-row class="doubleTable">
            <!--左侧表格-->
            <el-col :span="12">
              <match-table
                :list="item.listData1"
                :matchRule="
                  item.matchRule == 'monthly' ? 'LJLC' : item.matchRule
                "
              ></match-table>
            </el-col>
            <!--右侧表格-->
            <el-col :span="11">
              <match-table
                :list="item.listData2"
                :matchRule="
                  item.matchRule == 'monthly' ? 'DKCS' : item.matchRule
                "
              ></match-table>
            </el-col>
          </el-row>
        </div>
        <match-table
          v-else-if="!item.listData2"
          :list="item.listData1"
          :matchRule="item.matchRule"
          status="1"
        ></match-table>
      </div>
    </div>
  </div>
</template>
<script>
import {
  listCurrentMatch,
  monthlyGroupRanking,
  monthlyRanking,
} from "@/api/device/screen-preview/screen-preview";
import MatchTable from "../components/MatchTable1";
import _ from 'lodash'

export default {
  name: "CurrentMatch",
  dicts: ["match_rule"],
  props: ["heightCurrentMatch", "backgroundCurrentMatch", "marqueeIndex"],
  components: {
    MatchTable,
  },
  computed: {},
  data() {
    return {
      currentMatchTimer: null,
      currentIndex: 0,
      //定时器
      intervalId: null,
      //容器高度
      height: this.heightCurrentMatch,
      deviceCode: "",
      index: null,
      //赛事排名数据
      listCurrentMatchs: [],
      // 当前月份
      month: null,
    };
  },
  created() {
    // 如果路由有deviceCode，说明是在平台预览，否则是在大屏设备端展示
    if (this.$route.query.deviceCode) {
      this.deviceCode = this.$route.query.deviceCode;
      this.getCurrentMatch();
    } else {
      this.getDeviceCode();
    }
  },

  watch: {
    marqueeIndex: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue == 1) {
          this.index = 1;
        } else {
          this.index = 2;
        }
      },
    },
  },
  mounted() {
    this.$emitter.on("getMqttData", () => {
      this.getCurrentMatch();
    });


    // 定时每5分钟刷新数据
    // this.intervalId = setInterval(()=>{
    //   this.getCurrentMatch()
    // }, 5 * 60 * 1000)
  },
  methods: {
    // 查询设备编号
    getDeviceCode() {
      this.$bridge.callHandler("searchDeviceCode", "", (data) => {
        var settings = JSON.parse(data);
        this.deviceCode = settings.deviceCode;

        if (this.deviceCode) {
          this.getCurrentMatch();
        }
      });
    },
    getTableData(data) {
      //listData1左侧表格数据，listData2右侧表格数据
      //thead1左侧表格表头，thead2右侧表格表头
      data.map((element) => {
        //单人玩法
        if (element.matchRule == "SXGRZHCJ" || element.matchRule == "DCPDK") {
          // 男子组为空就只显示女子组
          if (!element.manList?.length) {
            element.listData1 = element.woManList.slice(0, 18);
          } else if (!element.woManList?.length) {
            //女子组为空只显示男子组
            element.listData1 = element.manList.slice(0, 18);
          } else {
            //男女子组都不为空同时显示
            element.listData1 = element.manList.slice(0, 18);
            element.listData2 = element.woManList.slice(0, 18);
            element.thead1 = "男子组";
            element.thead2 = "女子组";
            //得出左侧排名
            element.maxLength = Math.max(
              element.manList.length,
              element.woManList.length
            );
          }
        } else if (element.matchRule == "ZNTZS") {
          //表格显示数据
          element.listData1 = element.individualRankingList.slice(0, 18);
        } else {
          //多人玩法
          //单人赛
          if (element.partType == "DN" || element.partType == "DRS") {
            //表格显示数据
            element.listData1 = element.individualRankingList.slice(0, 18);
          } else {
            //多人赛
            //左侧排名
            element.maxLength = Math.max(
              element.individualRankingList.length,
              element.teamList.length
            );
            //统一循环数据
            // element.listData1 = element.individualRankingList
            //表格显示数据
            element.listData1 = element.teamList.slice(0, 18);
            // element.thead1 = '个人排名'
            element.thead1 = "团队排名";
            //统一团队赛和个人赛里的字段
            element.listData1.map((item) => {
              //头像字段
              item.avatar = item.teamLogo;
              //用户名字段
              item.playUserName = item.teamName;
            });
          }
        }
      });
      this.listCurrentMatchs = data;
      this.currentMatchChange()
    },

    /** 查询当前赛事列表 节流3分钟*/
    getCurrentMatch: _.throttle(function () {
      listCurrentMatch(this.deviceCode)
        .then((response) => {
          this.getTableData(response.data);
          this.getMonthlyRanking();
          this.getMonthlyGroupRanking();
        })
        .catch((err) => {
          this.getMonthlyRanking();
          this.getMonthlyGroupRanking();
        });
    }, 3 * 60 * 1000, {
      leading: true,
      trailing: true
    }),

    //获取当前日期函数
    getNowFormatDate() {
      let date = new Date(),
        year = date.getFullYear(), //获取完整的年份(4位)
        month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
        strDate = date.getDate(); // 获取当前日(1-31)
      this.month = month;
      if (month < 10) month = `0${month}`; // 如果月份是个位数，在前面补0
      if (strDate < 10) strDate = `0${strDate}`; // 如果日是个位数，在前面补0
      return `${year}-${month}`;
    },
    // 月度累积里程排行及累积打卡排行接口
    getMonthlyRanking() {
      let parame = {
        deviceCode: this.deviceCode,
        monthly: this.getNowFormatDate(),
      };
      monthlyRanking(parame).then((res) => {
        // 如果开启了只显示当天的赛事排名，则不进行显示
        if(res.data === null){
          return
        }
        let data = res.data;
        data.matchName = "月度排行榜";
        data.thead1 = "月度累积里程排行";
        data.thead2 = "月度累积打卡排行";
        data.matchRule = "monthly";
        // 月累计里程排名
        data.listData1 = data.eventScreenMonthlyMileageList;
        data.listData1.forEach((item) => {
          item.rankingValue = item.ranking;
          item.playUserName = item.userName;
          item.score = item.monthRunningMileage;
        });
        // 月度累积打卡排行
        data.listData2 = data.eventScreenMonthlyCheckList;
        data.listData2.forEach((item) => {
          item.rankingValue = item.ranking;
          item.playUserName = item.userName;
          item.score = item.monthCheckPointNumber;
        });
        this.listCurrentMatchs.push(data);
      });
      this.currentMatchChange();
    },
    // 月度组别排行接口
    getMonthlyGroupRanking() {
      let parame = {
        deviceCode: this.deviceCode,
        monthly: this.getNowFormatDate(),
      };
      monthlyGroupRanking(parame).then((res) => {
        // 如果开启了只显示当天的赛事排名，则不进行显示
        if(res.data === null){
          return
        }
        let data = res.data;
        data.forEach((item) => {
          if (
            item.eventScreenMonthlyManRankingList.length ||
            item.eventScreenMonthlyWoManRankingList.length
          ) {
            item.matchName = "月度排行榜";
            item.thead1 = "男子组";
            item.thead2 = "女子组";
            item.matchRule = "score";
            item.listData1 = item.eventScreenMonthlyManRankingList;
            item.listData1.forEach((item1) => {
              item1.rankingValue = item1.allRank;
              item1.playUserName = item1.userName;
              item1.score = item1.score;
            });
            item.listData2 = item.eventScreenMonthlyWoManRankingList;
            item.listData2.forEach((item2) => {
              item2.rankingValue = item2.allRank;
              item2.playUserName = item2.userName;
              item2.score = item2.score;
            });

            this.listCurrentMatchs.push(item);
          }
        });
        this.currentMatchChange();
      });
    },
    currentMatchChange(){
      this.currentIndex = 0;
      clearInterval(this.currentMatchTimer);
      if(this.listCurrentMatchs && this.listCurrentMatchs.length > 0) {
        let timeList = [];
        const currentTime = {
          "tenSeconds": 10 * 1000,
          "thirtySeconds": 30 * 1000
        };
        this.listCurrentMatchs.forEach(item => {
          timeList.push(currentTime[item.displayTime] || 10 * 1000 )
        });
        const getNext = (s) => {
          this.currentMatchTimer = setInterval(() => {
            if (this.currentIndex === this.listCurrentMatchs.length - 1) {
              this.currentIndex = 0;
              clearInterval(this.currentMatchTimer);
              getNext(timeList[this.currentIndex]);
              return;
            }
            this.currentIndex++;
            clearInterval(this.currentMatchTimer);
            getNext(timeList[this.currentIndex])
          }, s  || 10 * 1000);
        };
        getNext(timeList[0])
      }

    },
  },
  beforeDestroy() {
    clearInterval(this.currentMatchTimer)
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  deactivated() {
    clearInterval(this.currentMatchTimer)
  }
};
</script>

<style lang="scss" scoped>
.p_name {
  //background: linear-gradient(to right, rgb(65, 254, 232), rgb(8, 75, 213));
  //background: rgb(255,255,255,0.3);
  padding: 5px 0px;
  text-align: center;
}

.doubleTable {
  display: flex;
  justify-content: space-between;
}

.marquee {
  position: relative;
  padding: 0 1.5vw 2vh 2vw;
  overflow: hidden;

  .personalMatch {
    margin: 20px 40px;
    font-size: 24px;
    color: #a5fffd;

    .data {
      width: 100%;
      background: rgb(247, 188, 188);

      .ones {
        width: 33%;
        text-align: center;
        float: left;
        padding-top: 50px;

        > div:first-child {
          font-size: 40px;
          margin-bottom: 20px;
        }
      }
    }

    .time {
      clear: both;
      padding-top: 100px;
      text-align: center;
      justify-content: right;
    }
  }
}

.marquee1 {
  .p_title {
    z-index: 100;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  ::v-deep .el-carousel__container {
    height: 81vh !important;
    overflow: hidden;
  }
}

.marquee2 {
  background: url(../../../../assets/images/datascreen/图层2备份@2x.png)
    no-repeat 0 0;
  background-size: 100% 100%;

  ::v-deep .el-carousel__container {
    height: 22.5vh !important;
    overflow: hidden;
  }
}

.girlGrop {
  overflow: hidden;
  text-overflow: ellipsis; //多出部分以省略号形式隐藏
  white-space: nowrap; /* 禁止换行  normal可以换行 */
}

.p_top {
  margin: 16px 0;
  margin-left: 200px !important;
  width: 450px;
  text-align: center;
  font-size: 20px;

  color: #0ec0be;
}

.p_dataScreen {
  text-align: center;
  margin: 16px 0;
  //color: #0ec0be;
  font-size: 20px;
}

.lineGroup {
  margin: 5px 0px 0px 0px;
}

::v-deep .el-carousel__indicators {
  display: none;
}
</style>
