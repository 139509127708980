import request from '@/utils/request'

// 查询奖品类型列表
export function listType(query) {
  return request({
    url: '/rc/PrizeType/list',
    method: 'get',
    params: query
  })
}

// 新增奖品类型
export function addType(data) {
  return request({
    url: '/rc/PrizeType',
    method: 'post',
    data: data
  })
}

// 修改奖品类型
export function updateType(data) {
  return request({
    url: '/rc/PrizeType',
    method: 'put',
    data: data
  })
}

// 删除奖品类型
export function delType(typeId) {
  return request({
    url: '/rc/PrizeType/' + typeId,
    method: 'delete'
  })
}

// 查询奖品类型详细
export function getType(typeId) {
    return request({
      url: '/rc/PrizeType/' + typeId,
      method: 'get'
    })
  }

// 导出奖品类型
export function exportType(query) {
  return request({
    url: '/rc/PrizeType/export',
    method: 'get',
    params: query
  })
}
// 查询奖品补货
export function listRestock(query) {
  return request({
    url: '/rc/restock/list',
    method: 'get',
    params: query
  })
}
// 查询货道管理列表
export function cargoList(data) {
  return request({
    url: '/rc/way/CargoList/' + data,
    method: 'get',
  })
}
// 新增奖品补货
export function addRestock(data) {
  return request({
    url: '/rc/restock',
    method: 'post',
    data: data
  })
}
// 删除奖品补货
export function delRestock(typeId) {
  return request({
    url: '/rc/restock/' + typeId,
    method: 'delete'
  })
}

// 根据奖品类型获取该类型奖品入库剩余数量信息
export function getPrizeType(prizeTypeCode) {
  return request({
    url: '/rc/PrizeType/count/' + prizeTypeCode,
    method: 'get'
  })
}
