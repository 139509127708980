import request from "@/utils/request";

//查询设备终端播放设置列表
export function queryDeviceInfoList(query) {
  return request({
    url: "/ad/playSettings/queryDeviceInfoList",
    method: "get",
    params: query
  });
}
// 查询设备终端播放设置
export function queryDevicePlaySettings(query) {
  return request({
    url: "/ad/playSettings/queryDevicePlaySettings",
    method: "get",
    params: query
  });
}
// 配置设备终端播放设置
export function configPlatSettings(data) {
  return request({
    url: "/ad/playSettings/configPlatSettings",
    method: "post",
    data: data
  });
}

