var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.displayType == "SS"
        ? _c("data-screen", { attrs: { screenTitle: _vm.screenTitle } })
        : _vm.displayType == "GG"
        ? _c("advert-screen", { attrs: { screenTitle: _vm.screenTitle } })
        : _vm.displayType == "QPGG"
        ? _c("advert-full-screen", { attrs: { screenTitle: _vm.screenTitle } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }