import request from '@/utils/request'

// 查询绿道或公园集合
export function getGreenwayTrackList(queryType, greenwayName, parkName) {
  return request({
    url: '/emd/reserve/getGreenwayTrackList?pageNum=1&pageSize=20&queryType='+queryType+'&greenwayName='+greenwayName+'&parkName='+parkName,
    method: 'get'
  })
}

// 获取办赛绿道预定信息
export function selectScheduleGreenwayList(query) {
  return request({
    url: '/emd/reserve/selectScheduleGreenwayList',
    method: 'POST',
    data: query,
  })
}

//赛事信息
export function queryMatchInfo(query) {
  return request({
    url: '/emd/reserve/queryMatchInfo',
    method: 'get',
    params: query
  })
}
//赛事信息
export function reserve(query) {
  return request({
    url: '/emd/reserve/reserve',
    method: 'get',
    params: query
  })
}

//取消预定
export function cancelReserve(query) {
  return request({
    url: '/emd/reserve/cancelReserve',
    method: 'get',
    params: query
  })
}

//删除预定
export function removeReserve(query) {
  return request({
    url: '/emd/reserve/removeReserve',
    method: 'get',
    params: query
  })
}
//获取预定时间
export function aloneLately(query) {
  return request({
    url: '/emd/reserve/aloneLately',
    method: 'get',
    params: query
  })
}
