import request from '@/utils/request'

// 查询小程序（跑者）用户列表
export function listAppUser(query) {
  return request({
    url: '/system/appUser/list',
    method: 'get',
    params: query
  })
}

export function queryGameRecordList(query) {
  return request({
    url: '/system/appUser/queryGameRecordList',
    method: 'get',
    params: query
  })
}

export function punchRecordList(query) {
  return request({
    url: '/system/appUser/punchRecordList',
    method: 'get',
    params: query
  })
}

export function purchaseRecordList(query) {
  return request({
    url: '/system/appUser/purchaseRecordList',
    method: 'get',
    params: query
  })
}

export function getMedalList(query) {
  return request({
    url: '/system/appUser/getMedalList',
    method: 'get',
    params: query
  })
}

export function getChipList(query) {
  return request({
    url: '/system/appUser/getChipList',
    method: 'get',
    params: query
  })
}

export function getAddressList(query) {
  return request({
    url: '/system/appUser/getAddressList',
    method: 'get',
    params: query
  })
}

// 导出
export function exportAppUser(query,appUserIds) {
  if (0 === appUserIds.length) {
    appUserIds = [-1];
  }
  return request({
    url: '/system/appUser/export/'+appUserIds,
    method: 'get',
    params: query
  })
}

//号码库绑定芯片
export function numberBindChip(data){
  return request({
    url: '/system/appUser/numberBindChip',
    method: 'POST',
    data: data
  })
}

//取消绑定芯片
export function cancelBindChip(data){
  return request({
    url: '/system/appUser/cancelBindChip',
    method: 'POST',
    data: data
  })
}

// 生成跑者用户渠道二维码
export function generateChannelWxCode(params){
  return request({
    url: '/system/appUser/generateChannelWxCode',
    method: 'get',
    params
  })
}
// 获取已生成的跑者用户渠道二维码集合以及用户数
export function getChannelWxCodeList(params){
  return request({
    url: '/system/appUser/getChannelWxCodeList',
    method: 'get',
    params
  })
}
// 获取已生成的跑者用户渠道二维码用户数
export function getChannelUserCount(params){
  return request({
    url: '/system/appUser/getChannelUserCount',
    method: 'get',
    params
  })
}
// 删除已生成的跑者用户渠道二维码
export function deleteChannelWxCode(data){
  return request({
    url: `/system/appUser/deleteChannelWxCode`,
    method: 'delete',
    data
  })
}