import request from '@/utils/request'

// 查询运营芯片商品管理列表
export function listChip(query) {
  return request({
    url: '/operate/chip/list',
    method: 'get',
    params: query
  })
}

// 查询运营芯片商品管理详细
export function getChip(chipId) {
  return request({
    url: '/operate/chip/' + chipId,
    method: 'get'
  })
}

// 新增运营芯片商品管理
export function addChip(data) {
  return request({
    url: '/operate/chip',
    method: 'post',
    data: data
  })
}
// 修改芯片商品管理
export function updateChip(data) {
  return request({
    url: '/operate/chip/update',
    method: 'post',
    data: data
  })
}
// 删除芯片商品管理
export function delChip(chipId) {
  return request({
    url: '/operate/chip/delChip/' + chipId,
    method: 'post'
  })
}

