import request from '@/utils/request'


// 查询故障日志列表
export function listFaultLog(query) {
  return request({
    url: '/monitor/faultLog/listFaultLog',
    method: 'get',
    params: query
  })
}

// 删除运营赛事保险
export function delFaultLog(faultId) {
  return request({
    url: '/monitor/faultLog/delFaultLog/' + faultId,
    method: 'post'
  })
}


// 清空操作日志
export function cleanFaultLog() {
  return request({
    url: '/monitor/faultLog/cleanFaultLog',
    method: 'post'
  })
}



// 导出故障日志
export function exportFaultLog(query, faultIds) {
  if (0 === faultIds.length) {
    faultIds = [-1];
  }
  return request({
    url: '/monitor/faultLog/export/' + faultIds,
    method: 'get',
    params: query
  })
}



//查询设备日志列表
export function listDeviceLog(query) {
  return request({
    url: '/rc/deviceLog/list',
    method: 'get',
    params: query
  })
}

//清空设备日志
export function cleanDeviceoperlog() {
  return request({
    url: '/rc/deviceLog/clean',
    method: 'delete'
  })
}

// 删除设备日志
export function delDeviceoperlog(deviceOperIds) {
  return request({
    url: '/rc/deviceLog/remove/' + deviceOperIds,
    method: 'delete'
  })
}

// 导出设备日志
export function exportDeviceoperlog(query,updateIds) {
  // if (0 === operIdList.length) {
  //   operIdList = [-1];
  // }
  if (0 === updateIds.length) {
    updateIds = [-1];
  }
  return request({
    url: '/rc/deviceLog/export/'+updateIds,
    method: 'get',
    params: query
  })
}
