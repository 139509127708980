import request from '@/utils/request'

// 查询左侧节点
export function getAdvertConfigNode(query) {
  return request({
    url: '/emd/advertConfig/getAdvertConfigNode',
    method: 'get',
    params: query
  })
}

// 查询右侧上传的
export function getAdvertConfigFile(query) {
  return request({
    url: '/emd/advertConfig/getAdvertConfigFile',
    method: 'get',
    params: query
  })
}

// 保存或者修改
export function addOrUpdate(query) {
  return request({
    url: '/emd/advertConfig/addOrUpdate',
    method: 'post',
    data: query
  })
}


