import request from '@/utils/request'

// 查询绿道路线列表
export function listLine(query) {
  return request({
    url: '/greenway/line/list',
    method: 'get',
    params: query
  })
}

// 查询绿道路线详细
export function getLine(lineId) {
  return request({
    url: '/greenway/line/' + lineId,
    method: 'get'
  })
}

// 新增绿道路线
export function addLine(data) {
  return request({
    url: '/greenway/line/add',
    method: 'post',
    data: data
  })
}

// 修改绿道路线
export function updateLine(data) {
  return request({
    url: '/greenway/line/edit',
    method: 'post',
    data: data
  })
}

// 删除绿道路线
export function delLine(lineId) {
  return request({
    url: '/greenway/line/del/' + lineId,
    method: 'post'
  })
}

// 导出绿道路线
export function exportLine(query) {
  return request({
    url: '/greenway/line/export',
    method: 'get',
    params: query
  })
}

// 获取所有路线集合
export function getLineAll(query) {
  return request({
    url: '/greenway/line/getLineAll/',
    method: 'get',
    params: query
  })
}

// 获取CP点集合
export function getCPList(greenwayId) {
  return request({
    url: '/greenway/line/getCPList/' + greenwayId,
    method: 'get'
  })
}

//获取绿道路线打卡（有分页）
export function getLineCheckPoint(lineId) {
  return request({
    url: '/greenway/line/getLineCheckPoint/' + lineId,
    method: 'get'
  })
}

//获取绿道路线打卡（无分页）
export function getLineCheckPointNoPage(lineId) {
  return request({
    url: '/greenway/line/getLineCheckPointNoPage/' + lineId,
    method: 'get'
  })
}
