<template>
  <div class="box">
    <div class="p_title" :style="{fontSize: 14 * this.$store.getters.width / 1000 + 'px'}">赛道地图</div>
    <div class="runPath">
      <img :src="trackImageUrl"  width="100%" height="100%"/>
    </div>
  </div>
</template>

<script>
import {getTrackImage} from "@/api/device/screen-preview/screen-preview";

export default {
  name: "w-run-map",
  components: {},
  props: {},
  data() {
    return {
      trackImageUrl: '',
      deviceCode: 'testpack001-MATCHSCREEN-0001'
    };
  },
  methods: {
    /** 赛事大屏地图 */
    getImage() {
      getTrackImage(this.deviceCode).then((res) => {
        this.trackImageUrl = process.env.VUE_APP_BASE_API + res.data;
      });
    },
  },
  mounted() {
    this.getImage()
  }
}
</script>

<style lang="scss" scoped>
.box {
  color: #fff;
  height: 100%;
  width: 100%;
  background: url(../../../../assets/images/index/赛事路线.png) no-repeat 0px 0px;
  background-size: 100% 100%;
  //padding: 50px 0px 20px 15px;
  position: relative;

  .p_title {
    position: absolute;
    top: 2%;
    left: 5%;
  }

  .runPath {
    background: #000;
    position: absolute;
    left: 5%;
    top: 17%;
    right: 5%;
    bottom: 5%;
  }
}
</style>
