import request from '@/utils/request'

// 查询赛事报名订单列表
export function listEnroll(query) {
  return request({
    url: '/order/enroll/list',
    method: 'get',
    params: query
  })
}

// 导出赛事报名订单
export function exportEnroll(query,orderIds) {
  if (0 === orderIds.length) {
    orderIds = [-1];
  }
  return request({
    url: '/order/enroll/export/'+orderIds,
    method: 'get',
    params: query
  })
}
// 新增赛事报名订单
export function addEnroll(data) {
  return request({
    url: '/order/enroll',
    method: 'post',
    data: data
  })
}

/**
 * 强制退单
 * @param orderNos
 */
export function coerceChargebacks(orderNos,templateCode) {
  return request({
    url: '/order/enroll/coerceChargebacks/'+templateCode,
    method: 'post',
    data: orderNos
  })
}

/**
 * 强制退单
 * @param orderNos
 */
export function coerceChargebacks2(orderNos) {
  return request({
    url: '/order/enroll/coerceChargebacks',
    method: 'post',
    data: orderNos
  })
}

// 人工领取
export function artificialReceive(orderId) {
  return request({
    url: '/order/enroll/artificialReceive/' + orderId,
    method: 'get'
  })
}

// 取消不参与调剂
export function cancelNotTakePartRelieve(orderId) {
  return request({
    url: '/order/enroll/cancelNotTakePartRelieve/' + orderId,
    method: 'get'
  })
}

// 调剂失败退单
export function relieveFailChargeback(orderNo) {
  return request({
    url: '/order/enroll/relieveFailChargeback/' + orderNo,
    method: 'get'
  })
}
