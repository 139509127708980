<template>
  <div className="box">
    <div id="weather" :style="{fontSize: size + 'px', color,textAlign: textAlign}">
      <span>天气：&nbsp;</span>
      <span v-show="briefNew" style="margin-right: 10px">{{briefNew}}</span>
      <span v-show="tmpNew" style="margin-right: 10px">{{tmpNew}}℃</span>
      <i :class="'qi-' + nowIcon"></i>
    </div>
  </div>
</template>

<script>
import {weatherConfig} from "@/views/device/widget/weather/config";
import {textConfig} from "@/views/device/widget/text/config";

export default {
  name: "w-weather",
  components: {},
  props: {

    briefNew: {
      type: String,
      default: weatherConfig.children.props.briefNew
    },
    tmpNew: {
      type: String,
      default: weatherConfig.children.props.tmpNew
    },
    nowIcon: {
      type: String,
      default: weatherConfig.children.props.nowIcon
    },
    fontSize: {
      type: [Number, String],
      default: weatherConfig.children.props.fontSize
    },
    color: {
      type: String,
      default: weatherConfig.children.props.color
    },
    textAlign: {
      type: String,
      default: weatherConfig.children.props.textAlign
    }
  },
  computed: {
    size(){
      return this.fontSize * this.$store.getters.width / 1000
    }
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.box {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

</style>
