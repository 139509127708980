// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/datascreen/图层3@2x.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n.scollData[data-v-be144f2a] {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  width: 100%;\n  /* 禁止换行  normal可以换行 */\n}\n.marquee2[data-v-be144f2a] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 0 0;\n  background-size: 100% 100%;\n  padding: 7vh 1.5vw 2vh 2vw;\n}\n.marquee2 .personalMatch[data-v-be144f2a] {\n  height: 54vh;\n}\n.marquee2 .personalMatch .ones > div[data-v-be144f2a]:first-child,\n.marquee2 .personalMatch .ones > div[data-v-be144f2a]:nth-child(2) {\n  font-size: 40px;\n}\n.marquee2 .p_title[data-v-be144f2a] {\n  padding-top: 3px;\n}\n.marquee1[data-v-be144f2a] {\n  padding: 1vh 1.5vw 2vh 2vw;\n  height: 100%;\n}\n.marquee1 .ones[data-v-be144f2a] {\n  padding: 4vh 1vw;\n}\n.marquee1 .ones > div[data-v-be144f2a]:first-child,\n.marquee1 .ones > div[data-v-be144f2a]:nth-child(2) {\n  font-size: 40px;\n  margin-bottom: 4%;\n}\n.container[data-v-be144f2a] {\n  position: relative;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  overflow: hidden;\n  font-size: 18px;\n}\n.container .personalMatch[data-v-be144f2a] {\n  color: #a5fffd;\n}\n.container .personalMatch .information[data-v-be144f2a] {\n  width: 45vw;\n}\n.container .personalMatch .userName[data-v-be144f2a] {\n  line-height: 55px;\n}\n.container .personalMatch .data[data-v-be144f2a] {\n  width: 100%;\n  height: 37%;\n}\n.container .personalMatch .data .ones[data-v-be144f2a] {\n  width: 33%;\n  height: 50%;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  text-align: center;\n  float: left;\n  overflow: hidden;\n}\n.container .personalMatch .data .ones > div[data-v-be144f2a]:nth-child(2) {\n  color: transparent;\n}\n.container .personalMatch .time[data-v-be144f2a] {\n  clear: both;\n  padding-top: 4%;\n  text-align: center;\n}", ""]);
// Exports
module.exports = exports;
