<template>
  <el-form
    label-width="30px"
    label-position="top"
    size="mini"
    :inline="true"
  >
    <el-form-item label="w：">
      <el-input-number
        :value="currentEditItem.w"
        @change="(currentValue)=>changeOptions('w',currentValue)"
        controls-position="right"
        :min="0"
        :max="100"
      >
      </el-input-number>
    </el-form-item>
    <el-form-item label="h：">
      <el-input-number
        :value="currentEditItem.h"
        @change="(currentValue)=>changeOptions('h',currentValue)"
        controls-position="right"
        :min="0"
        :max="100"
      >
      </el-input-number>
    </el-form-item>
    <el-form-item label="x：">
      <el-input-number
        :value="currentEditItem.x"
        @change="(currentValue)=>changeOptions('x',currentValue)"
        controls-position="right"
        :min="0"
        :max="100 - currentEditItem.w"
      >
      </el-input-number>
    </el-form-item>
    <el-form-item label="y：">
      <el-input-number
        :value="currentEditItem.y"
        @change="(currentValue)=>changeOptions('y',currentValue)"
        controls-position="right"
        :min="0"
        :max="100 - currentEditItem.h"
      >
      </el-input-number>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: "optionConfig",
  components: {},
  props: {
    currentEditItem: {
      type: Object
    }
  },
  data() {
    return {};
  },
  methods: {
    changeOptions(key, value){
      this.$emit('change', {key,value})
    }
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
