import request from '@/utils/request'
// 查询领取密码列表
export function getPassword(orderId) {
   return request({
    url: '/order/chipRecord/listPassword/' + orderId,
    method: 'get'
  })
}


