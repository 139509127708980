var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _c(
      "div",
      {
        staticClass: "p_title",
        style: { fontSize: (14 * this.$store.getters.width) / 1000 + "px" },
      },
      [_vm._v("赛道地图")]
    ),
    _c("div", { staticClass: "runPath" }, [
      _c("img", {
        attrs: { src: _vm.trackImageUrl, width: "100%", height: "100%" },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }