import request from '@/utils/request'

// 查询存包订单列表
export function listLocker(query) {
  return request({
    url: '/order/locker/list',
    method: 'get',
    params: query
  })
}

// 导出存包订单
export function exportLocker(query,orderIds) {
  if (0 === orderIds.length) {
    orderIds = [-1];
  }
  return request({
    url: '/order/locker/export/'+orderIds,
    method: 'get',
    params: query
  })
}
