var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fullscreen",
    {
      attrs: { fullscreen: _vm.fullscreen },
      on: {
        "update:fullscreen": function ($event) {
          _vm.fullscreen = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "screen1" },
        [
          _c(
            "el-container",
            [
              _c("el-header", [
                _c("div", { staticClass: "title" }, [
                  _c(
                    "div",
                    {
                      staticClass: "top",
                      on: {
                        click: function ($event) {
                          return _vm.toggle()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.screenTitle))]
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "text-align": "right",
                      position: "absolute",
                      top: "6px",
                      right: "3px",
                      width: "100%",
                    },
                  },
                  [
                    _c("w-clock", { staticClass: "clock" }),
                    _c("w-weather", { staticClass: "weather" }),
                  ],
                  1
                ),
              ]),
              _c(
                "el-main",
                { staticStyle: { "padding-top": "43px" } },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 15 } },
                    [
                      _c(
                        "el-col",
                        { staticClass: "left", attrs: { span: 9 } },
                        [
                          _c("track-image", {
                            attrs: {
                              heightTrackImage: "35.7vh",
                              backgroundTrackImage: "datascreen/编组@2x.png",
                            },
                          }),
                          _c("current-match", {
                            staticStyle: { "margin-top": "2vh" },
                            attrs: {
                              marqueeIndex: "2",
                              heightCurrentMatch: "25vh",
                            },
                          }),
                          _c("recent-match", {
                            attrs: {
                              heightRecentMatch: "25vh",
                              backgroundRecentMatch: "datascreen/最近赛事.png",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticClass: "center", attrs: { span: 15 } },
                        [
                          _c(
                            "div",
                            { staticClass: "bigScreen" },
                            [
                              _c("personal-match", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.ptitle === "个人赛事",
                                    expression: "ptitle === '个人赛事'",
                                  },
                                ],
                                attrs: {
                                  marqueeIndex: "2",
                                  heightCurrentMatch: "62.5vh",
                                },
                                on: {
                                  timeTitle: _vm.getTitle,
                                  changePage: _vm.changePage,
                                },
                              }),
                              _c("big-screen-view", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.ptitle,
                                    expression: "!ptitle",
                                  },
                                ],
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { attrs: { gutter: 15 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 15 } },
                                [
                                  _c("over-game", {
                                    attrs: {
                                      heightOverGame: "25vh",
                                      heightTbody: "70%",
                                      backgroundOverGame:
                                        "datascreen/图层2备份@2x.png",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 9 } },
                                [
                                  _c("total-sessions", {
                                    attrs: {
                                      heightTotalSessions: "25vh",
                                      backgroundTotalSessions:
                                        "datascreen/图层@2x.png",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }