import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'
import Setting  from "@/settings"

NProgress.configure({ showSpinner: false })

const whiteList = ['/login','/teamByLogin','/auth-redirect', '/bind', '/register','/screen','/bigscreenview','/datascreen','/operationscreen']
const whiteShareList = [
  '/404',"/shareRedirect","/shareEventPlanOverview","/shareOrganizerInformation","/sharePlanConfig","/shareQuotationScheme","/shareContractInvoiceConfig","/shareCompetitionCeremonyProcess","/shareExecutionProcess","/shareAdvertConfig",
  "/sharePlanConfigOnce","/shareQuotationSchemeOnce","/shareContractInvoiceConfigOnce","/shareCompetitionCeremonyProcessOnce","/shareExecutionProcessOnce","/shareAdvertConfigOnce"
]
router.beforeEach((to, from, next) => {
  NProgress.start()
  if (getToken() && !whiteShareList.includes(to.path)) {
    to.meta.title && store.dispatch('settings/setTitle', to.meta.title)
    /* has token*/
    if (['/login','/teamByLogin'].includes(to.path)) {
      next({ path: '/' })
      NProgress.done()
    } else {
      if (store.getters.roles.length === 0) {
        // 判断当前用户是否已拉取完user_info信息
        store.dispatch('GetInfo').then((userInfo) => {
          store.dispatch('GenerateRoutes',userInfo).then(accessRoutes => {
            // 根据roles权限生成可访问的路由表
            router.addRoutes(accessRoutes) // 动态添加可访问路由表
            // 是否禁止访问首页
            const isAgent = !!userInfo.user.hideHomePage
            let goToGo = null
            if (isAgent && accessRoutes.length) {
              const getFirstRoute = (node) => {
               return node.children && node.children.length ? getFirstRoute(node.children[0]) : node
              }
              goToGo = getFirstRoute(accessRoutes[0])
            }else {
              goToGo = to
            }
            next({ ...goToGo, replace: true }) // hack方法 确保addRoutes已完成
          })
        }).catch(err => {
            store.dispatch('LogOut').then(() => {
              Message.error(err)
              next({ path: '/' })
            })
          })
      } else {
        if(!!store.state.user.loginUserInfo.hideHomePage && to.path === '/index') {
          let goToPath = ""
          const getFirstRoute = (node) => {
            goToPath += node.path
            if(node.children && node.children.length) {
              goToPath+='/'
               getFirstRoute(node.children[0])
            }
           }
           getFirstRoute(store.getters.topbarRouters[0])
           next({path:'/redirect' + goToPath,replace:true})
        }else {
          next()
        }
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1 || whiteShareList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      // 否则全部重定向到登录页
      let nextTo = 'login'
      // 根据配置，判断进入哪套登录页面
      if(to.path) {
       const enterLoginPage = Setting.enterLoginPage
        if(typeof enterLoginPage === 'string') {
          const cPath = to.path.split('/')[1]
          (enterLoginPage === cPath) && (nextTo = 'teamByLogin')
        }
        if(typeof enterLoginPage === 'function') {
          (enterLoginPage(to,from)) && (nextTo = 'teamByLogin')
        }
      }
      next(`/${nextTo}?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
