import request from "@/utils/request";
import {praseStrEmpty} from "@/utils/ruoyi";

//查询代理商列表
export function listAgent(){
  return request({
    url: '/system/agent/list',
    method: 'get',
  })
}

//新增代理商
export function addAgent(data){
  return request({
    url: '/system/agent',
    method: 'post',
    data: data
  })
}

//修改代理商
export function updateAgent(data){
  return request({
    url: '/system/agent',
    method: 'put',
    data: data
  })
}

//查看代理商
export function viewAgent(deptId){
  return request({
    url: '/system/agent/'+deptId,
    method: 'get',
  })
}

//删除代理商
export function delAgent(deptId){
  return request({
    url: '/system/agent/'+deptId,
    method: 'delete',
  })
}

// 查询代理商用户列表
export function listAgentUser(query) {
  return request({
    url: '/system/agent/user/list',
    method: 'get',
    params: query
  })
}

// 新增代理商用户
export function addAgentUser(data) {
  return request({
    url: '/system/agent/user/add',
    method: 'post',
    data: data
  })
}

/**
 * 查询代理商用户信息
 * @param userId
 * @returns {AxiosPromise}
 */
export function getAgentUser(userId) {
  return request({
    url: '/system/agent/user/' + praseStrEmpty(userId),
    method: 'get'
  })
}



/**
 * 查询代理商绑定绿道 -- 需要启用绿道
 * @param userId
 * @returns {AxiosPromise}
 */
export function listGreenWay() {
  return request({
    url: '/system/agent/greenwayList' ,
    method: 'get'
  })
}


/**
 * 查询代理商下面是否有用户
 * @param deptId
 * @returns {AxiosPromise}
 */
export function isExitAgentUser(deptId){
  return request({
    url: '/system/agent/isExitAgentUser/' + deptId,
    method: 'get'
  })
}


// 更新代理商用户
export function updateAgentUser(data) {
  return request({
    url: '/system/agent/user/edit',
    method: 'put',
    data: data
  })
}

// 删除代理商用户
export function delAgentUser(userId) {
  return request({
    url: '/system/agent/user/' + userId,
    method: 'delete'
  })
}

export function viewAgentByUserId(userId) {
  return request({
    url: '/system/agent/greenway/' + userId,
    method: 'get'
  })
}

// 系统用户展示部门
// 代理商用户ID查询绿道信息
//团建赛客户欢迎语显示 为客户名称
export function getWelcomeInfo(userId) {
  return request({
    url: '/system/agent/welcomeinfo/' + userId,
    method: 'get'
  })
}

