<template>
    <div className="box">
      <div id="weather">
        天气：<span style="margin-right: 10px">{{briefNew}}/{{tmpNew}}℃</span>
      </div>
    </div>
  </template>

  <script>

  export default {
    name: "w-weather",
    computed: {
      size(){
        return this.fontSize * this.$store.getters.width / 1000
      }
    },
    data() {
      return {
        city:'深圳',
        tmpNew:'',
        briefNew:'',

      };
    },
    methods: {
        async searchWeather(){
      let key = 'e2138a2ae39242c898493885eb195048';
      //获取城市的ID
      let httpUrl = `https://geoapi.qweather.com/v2/city/lookup?location=${this.city}&key=${key}`
      let res = await fetch(httpUrl)
      let result = await res.json()
      let id = result.location[0].id
      //根据城市id获取具体的天气
      let httpUrl1 = `https://devapi.qweather.com/v7/weather/now?location=${id}&key=${key}`
      let res1 = await fetch(httpUrl1)
      let result1 = await res1.json()
      let now = result1.now
      this.tmpNew =now.temp
      this.briefNew = now.text
    },
    },
    mounted() {
        this.searchWeather()
    }
  }
  </script>

  <style lang="scss" scoped>
  .box {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  </style>
