var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      !_vm.topNav
        ? _c("breadcrumb", {
            staticClass: "breadcrumb-container",
            attrs: { id: "breadcrumb-container" },
          })
        : _vm._e(),
      _vm.topNav
        ? _c("top-nav", {
            staticClass: "topmenu-container",
            attrs: { id: "topmenu-container" },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile"
            ? [
                _c("screenfull", {
                  staticClass: "right-menu-item hover-effect",
                  attrs: { id: "screenfull" },
                }),
                _c(
                  "el-tooltip",
                  {
                    staticStyle: { "margin-right": "15px" },
                    attrs: {
                      content: "布局大小",
                      effect: "dark",
                      placement: "bottom",
                    },
                  },
                  [
                    _c("size-select", {
                      staticClass: "right-menu-item hover-effect",
                      attrs: { id: "size-select" },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }