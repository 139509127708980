import request from '@/utils/request'

//查询赛事总览
export function queryOverviewInfo(query) {
  return request({
    url:'/emd/generalView/share/queryOverviewInfo',
    method: 'get',
    params: query
  })
}
//  客户确认
export function customerConfirmation(params) {
  return request({
   url: '/emd/main/share/customerConfirmation',
   method: 'get',
   params
 })
}

//确认记录
export function confirmationRecord(query) {
  return request({
    url: '/emd/generalView/share/confirmationRecord',
    method: 'get',
    params: query
  })
}
