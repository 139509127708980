import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'
import duplicateIndex from '@/layout/duplicateIndex'
import sharingPlanView from '@/sharingPlanView/index.vue'
import sharingPlanViewOnce from '@/sharingPlanView/once.vue'

/**
 * Note: 路由配置项
 *
 * hidden: true                     // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true                 // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                  // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                  // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                  // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect             // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'               // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * query: '{"id": 1, "name": "ry"}' // 访问路由的默认传递参数
 * meta : {
    noCache: true                   // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'                  // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'                // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false               // 如果设置为false，则不会在breadcrumb面包屑中显示
    activeMenu: '/system/user'      // 当路由设置了该属性，则会高亮相对应的侧边栏。
  }
 */

// 公共路由
export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: (resolve) => require(['@/views/redirect'], resolve)
      }
    ]
  },
  {
    path: '/teamByLogin',
    component: (resolve) => require(['@/views/teamLogin/index'], resolve),
    hidden: true
  },
  {
    path: '/login',
    component: (resolve) => require(['@/views/login'], resolve),
    hidden: true
  },
  {
    path: '/register',
    component: (resolve) => require(['@/views/register'], resolve),
    hidden: true
  },
  {
    path: '/404',
    component: (resolve) => require(['@/views/error/404'], resolve),
    hidden: true
  },
  {
    path: '/bigscreenview',
    component: () => import('@/views/device/screen-preview/components/bigScreenView'),
    hidden: false
  },
  {
    path: '/screen',
    component: () => import('@/views/device/screen-preview/index'),
    hidden: false
  },
  {
    path: '/datascreen',
    component: () => import('@/views/device/screen-preview/dataScreen'),
    hidden: false
  },
  {
    path: '/operationscreen',
    component: () => import('@/views/device/operation-maintenance/operationScreen'),
    hidden: false
  },
  {
    path: '/401',
    component: (resolve) => require(['@/views/error/401'], resolve),
    hidden: true
  },
  {
    path: '',
    component: Layout,
    redirect: 'index',
    children: [
      {
        path: 'index',
        component: (resolve) => require(['@/views/index'], resolve),
        name: 'Index',
        meta: { title: '首页', icon: 'dashboard', affix: true }
      }
    ]
  },
  {
    path: '',
    component: duplicateIndex,
    redirect: 'eventPlanOverview',
    hidden: true,
    children: [
      {
        path: 'eventPlanOverview',
        component: (resolve) => require(['@/views/emd/demandEstablishMatchEdit/eventPlanOverview'], resolve),
        name: 'eventPlanOverview',
        meta: { title: '赛事方案总览', icon: 'dashboard', affix: false }
      }
    ]
  },{
    path: '',
    component: duplicateIndex,
    redirect: 'organizerInformation',
    hidden: true,
    children: [
      {
        path: 'organizerInformation',
        component: (resolve) => require(['@/views/emd/demandEstablishMatchEdit/organizerInformation'], resolve),
        name: 'organizerInformation',
        meta: { title: '办赛人员信息', icon: 'dashboard', affix: false }
      }
    ]
  },{
    path: '',
    component: duplicateIndex,
    redirect: 'planConfig/index',
    hidden: true,
    children: [
      {
        path: '/planConfig/index',
        component: (resolve) => require(['@/views/emd/demandEstablishMatchEdit/planConfig/index'], resolve),
        name: 'planConfig',
        meta: { title: '赛事策划方案配置', icon: 'dashboard', affix: false }
      }
    ]
  },{
    path: '',
    component: duplicateIndex,
    redirect: 'quotationScheme',
    hidden: true,
    children: [
      {
        path: 'quotationScheme',
        component: (resolve) => require(['@/views/emd/demandEstablishMatchEdit/quotationScheme'], resolve),
        name: 'quotationScheme',
        meta: { title: '赛事报价方案', icon: 'dashboard', affix: false }
      }
    ]
  },{
    path: '',
    component: duplicateIndex,
    redirect: 'contractInvoiceConfig',
    hidden: true,
    children: [
      {
        path: 'contractInvoiceConfig',
        component: (resolve) => require(['@/views/emd/demandEstablishMatchEdit/contractInvoiceConfig'], resolve),
        name: 'contractInvoiceConfig',
        meta: { title: '赛事合同方案', icon: 'dashboard', affix: false }
      }
    ]
  },{
    path: '',
    component: duplicateIndex,
    redirect: 'competitionCeremonyProcess',
    hidden: true,
    children: [
      {
        path: 'competitionCeremonyProcess',
        component: (resolve) => require(['@/views/emd/demandEstablishMatchEdit/competitionCeremonyProcess'], resolve),
        name: 'competitionCeremonyProcess',
        meta: { title: '赛事仪式流程', icon: 'dashboard', affix: false }
      }
    ]
  },{
    path: '',
    component: duplicateIndex,
    redirect: 'executionProcess',
    hidden: true,
    children: [
      {
        path: 'executionProcess',
        component: (resolve) => require(['@/views/emd/demandEstablishMatchEdit/executionProcess'], resolve),
        name: 'executionProcess',
        meta: { title: '赛事执行流程方案', icon: 'dashboard', affix: false }
      }
    ]
  },{
    path: '',
    component: duplicateIndex,
    redirect: 'advertConfig',
    hidden: true,
    children: [
      {
        path: 'advertConfig',
        component: (resolve) => require(['@/views/emd/demandEstablishMatchEdit/advertConfig'], resolve),
        name: 'advertConfig',
        meta: { title: '赛事品宣方案', icon: 'dashboard', affix: false }
      }
    ]
  },
  //分享方案链接
  {
    path: '/shareRedirect',
    component: sharingPlanView,
    hidden: true,
    children: [
      {
        path: '/shareRedirect/:path(.*)',
        name: 'shareRedirect',
        component: (resolve) => require(['@/views/redirect'], resolve)
      }
    ]
  },
  {
    path: '',
    component: sharingPlanView,
    redirect: 'shareEventPlanOverview',
    hidden: true,
    children: [
      {
        path: 'shareEventPlanOverview',
        component: (resolve) => require(['@/sharingPlanView/pages/eventPlanOverview/index.vue'], resolve),
        name: 'shareEventPlanOverview',
        meta: { title: '赛事方案总览', icon: 'dashboard', affix: false }
      }
    ]
  },{
    path: '',
    component: sharingPlanView,
    redirect: 'shareOrganizerInformation',
    hidden: true,
    children: [
      {
        path: 'shareOrganizerInformation',
        component: (resolve) => require(['@/sharingPlanView/pages/organizerInformation/index.vue'], resolve),
        name: 'shareOrganizerInformation',
        meta: { title: '办赛人员信息', icon: 'dashboard', affix: false }
      }
    ]
  },
  {
    path: '',
    component: sharingPlanView,
    redirect: 'sharePlanConfig',
    hidden: true,
    children: [
      {
        path: '/sharePlanConfig',
        component: (resolve) => require(['@/sharingPlanView/pages/planConfig/index.vue'], resolve),
        name: 'sharePlanConfig',
        meta: { title: '赛事策划方案配置', icon: 'dashboard', affix: false,pageFrom:"isViewByPlan"}
      }
    ]
  },{
    path: '',
    component: sharingPlanView,
    redirect: 'shareQuotationScheme',
    hidden: true,
    children: [
      {
        path: 'shareQuotationScheme',
        component: (resolve) => require(['@/sharingPlanView/pages/quotationScheme/index.vue'], resolve),
        name: 'shareQuotationScheme',
        meta: { title: '赛事报价方案', icon: 'dashboard', affix: false,pageFrom:"isViewByQuote"}
      }
    ]
  },{
    path: '',
    component: sharingPlanView,
    redirect: 'shareContractInvoiceConfig',
    hidden: true,
    children: [
      {
        path: 'shareContractInvoiceConfig',
        component: (resolve) => require(['@/sharingPlanView/pages/contractInvoiceConfig/index.vue'], resolve),
        name: 'shareContractInvoiceConfig',
        meta: { title: '赛事合同方案', icon: 'dashboard', affix: false,pageFrom:"isViewByContract"}
      }
    ]
  },{
    path: '',
    component: sharingPlanView,
    redirect: 'shareCompetitionCeremonyProcess',
    hidden: true,
    children: [
      {
        path: 'shareCompetitionCeremonyProcess',
        component: (resolve) => require(['@/sharingPlanView/pages/competitionCeremonyProcess/index.vue'], resolve),
        name: 'shareCompetitionCeremonyProcess',
        meta: { title: '赛事仪式流程', icon: 'dashboard', affix: false,pageFrom:"isViewByRite"}
      }
    ]
  },{
    path: '',
    component: sharingPlanView,
    redirect: 'shareExecutionProcess',
    hidden: true,
    children: [
      {
        path: 'shareExecutionProcess',
        component: (resolve) => require(['@/sharingPlanView/pages/executionProcess/index.vue'], resolve),
        name: 'shareExecutionProcess',
        meta: { title: '赛事执行流程方案', icon: 'dashboard', affix: false,pageFrom:"isViewByExecute"}
      }
    ]
  },{
    path: '',
    component: sharingPlanView,
    redirect: 'shareAdvertConfig',
    hidden: true,
    children: [
      {
        path: 'shareAdvertConfig',
        component: (resolve) => require(['@/sharingPlanView/pages/advertConfig/index.vue'], resolve),
        name: 'shareAdvertConfig',
        meta: { title: '赛事品宣方案', icon: 'dashboard', affix: false,pageFrom:"isViewByAdvert" }
      }
    ]
  },
    //分享链接单个
    {
      path: '',
      component: sharingPlanViewOnce,
      redirect: 'sharePlanConfigOnce',
      hidden: true,
      children: [
        {
          path: '/sharePlanConfigOnce',
          component: (resolve) => require(['@/sharingPlanView/pages/planConfig/index.vue'], resolve),
          name: 'sharePlanConfigOnce',
          meta: { title: '赛事策划方案配置', icon: 'dashboard', affix: false,pageFrom:"isViewByPlan"}
        }
      ]
    },{
      path: '',
      component: sharingPlanViewOnce,
      redirect: 'shareQuotationSchemeOnce',
      hidden: true,
      children: [
        {
          path: 'shareQuotationSchemeOnce',
          component: (resolve) => require(['@/sharingPlanView/pages/quotationScheme/index.vue'], resolve),
          name: 'shareQuotationSchemeOnce',
          meta: { title: '赛事报价方案', icon: 'dashboard', affix: false,pageFrom:"isViewByQuote"}
        }
      ]
    },{
      path: '',
      component: sharingPlanViewOnce,
      redirect: 'shareContractInvoiceConfigOnce',
      hidden: true,
      children: [
        {
          path: 'shareContractInvoiceConfigOnce',
          component: (resolve) => require(['@/sharingPlanView/pages/contractInvoiceConfig/index.vue'], resolve),
          name: 'shareContractInvoiceConfigOnce',
          meta: { title: '赛事合同方案', icon: 'dashboard', affix: false,pageFrom:"isViewByContract"}
        }
      ]
    },{
      path: '',
      component: sharingPlanViewOnce,
      redirect: 'shareCompetitionCeremonyProcessOnce',
      hidden: true,
      children: [
        {
          path: 'shareCompetitionCeremonyProcessOnce',
          component: (resolve) => require(['@/sharingPlanView/pages/competitionCeremonyProcess/index.vue'], resolve),
          name: 'shareCompetitionCeremonyProcessOnce',
          meta: { title: '赛事仪式流程', icon: 'dashboard', affix: false,pageFrom:"isViewByRite"}
        }
      ]
    },{
      path: '',
      component: sharingPlanViewOnce,
      redirect: 'shareExecutionProcessOnce',
      hidden: true,
      children: [
        {
          path: 'shareExecutionProcessOnce',
          component: (resolve) => require(['@/sharingPlanView/pages/executionProcess/index.vue'], resolve),
          name: 'shareExecutionProcessOnce',
          meta: { title: '赛事执行流程方案', icon: 'dashboard', affix: false,pageFrom:"isViewByExecute"}
        }
      ]
    },{
      path: '',
      component: sharingPlanViewOnce,
      redirect: 'shareAdvertConfigOnce',
      hidden: true,
      children: [
        {
          path: 'shareAdvertConfigOnce',
          component: (resolve) => require(['@/sharingPlanView/pages/advertConfig/index.vue'], resolve),
          name: 'shareAdvertConfigOnce',
          meta: { title: '赛事品宣方案', icon: 'dashboard', affix: false,pageFrom:"isViewByAdvert" }
        }
      ]
    },
  {
    path: '/user',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [
      {
        path: 'profile',
        component: (resolve) => require(['@/views/system/user/profile/index'], resolve),
        name: 'Profile',
        meta: { title: '个人中心', icon: 'user' }
      }
    ]
  },
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'role/:userId/:userType',
        component: (resolve) => require(['@/views/system/user/authRole'], resolve),
        name: 'AuthRole',
        meta: { title: '分配角色', activeMenu: '/system/user' }
      }
    ]
  },
  {
    path: '/system/role-auth',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'user/:roleId(\\d+)',
        component: (resolve) => require(['@/views/system/role/authUser'], resolve),
        name: 'AuthUser',
        meta: { title: '分配用户', activeMenu: '/system/role' }
      }
    ]
  },
  {
    path: '/system/dict-data',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'index/:dictId(\\d+)',
        component: (resolve) => require(['@/views/system/dict/data'], resolve),
        name: 'Data',
        meta: { title: '字典数据', activeMenu: '/system/dict' }
      }
    ]
  },
  {
    path: '/monitor/job-log',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'index',
        component: (resolve) => require(['@/views/monitor/job/log'], resolve),
        name: 'JobLog',
        meta: { title: '调度日志', activeMenu: '/monitor/job' }
      }
    ]
  },
  {
    path: '/tool/gen-edit',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'index',
        component: (resolve) => require(['@/views/tool/gen/editTable'], resolve),
        name: 'GenEdit',
        meta: { title: '修改生成配置', activeMenu: '/tool/gen' }
      }
    ]
  }
]

export default new Router({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})
