import request from "@/utils/request";
//新增广告客户
export function addCustomer(data) {
  return request({
    url: `/ad/ad_customers/add_customer?customerName=${data.customerName}&customerEmail=${data.customerEmail}&customerPhone=${data.customerPhone}`,
    method: "post"
  });
}
//修改广告客户
export function editCustomer(data) {
    return request({
      url: `/ad/ad_customers/edit_customer?adCustomerId=${data.adCustomerId}&customerName=${data.customerName}&customerEmail=${data.customerEmail}&customerPhone=${data.customerPhone}`,
      method: "put"
    });
  }
//查询广告客户列表
export function listAdCustomer(params) {
    return request({
      url: "/ad/ad_customers/list_ad_customer",
      method: "get",
      params
    });
  }
//获取广告客户详细信息
export function getAdCustomerInfo(id) {
    return request({
      url: `/ad/ad_customers/get_ad_customer_info/${id}`,
      method: "get",
    });
  }
//判断广告客户是否可删除
export function isAdCustomerRemovable(params) {
    return request({
      url: `/ad/ad_customers/is_ad_customer_removable?adCustomerIds=${params.adCustomerIds}`,
      method: "get"
    });
  }
//删除广告客户
export function isAdCustomerDelete(params) {
    return request({
      url: `/ad/ad_customers/delete/${params.adCustomerIds}`,
      method: "delete",
    });
  }
