import request from '@/utils/request'
// 顶部赛事信息
export function getUpperMatchInfo(query) {
  return request({
    url:  '/emd/quoteConfig/share/getUpperMatchInfo',
    method: 'get',
    params: query
  })
}

// 左侧数据
export function getLeftTreeData(query) {
  return request({
    url:  '/emd/quoteConfig/share/getLeftTreeData',
    method: 'get',
    params: query
  })
}

// 右侧table数据
export function getRightTable(query) {
  return request({
    url: '/emd/quoteConfig/share/getRightTable',
    method: 'get',
    params: query
  })
}


