//只可输入中文英文数字
export function forbidPunctuation(rule, value, callback) {
  const reg = '^[\u4e00-\u9fa5a-zA-Z0-9]*$'
  if (!value.match(reg)) {
    callback(new Error(`不能输入特殊字符`))
  } else {
    callback()
  }
}

//只可输入中文英文数字标点符号
export function forbidSpecialChar(rule, value, callback) {
  const reg = '^[\u4e00-\u9fa5a-zA-Z0-9`～~!@#$%^&*()_\\-+=<>?:"{}|,.\\/;\'\\\\[\\]·~！@#¥￥%……&*（）——\\-+={}|《》？：“”【】、；‘’，。、｜「」]*$'
  if (!value.match(reg)) {
    callback(new Error(`不能输入特殊字符`))
  } else {
    callback()
  }
}


