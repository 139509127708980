import request from '@/utils/request'

//获取商城数据接口
// export function getMatchMaterial(params) {
//   return request({
//     url: '/emd/match/info/getMatchMaterial' + shareLinkUrlSuffix,
//     method: 'get',
//     params: params
//   })
// }
//赛事策划详情
export function getInfo(emdMainId) {
  return request({
    url: `/emd/match/share/info/getInfo/`+ emdMainId,
    method: 'GET',
  })
}

// 查询绿道列表
export function listTrack(query) {
    return request({
      url: '/greenway/track/list',
      // method: 'get',
      method: 'post',

      params: {
        pageNum: query.pageNum,
        pageSize: query.pageSize,
      },
      data: query,
    })
  }//获取绿道打卡点
export function getCheckPoint(greenwayIds) {
  return request({
    url:  '/emd/match/share/info/getCheckPoint',
    method: 'get',
    params: {
      greenwayIds: greenwayIds.join(',')
    }
  })
}
