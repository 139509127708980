import request from '@/utils/request'

// 查询签到记录明细
export function listSignInDetails(query) {
  return request({
    url: '/match/signInDetails/list',
    method: 'get',
    params: query
  })
}

// 导出签到记录明细
export function exportSignInDetailsExcel(query) {
  return request({
    url: '/match/signInDetails/exportSignInDetailsExcel',
    method: 'get',
    params: query
  })
}
