var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _c(
      "video",
      {
        key: _vm.value1,
        attrs: {
          width: "100%",
          height: "100%",
          autoplay: "autoplay",
          loop: "loop",
          controls: "",
          muted: "muted",
        },
        domProps: { muted: true },
      },
      [_c("source", { attrs: { src: _vm.value1 } })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }