import request from '@/utils/request'

//报名明细数据集合
export function listEnrollDetails(data) {
  return request({
    url: '/match/enrollDetails/listEnrollDetails?' + 'pageNum=' +data.pageNum + '&pageSize=' + data.pageSize,
    method: 'POST',
    data: data
  })
}

//导出
export function exportEnrollDetails(data) {
  return request({
    url: '/match/enrollDetails/exportEnrollDetails',
    method: 'POST',
    data: data
  })
}



//根据用户Id获取用户绑定的芯片
export function listChipUserBindByUserId(data) {
  return request({
    url: '/match/enrollDetails/listChipUserBindByUserId/'+ data.userId,
    method: 'GET',
    params: data
  })
}

