import request from '@/utils/request'

//办赛需求列表
export function getEmdMainList(params) {
  return request({
    url: '/emd/main/list',
    method: 'get',
    params: params
  })
}

// 删除办赛需求
export function deleteMain(emdMainId) {
  return request({
    url: '/emd/main/delete/' + emdMainId,
    method: 'get'
  })
}
