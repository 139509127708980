var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "matchVideo" }, [
    _c("div", { staticClass: "p_title" }, [_vm._v("赛事讲解")]),
    _c(
      "div",
      { staticClass: "p_video" },
      [
        _c(
          "el-carousel",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.adType === "1000",
                expression: "adType === '1000'",
              },
            ],
            staticClass: "imgClass",
          },
          _vm._l(_vm.videoList, function (item) {
            return _c("el-carousel-item", { key: item.playbillId }, [
              _c("img", {
                attrs: { width: "100%", height: "100%", src: item.url },
              }),
            ])
          }),
          1
        ),
        _c("video", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.adType === "1001",
              expression: "adType === '1001'",
            },
          ],
          ref: "videoAd",
          attrs: {
            autoplay: "autoplay",
            controls: "",
            type: "video/mp4",
            width: "100%",
            height: "100%",
          },
          domProps: { muted: true },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }