var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      attrs: {
        "label-width": "30px",
        "label-position": "top",
        size: "mini",
        inline: true,
      },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "w：" } },
        [
          _c("el-input-number", {
            attrs: {
              value: _vm.currentEditItem.w,
              "controls-position": "right",
              min: 0,
              max: 100,
            },
            on: {
              change: function (currentValue) {
                return _vm.changeOptions("w", currentValue)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "h：" } },
        [
          _c("el-input-number", {
            attrs: {
              value: _vm.currentEditItem.h,
              "controls-position": "right",
              min: 0,
              max: 100,
            },
            on: {
              change: function (currentValue) {
                return _vm.changeOptions("h", currentValue)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "x：" } },
        [
          _c("el-input-number", {
            attrs: {
              value: _vm.currentEditItem.x,
              "controls-position": "right",
              min: 0,
              max: 100 - _vm.currentEditItem.w,
            },
            on: {
              change: function (currentValue) {
                return _vm.changeOptions("x", currentValue)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "y：" } },
        [
          _c("el-input-number", {
            attrs: {
              value: _vm.currentEditItem.y,
              "controls-position": "right",
              min: 0,
              max: 100 - _vm.currentEditItem.h,
            },
            on: {
              change: function (currentValue) {
                return _vm.changeOptions("y", currentValue)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }