var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        marquee: true,
        marquee1: _vm.index === 1,
        marquee2: _vm.index === 2,
      },
      style: { height: _vm.height },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.index === 2,
              expression: "index === 2",
            },
          ],
          staticClass: "p_title",
        },
        [_c("span", [_vm._v("赛事排名 ")])]
      ),
      _c(
        "el-carousel",
        { attrs: { interval: 8000 } },
        _vm._l(_vm.listCurrentMatchs, function (item, index) {
          return _c(
            "el-carousel-item",
            { key: index },
            [
              _c(
                "div",
                { class: _vm.marqueeIndex == 2 ? "p_top" : "p_dataScreen" },
                [
                  _c(
                    "span",
                    { staticStyle: { display: "block" } },
                    [
                      _vm._v(" " + _vm._s(item.matchName) + " （ "),
                      _c("dict-label", {
                        attrs: {
                          options: _vm.dict.type.match_rule,
                          value: item.matchRule,
                        },
                      }),
                      item.lineGroup
                        ? _c("span", [
                            item.matchRule == "MBLC" || item.matchRule == "LJLC"
                              ? _c("span", [
                                  _vm._v(
                                    " / " + _vm._s(item.lineGroup) + "KM "
                                  ),
                                ])
                              : item.matchRule == "DKCS"
                              ? _c("span", [
                                  _vm._v(
                                    " / " + _vm._s(item.lineGroup) + "次 "
                                  ),
                                ])
                              : item.matchRule == "SXGRZHCJ" ||
                                item.matchRule == "DCPDK"
                              ? _c("span", [
                                  _vm._v(
                                    " / " + _vm._s(item.lineGroup) + "KM "
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm._v(" ） "),
                    ],
                    1
                  ),
                ]
              ),
              item.listData1 && item.listData2
                ? _c(
                    "div",
                    [
                      _c(
                        "el-row",
                        {
                          staticClass: "p_name",
                          staticStyle: { padding: "7px" },
                        },
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: { "margin-left": "16px" },
                              attrs: { span: 5 },
                            },
                            [_vm._v("名次")]
                          ),
                          _c("el-col", { attrs: { span: 11 } }, [
                            _vm._v(_vm._s(item.thead1)),
                          ]),
                          _c("el-col", { attrs: { span: 6 } }, [
                            _vm._v(_vm._s(item.thead2)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 2 } },
                            _vm._l(item.maxLength, function (index, item1) {
                              return _c(
                                "el-row",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.maxLength,
                                      expression: "item.maxLength",
                                    },
                                  ],
                                  key: item1,
                                  staticClass: "m_row",
                                },
                                [
                                  item1 == 0
                                    ? _c(
                                        "el-col",
                                        {
                                          staticStyle: {
                                            height: "20px",
                                            position: "relative",
                                          },
                                          attrs: { span: 16, offset: 8 },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              position: "absolute",
                                              top: "-5px",
                                              left: "-12px",
                                            },
                                            attrs: {
                                              width: "35",
                                              height: "28",
                                              src: require("../../../../assets/images/first.png"),
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      )
                                    : item1 == 1
                                    ? _c(
                                        "el-col",
                                        {
                                          staticStyle: {
                                            height: "20px",
                                            position: "relative",
                                          },
                                          attrs: { span: 16, offset: 8 },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              position: "absolute",
                                              top: "-5px",
                                              left: "-12px",
                                            },
                                            attrs: {
                                              width: "35",
                                              height: "28",
                                              src: require("../../../../assets/images/twice.png"),
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      )
                                    : item1 == 2
                                    ? _c(
                                        "el-col",
                                        {
                                          staticStyle: {
                                            height: "20px",
                                            position: "relative",
                                          },
                                          attrs: { span: 16, offset: 8 },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              position: "absolute",
                                              top: "-5px",
                                              left: "-12px",
                                            },
                                            attrs: {
                                              width: "35",
                                              height: "28",
                                              src: require("../../../../assets/images/third.png"),
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      )
                                    : _c(
                                        "el-col",
                                        {
                                          staticStyle: { height: "20px" },
                                          attrs: { span: 16, offset: 8 },
                                        },
                                        [_vm._v(_vm._s(item1 + 1))]
                                      ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 11 } },
                            [
                              _c("match-table", {
                                attrs: { list: item.listData1 },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 11 } },
                            [
                              _c("match-table", {
                                attrs: { list: item.listData2 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : !item.listData2
                ? _c("match-table", {
                    attrs: {
                      list: item.listData1,
                      matchRule: item.matchRule,
                      status: "1",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }