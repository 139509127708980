import request from '@/utils/request'
// 分享链接接口后缀
export const shareLinkUrlSuffix = ""
// 获取办赛需求主对象(分享链接用)
export function getMainBase(params) {
   return request({
    url: '/emd/main/share/getMainBase',
    method: 'get',
    params
  })
}

// 查询绿道或公园集合
export function getGreenwayTrackListByShare(queryType, greenwayName, parkName) {
  return request({
    url: '/emd/reserve/share/getGreenwayTrackList?pageNum=1&pageSize=20&queryType='+queryType+'&greenwayName='+greenwayName+'&parkName='+parkName,
    method: 'get'
  })
}

// 获取办赛绿道预定信息
export function selectScheduleGreenwayListByShare(query) {
  return request({
    url: '/emd/reserve/share/selectScheduleGreenwayList',
    method: 'POST',
    data: query,
  })
}
