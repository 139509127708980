import request from "@/utils/request";

// 校验团建赛手机号是否存在
export function checkmobile(phone) {
  return request({
    url: `/tm/checkmobile/${phone}`,
    method: 'get',
  })
}

// 发送短信验证码修改密码
export function sendSmsCode(params) {
    return request({
      url: `/tm/sendSmsCode`,
      method: 'post',
      params
    })
}

// 校验验证码是否正确
export function verifySmsCode(params) {
    return request({
      url: `/tm//verifySmsCode`,
      method: 'post',
      params
    })
  }

// 修改密码
export function updateLoginPassword(params) {
    return request({
      url: `/tm/updateLoginPassword`,
      method: 'post',
      params
    })
  }
