var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { className: "box" } }, [
    _c(
      "div",
      {
        style: {
          fontSize: _vm.size + "px",
          color: _vm.color,
          textAlign: _vm.textAlign,
        },
        attrs: { id: "weather" },
      },
      [
        _c("span", [_vm._v("天气： ")]),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.briefNew,
                expression: "briefNew",
              },
            ],
            staticStyle: { "margin-right": "10px" },
          },
          [_vm._v(_vm._s(_vm.briefNew))]
        ),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tmpNew,
                expression: "tmpNew",
              },
            ],
            staticStyle: { "margin-right": "10px" },
          },
          [_vm._v(_vm._s(_vm.tmpNew) + "℃")]
        ),
        _c("i", { class: "qi-" + _vm.nowIcon }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }