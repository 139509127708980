<template>
  <div>
    <data-screen
      v-if="displayType == 'SS'"
      :screenTitle="screenTitle"
    ></data-screen>
    <advert-screen
      v-else-if="displayType == 'GG'"
      :screenTitle="screenTitle"
    ></advert-screen>

    <advert-full-screen
      v-else-if="displayType == 'QPGG'"
      :screenTitle="screenTitle"
    ></advert-full-screen>

  </div>
</template>

<script>
import AdvertScreen from "./AdvertScreen";
import DataScreen from "./dataScreen1";
import advertFullScreen  from "./advertFullScreen";
import { mqttTopic, onMqttMessage, unsubscribe } from "@/utils/mqtt";
import {
  getPageType,
  getTitle,
} from "@/api/device/screen-preview/screen-preview";
import { updatePage } from "@/api/device/data-screen/data-screen";

export default {
  components: {
    AdvertScreen,
    DataScreen,
    advertFullScreen
  },
  data() {
    return {
      //页面类型
      displayType: "",
      deviceCode: null,
      //页面标题
      screenTitle: "",
    };
  },
  created() {
    // 请求layout数据
    // this.init()
    if (this.$route.query.deviceCode) {
      this.deviceCode = this.$route.query.deviceCode;
      //初始化页面状态
      this.displayType = "";
      //获取页面信息
      this.getList();
      //mqtt监听数据大屏切换页面类型
      this.getMqttData();
      //获取页面标题
      this.getScreenTitle();
    } else {
    // 查询设备编号
      this.getDeviceCode();
    }
  },
  methods: {
    //mqtt订阅
    getMqttData() {
      //连接mqtt mqtt发消息即调接口
      onMqttMessage({
        topic: this.deviceCode + mqttTopic.push_to_device_screen_page,
      });
      //数据大屏切换页面类型topic
      this.$emitter.on(
        this.deviceCode + mqttTopic.push_to_device_screen_page,
        ({ topic, message }) => {
          if (
            topic ===
            this.deviceCode + mqttTopic.push_to_device_screen_page
          ) {
            //mqtt收到消息，调接口获得自动状态（自动/手动）和页面状态（GG/SS）
            this.$nextTick(() => {
              this.getList();
            });
          }
        }
      );
    },
    // 查询设备编号
    getDeviceCode() {
      this.$bridge.callHandler("searchDeviceCode", "", (data) => {
        var settings = JSON.parse(data);
        this.deviceCode = settings.deviceCode;
        //初始化页面状态
        this.displayType = "";
        if (this.deviceCode) {
          // 根据设备编码获取页面信息
          this.getList();
          this.getMqttData();
          this.getScreenTitle();
        }
      });
    },

    //切换页面类型
    changePage() {
      //SS赛事GG广告//QPGG全屏广告
      updatePage(this.deviceCode, this.displayType).then((res) => {
        if (res.code == 200) {
          console.log("切换成功");
        }
      });
    },
    //根据设备编码获取页面信息
    getList() {
      //mqtt收到消息，调接口获得自动状态（自动/手动）和页面状态（GG/SS）
      getPageType(this.deviceCode).then((response) => {
        //页面类型有两个变量 pageType 和 displayType
        this.displayType = response.data.pageType;
        //判断页面是赛事大屏还是广告大屏
        // 如果是赛事大屏,自动状态下，完赛mqtt计时十分钟没消息转广告大屏；10分钟有消息消除定时再重新定时十分钟
        if (
          response.data.pageType === "SS" &&
          Number(response.data.autoFlag) === 1
        ) {
          //赛事大屏跳转广告大屏，重新定时
          this.$emitter.emit("setTimeOutOverGame", response.data);
        }
      });
    },
    //获取标题
    getScreenTitle() {
      getTitle(this.deviceCode).then((res) => {
        this.screenTitle = res.data;
      });
    },
  },
  mounted() {
    // 完赛mqtt计时十分钟没消息赛事大屏跳转广告大屏
    this.$emitter.on("changePageType", (item) => {
      this.displayType = item;
      this.changePage();
    });
  },
  beforeDestroy() {
    // 销毁前取消订阅
    if (this.deviceCode) {
      unsubscribe(this.deviceCode + mqttTopic.push_to_device_screen_page);
    }
  },
  computed: {},
};
</script>

<style lang="scss" scoped></style>
