!
<template>
  <div class="container">
    <router-view></router-view>
    <div class="copyShareLink" @click="copyShareLinkFn">点击复制分享链接</div>
  </div>
</template>

<script>
import { Message } from "element-ui";
import { getMainBase } from "@/api/sharingPlanView/index.js";
export default {
  name: "HyzpVue20Once",

  data() {
    return {};
  },

  mounted() {
  },
   async beforeRouteEnter(to, from, next) {
    if (!to.query.mainId) {
      Message.error("找不到网页！");
      next({ path: "/404" });
      return;
    }
    try {
      const res = await getMainBase({ emdMainId: to.query.mainId });
      if(res.code === 200 && res.data) {
        next();
      }else {
        Message.error("找不到网页！");
        next({ path: "/404" });
      }
    } catch (e) {
      Message.error("找不到网页！");
      next({ path: "/404" });
    }
  },
  methods: {
    copyShareLinkFn() {
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard
          .writeText(location.href)
          .then(() => {
            this.$notify({
              type: "success",
              title: "分享方案链接复制成功",
              message: "已复制到剪切板(默认快捷键ctrl+v或command+v)",
              duration: 5000,
            });
          })
          .catch((error) => {
            this.$notify({
              type: "error",
              title: "失败",
              message: "分享方案链接复制失败",
            });
          });
      } else {
        // 创建text area
        const textArea = document.createElement("input");
        textArea.value = textToCopy;
        // 使text area不在viewport，同时设置不可见
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        new Promise((resolve, reject) => {
          // 执行复制命令并移除文本框
          document.execCommand("copy")
            ? resolve()
            : reject(new Error("出错了"));
          textArea.remove();
        }).then(
          () => {
            this.$notify({
              type: "success",
              title: "分享方案链接复制成功",
              duration: 5000,
              message: "已复制到剪切板(默认快捷键ctrl+v或command+v)",
            });
          },
          () => {
            this.$notify({
              type: "error",
              title: "失败",
              message: "分享方案链接复制失败",
            });
          }
        );
      }
    },
  },
};
</script>
<style>
/* 分享页面text类型按钮默认14px */
/*  .el-button.el-button--text {
    font-size: 14px;
  }*/
</style>
<style lang="scss" scoped>
.container {
  width: 100vw;
  height: 100vh;
  background: #f8fafb;
  .copyShareLink {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    writing-mode: vertical-rl;
    position: fixed;
    width: 36px;
    height: 140px;
    border-radius: 20px 0 0 20px;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2px;
    right: -60px;
    top: 200px;
    z-index: 999;
    background: var(--theme-color);
    color: #fff;
    animation: cartoonIn 0.8s 1s forwards, cartoonOut 0.8s 4s forwards;
    &:hover {
      animation-play-state: paused;
      animation: cartoonIn 0.8s 1s forwards;
    }
  }
  @keyframes cartoonIn {
    0% {
      opacity: 0;
    }
    100% {
      right: 0;
      opacity: 1;
    }
  }
  @keyframes cartoonOut {
    0% {
      right: 0;
      opacity: 1;
      align-items: center;
    }
    100% {
      right: -16px;
      opacity: 0.7;
      align-items: flex-end;
    }
  }
}
</style>
