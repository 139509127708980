import request from '@/utils/request'

// 查询奖牌订单记录列表
export function listPrize(query) {
  return request({
    url: '/rc/prize/listRcPrizeOrder',
    method: 'get',
    params: query
  })
}

// 导出奖牌订单记录
export function exportPrize(query, prizeIds) {
  if (0 === prizeIds.length) {
    prizeIds = [-1];
  }
  return request({
    url: '/rc/prize/exportRcPrizeOrder/'+ prizeIds,
    method: 'get',
    params: query
  })
}
