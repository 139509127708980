import request from "@/utils/request";

//分页查询广告投放记录
export function listAdSchedule(params) {
  return request({
    url: "/ad/ad_schedule/list_ad_schedule",
    method: "get",
    params
  });
}

/**
 * 导出投放记录
 * @param params
 * @returns {*}
 */
export function exportAdSchedule(params) {
    return request({
      url: "/ad/ad_schedule/export_ad_schedule",
      method: "get",
      params
    });
  }

/**
 * 停止或继续投放广告
 * @param params
 * @returns {*}
 */
export function enableAdSchedule(params) {
  return request({
    url: "/ad/ad_schedule/enable_ad_schedule",
    method: "put",
    params
  });
}


