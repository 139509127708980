<template>
  <div :class="{'has-logo':showLogo}" :style="{ backgroundColor: settings.sideTheme === 'theme-dark' ? variables.menuBackground : variables.menuLightBackground }">
    <logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar :class="settings.sideTheme" wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="settings.sideTheme === 'theme-dark' ? variables.menuBackground : variables.menuLightBackground"
        :text-color="settings.sideTheme === 'theme-dark' ? variables.menuColor : variables.menuLightColor"
        :unique-opened="true"
        :active-text-color="settings.theme"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item
          v-for="(route, index) in sidebarRouters"
          :key="route.path  + index"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
  import { mapGetters, mapState } from "vuex";
  import Logo from "./Logo";
  import SidebarItem from "./SidebarItem";
  import variables from "@/assets/styles/variables.scss";

  export default {
    data() {
      return {
        sidebarRouters :[],
        list:[],
      }
    },
    created() {
      //读取的参数
      const query = this.$route.query;
      let routeList = [];
      let route = this.createRoutes("eventPlanOverview", "/eventPlanOverview", "赛事方案总览", "form");
      routeList.push(route);
      route = this.createRoutes("organizerInformation", "/organizerInformation", "办赛人员信息", "money");
      routeList.push(route);
      route = this.createRoutes("planConfig", "/planConfig/index", "赛事策划方案", "edit");
      routeList.push(route);
      route = this.createRoutes("quotationScheme", "/quotationScheme", "赛事报价方案", "druid");
      routeList.push(route);
      route = this.createRoutes("contractInvoiceConfig", "/contractInvoiceConfig", "赛事合同方案", "excel");
      routeList.push(route);
      route = this.createRoutes("competitionCeremonyProcess", "/competitionCeremonyProcess", "赛事仪式流程", "slider");
      routeList.push(route);
      route = this.createRoutes("executionProcess", "/executionProcess", "赛事执行流程", "education");
      routeList.push(route);
      route = this.createRoutes("advertConfig", "/advertConfig", "赛事品宣方案", "button");
      routeList.push(route);
      this.sidebarRouters = routeList;
    },
    components: { SidebarItem, Logo },
    computed: {
      ...mapState(["settings"]),
      ...mapGetters(["sidebar"]),
      activeMenu() {
        const route = this.$route;

        const { meta, path } = route;
        // if set path, the sidebar will highlight the path you set
        if (meta.activeMenu) {
          return meta.activeMenu;
        }
        return path;
      },
      showLogo() {
        return this.$store.state.settings.sidebarLogo;
      },
      variables() {
        return variables;
      },
      isCollapse() {
        return !this.sidebar.opened;
      }
    },
    methods: {
      createRoutes (name, path, title, icon) {
        const route = {
          "name": name,
          "path": path,
          "hidden": false,
          "meta": {
            "title": title,
            "icon": icon,
            "noCache": false,
            "link": null
          }
        }
        return route;
      }
    }
  };
</script>
